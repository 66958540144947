import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'sp-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {

  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() type: string = 'text';

  constructor() { }

  ngOnInit() {
  }

}
