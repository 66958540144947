import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

interface TransferStatus {
  id: number;
  value: string;
  description: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sp-input-statement-status-select',
  templateUrl: './input-statement-status-select.component.html',
  styleUrls: ['./input-statement-status-select.component.scss']
})
export class InputStatementStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() multiple = false;

  @Output() itemSelected = new EventEmitter<TransferStatus>();

  statusList: TransferStatus[];

  constructor() { }

  ngOnInit() {
    this.generateStatusList();
  }

  generateStatusList() {
    this.statusList = [
      {
        id: 0,
        value: "DRAFT",
        description: 'bonifici.statement_status.DRAFT'
      },
      {
        id: 1,
        value: "SAVED",
        description: 'bonifici.statement_status.SAVED'
      },
      {
        id: 2,
        value: "CONFIRMED",
        description: 'bonifici.statement_status.CONFIRMED'
      },
      {
        id: 3,
        value: "CANCELED",
        description: 'bonifici.statement_status.CANCELED'
      },
    ];
  }

  changed(ev: TransferStatus) {
    this.itemSelected.emit(ev);
  }

}
