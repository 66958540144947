import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {LoggingService} from "../core/services/log.service";
import {environment} from "../../environments/environment";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private map = new Map<string, any>();

  constructor(private http: HttpClient, private logger: LoggingService , public datepipe: DatePipe) {}

  storePaymentData(token: string, data: any) {
    this.map.set(token, data);
  }

  getPaymentData(token: string): any {
    return this.map.get(token);
  }

  cleanPaymentData(token: string) {
    this.map.delete(token);
  }

  getRateOfTheDay(queryParams: {date: Date|String, idMerchant: number}): any {
    if ( queryParams.date == null ) {
      const date = new Date();
      // dayDate.
      console.log('TODAY' , this.datepipe.transform( date , 'yyyy-MM-dd' ) );
      queryParams.date = this.datepipe.transform( date , 'yyyy-MM-dd' );
    }

    const path = environment.restBaseUrl + "/payment/processed";

    return this.http
      .post<any[]>( path , { "date" : queryParams.date, "idMerchant": queryParams.idMerchant }, this.getRequestOptionArgs() )
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            const dataResponse = res.data;
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            return response;
          }
        }
      ) ,
      catchError((error: HttpErrorResponse) => {
        this.logger.log("Service Fail. Error:", error, 200);
        return throwError(error);
      }
      ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };
    return httpOptions;
  }

  getAllBonifici( dateRange: Date|String = null ): any {
    const response = { "outcome": "" , "data": [] };
    const data = [
      {"id": 1 , "amount": 120 , "transferDayTime": "2019-05-12 12:00" , "installmentId": 24 , "status": "success" , "transferDetail": {"receivingId": 7 , "senderId": 1}},
      {"id": 2 , "amount": 200 , "transferDayTime": "2019-05-15 13:30" , "installmentId": 28 , "status": "failed" , "transferDetail": {"receivingId": 7 , "senderId": 1}},
      {"id": 3 , "amount": 400 , "transferDayTime": "2019-07-28 16:45" , "installmentId": 30 , "status": "planned" , "transferDetail": {"receivingId": 7 , "senderId": 1}}
    ];
    response.data = data;
    return response;
  }

}
