import { Component, OnInit , ViewChild , ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';

import { PaymentService } from '../../../services/payment.service';
import { BonificiService } from '../../../services/bonifici.service';
import { SellerService } from '../../../services/seller.service';

@Component({
  selector: 'app-bonifici-insert',
  templateUrl: './bonifici-insert.component.html',
  styleUrls: ['./bonifici-insert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BonificiInsertComponent implements OnInit {

  rows: any[] = [];
  options: any = [];
  dayModel: any;
  date : any; 
  selected : any;
  rowDetails:any = [];
  merchantList:any = [];
  tableRecordNumbers: number;
  actualCurrency: string;
  timeout: any;
  initiatedPayout: any;

  profile : any;
  drop = new FormControl(null);
  selectedForPayout : any = null ; 

  @ViewChild('dataTable') table: any;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  swal_error : string;
  swal_success : string;
  swal_success_txt: string;
  swal_error_txt : string;

  constructor( 
    private paymentService : PaymentService , 
    private bonificiService : BonificiService , 
    private sellerService : SellerService , 
    private translate: TranslateService,
    private datepipe : DatePipe) { 

      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'));
      // console.log( 'this.tableRecordNumbers' , this.tableRecordNumbers )
      this.profile = localStorage.getItem('profile')

      // Overrides default total/empty messages
      translate.get("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );
      translate.get("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);
    
      this.translate.get('bonifici.swal.title_Error').subscribe((res : string ) => this.swal_error = res )
      this.translate.get('bonifici.swal.title_Success').subscribe((res : string ) => this.swal_success = res )
      this.translate.get('bonifici.swal.content_Success').subscribe((res : string ) => this.swal_success_txt = res )
      this.translate.get('bonifici.swal.content_Error').subscribe((res : string ) => this.swal_error_txt = res )

    }

  ngOnInit() {
    this.sellerService.getAllSellers().subscribe((res : any) => {
      this.merchantList = res.results
      // this.getAllPayouts()
    })

  }

  // toggleExpandRow(row,expanded) {
  //   if (!expanded) {
  //     this.bonificiService.getAllInstallmentsByPayoutId( row.id ).subscribe((res:any) => {
  //       row['transferDetail'] = res.results
  //       this.actualCurrency= row.merchantDetail.currency
  //     })
      
  //   }
  //   this.table.rowDetail.toggleExpandRow(row);
  // }

  // onDetailToggle(event) {
  //   console.log('Detail Toggled', event);
  // }

  // getAllPayouts() {
  //   this.bonificiService.getAllPayouts().subscribe((res:any) => {
  //     this.rows = res.results
  //     this.rows.forEach(element => {
  //       element.merchantDetail = this.merchantList.find( x => x.idMerchant === element.idMerchant ) 
  //     });
  //   })
  // }

  // onPage(event) {
  //   clearTimeout(this.timeout);
  //   this.timeout = setTimeout(() => {
  //     console.log('paged!', event);
  //   }, 100);
  // }

  makePayout(){
    console.log( 'makePayout()' , this.selectedForPayout )
    if( this.selectedForPayout != null ) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      this.bonificiService.makePayout( this.selectedForPayout ).subscribe((res:any) => {
        console.log( 'sto facendo payout'  , res )
        if( res.success == false ) {
          swal(this.swal_error , this.swal_error_txt + res.message , "error")
        } else {
          if(res.status == 'ERROR') {
            swal(this.swal_error , this.swal_error_txt + res.reason , "error")
          } else {
            swal(this.swal_success , this.swal_success_txt , "success")
          }
        }
      })
    }
    
  }

  selectChange(event) {
    if( event != null ) {
      console.log( 'selectChange()' , event )
      this.selectedForPayout = event.idMerchant;
    } else {
      console.log( 'Clear selected Merchant' )
      this.selectedForPayout = null;
    }
    
  }

  async createDraftPayout() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let resp;
    resp = await this.bonificiService.draftPayout( 'STRIPE' , this.selectedForPayout ).toPromise()
    console.log( 'createDraftPayment - resp ' , resp )
    if( resp.success == false ) {
      this.initiatedPayout = null
      swal( this.translate.instant('error.generic_error') , this.translate.instant('error.error_'+resp.code) , "error")
    } else {
      swal.close()
      this.initiatedPayout = resp
    }
  }
  
}