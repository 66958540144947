import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ExternalApiComponent } from './external-api/external-api.component';
import { ExternalApplicationsModule } from './external-applications/external-applications.module';
import { ExternalMerchantsModule } from './external-merchants/external-merchants.module';

const routes: Routes = [
  {
    path: '',
    component: ExternalApiComponent,
    children: [
      { path: '', redirectTo: 'application', pathMatch: 'full' },
      { path: 'application', loadChildren: () => import('./external-applications/external-applications.module').then(m => m.ExternalApplicationsModule) },
      { path: 'merchant', loadChildren: () => import('./external-merchants/external-merchants.module').then(m => m.ExternalMerchantsModule) }
    ]
  }
];

@NgModule({
	imports: [
    SharedModule,
		RouterModule.forChild(routes),
		//ExternalApplicationsModule,
    //ExternalMerchantsModule,
	],
	declarations: [ ExternalApiComponent ],
  exports: [
    RouterModule
  ]
})
export class ExternalApisModule { }