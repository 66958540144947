import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { AppService } from '../../../core/services/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-external-merchant-form',
  templateUrl: './external-merchant-form.component.html',
  styleUrls: ['./external-merchant-form.component.scss']
})

export class ExternalMerchantFormComponent implements OnInit {
  @Input() merchants: any;
  @Input() set externalMerchantData(externalMerchantData : any){ 
    console.log('ExternalMerchantData' , externalMerchantData )
    if ( externalMerchantData ) {
      
      this.profileForm.setValue({
        apiKey: externalMerchantData.apiKey || "",
        merchantCode: externalMerchantData.merchantCode || "",
        idMerchant: externalMerchantData.idMerchant || null
      })
    }

  };

  typeOfUser:any;
  idUser: any;
  email: any;

  idExternalMerchant: any;
  profile: any;
  transferFreq;

  profileForm = this.formBuilder.group({
    apiKey: ['', Validators.required],
    merchantCode: ['', Validators.required],
    idMerchant: [null, Validators.required]
  });

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    public settings: SettingsService,
    private translate: TranslateService,
    private routerExtService: RouterExtService) {}

  ngOnInit() {
    this.typeOfUser = this.route.snapshot.params['usertype']
    this.profile = localStorage.getItem("profile");
    this.idUser = this.route.snapshot.params['id'];

    console.log( 'this.profileForm' , this.profileForm )
  }

  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.valid) {
      this.save(this.profileForm.value)
    }
  }

  save(externalMerchant) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.idUser) {
      this.appService.newElement('/external-merchant', externalMerchant).subscribe((response: any) => {
        if (response.outcome.success === true) {
          swal(this.translate.instant('external-merchant.swal_user_created_ok'), "" , "success").then((result) => {
            this.location.back();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
    else {
      externalMerchant['id'] = this.idUser
      this.appService.editElement('/external-merchant', externalMerchant).subscribe((response: any) => {
        if (response.outcome && response.outcome.success === true) {
          swal(this.translate.instant('external-merchant.swal_user_update_ok'), "" , "success").then((result) => {
            this.location.back();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/external/merchant');
    } else {
      this.location.back();
    }
  }
}
