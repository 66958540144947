import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PaymentService } from '../../../services/payment.service';
import { SalesService } from '../../../services/sales.service';
import { AppService } from '../../../core/services/base.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';

moment.locale('it', localization);

@Component({
  selector: 'app-rate-of-day',
  templateUrl: './rate-of-day.component.html',
  styleUrls: ['./rate-of-day.component.scss']
})
export class RateOfDayComponent implements OnInit {
  @ViewChild('dataTable') table: any;
  rows: any[] = [];
  options: any = [];
  dayModel: any;
  date: any;
  idMerchant: any;
  selected: any;
  locationPage: any;
  merchantInfo: any;
  isSuperAdmin: boolean;
  tableRecordNumbers: number;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  _moreInfo: any;

  searchForm: FormGroup;

  constructor(
    private paymentService: PaymentService ,
    private saleService: SalesService,
    private appService: AppService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    protected baseService: AppService
  ) {
    this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);

    // Overrides default total/empty messages
    translate.get("datatable_message.lista_totali_righe").subscribe((res: string ) => this.my_messages.totalMessage = res );
    translate.get("datatable_message.lista_vuota_righe").subscribe((res: string ) => this.my_messages.emptyMessage = res);

    this.locationPage = 'rateOfDay';
    if ( localStorage.getItem('profile') === "SUPERADMIN" ) {
      this.isSuperAdmin = true;
    } else {
      this.isSuperAdmin = false;
    }

    this.searchForm = this.formBuilder.group({
      //day: [{ startDate: moment(), endDate: moment() }, Validators.required],
      day: [moment().toDate(), Validators.required],
      idMerchant: [null]
    });
  }

  ngOnInit() {
    this.getRateOfDay();

    if ( this.isSuperAdmin ) {
      this.getMerchantsCurrencies();
    } else {
      this.getMerchantInfo();
    }
  }

  searchEvent(event) {
    this.options.day = event.startDate;

    this.getRateOfDay(this.options.day);
    if ( this.table ) {
      this.table.offset = 0;
    }
  }

  getQueryParamsOfSearch(searchItems) {
    const queryParamsSearch = new Date( searchItems );
    // queryParamsSearch = Object.keys(searchItems).map(key => key + '=' + searchItems[key]).join('&');
    return queryParamsSearch;
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      //const date = this.searchForm.controls.day.value.startDate;
      const date = this.searchForm.controls.day.value;
      const idMerchant = this.searchForm.controls.idMerchant.value
        ? this.searchForm.controls.idMerchant.value
        : null;
      const queryParamsSearch = { date, idMerchant };

      this.getRateOfDay(queryParamsSearch);
      if ( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset({
      //day: { startDate: moment(), endDate: moment() },
      day: moment().toDate(),
      idMerchant: null
    });
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  getRateOfDay(queryParams = null) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    if (!queryParams) {
      this.date = new Date();
      this.idMerchant = null;
    }

    this.paymentService.getRateOfTheDay(queryParams ? queryParams : { date: new Date(), idMerchant: null })
      .subscribe(async (res: any) => {
        this.rows = res.results;

        setTimeout(() => {
          swal.close();
        }, 500);
      });
  }

  async toggleExpandRow( rowData , expanded ) {
    if ( !expanded && !rowData.details ) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      const detail = await this.loadRowDetail(rowData);
      rowData.details = detail ? detail : null;
      rowData.details.instalmentOrder = await this.getInstalmentDetail( rowData.saleId , rowData.instalmentId , true );
      swal.close();
    }

    this.table.rowDetail.toggleExpandRow( rowData );
  }

  async loadRowDetail( rowData ) {
    const resp: any = await this.saleService.getRateDetails( rowData.saleId ).toPromise();
    if ( resp.idSale != null && resp.instalmentNumber != null  ) {
      return resp;
    } else {
      if ( resp.idSale == null ) {
        swal( this.translate.instant('general.error') , '' , 'error');
      } else {
        swal( this.translate.instant('general.error') , '' , 'error');
      }
    }
  }

  async getMerchantInfo() {
    const resp = await this.appService.getAll('/merchant/' + localStorage.getItem('idMerchant') ).toPromise();
    if ( resp != null && resp.id != null ) {
      this.merchantInfo = resp;
    }
  }

  async getInstalmentDetail( idSale , idInstalment = null , onlyOrder: boolean = false ) {
    let r = await this.saleService.getSalesDetails(idSale).toPromise();
    if ( r ) {
      if ( idInstalment ) {
        r = this.getInstalmentOrder( r.results , idInstalment );
      }
      if ( onlyOrder) {
        return r.order;
      } else {
        return r;
      }
    }
  }

  getInstalmentOrder( sale , instalment ) {
    const resultInstalment = sale.find(x => x.id === instalment );
    resultInstalment.order = sale.findIndex(x => x.id === instalment ) + 1;
    return resultInstalment;
  }

  async getMerchantsCurrencies() {
    const resp = await this.appService.getAll('/merchant/getAll').toPromise();
    console.log( 'getMerchantCurinccies - resp' , resp );
    if ( resp.results && resp.results != null ) {
      // Per proseguire con la ricerca del merchant e relativa valuta mi serve avere l'id del merchant nella lista delle rate del giorno o nel dettalio rata
    } else {

    }
  }

}
