import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';
import { Location } from '@angular/common';
import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})

export class ActivateUserComponent implements OnInit {
  public account = {
    password: <string>null,
  };
  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public barColors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  public baseColor = '#DDD';
  public strengthLabels = ['(Useless)', '(Weak)', '(Normal)', '(Strong)', '(Great!)'];
  strength: any;

formRecover = this.formBuilder.group({
  password : ['', Validators.required],
  newPassword : ['', Validators.required],
  repeatNewPassword : ['', Validators.required]
});

msgTranslation;
errorTranslation;
errorMessage;
token;

constructor(private formBuilder: FormBuilder, 
  private location: Location, 
  public settings: SettingsService, 
  private logger: LoggingService, 
  private activeRoute: ActivatedRoute, 
  private router: Router, 
  private authService: AuthService, 
  private translate: TranslateService) {
    const sub = this.activeRoute.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token)
    });

    this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
    this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
  }

  ngOnInit() {
    
  }

  onSubmit(): void {
    swal({
      title: this.msgTranslation.loading,
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  backClicked() {
    this.location.back();
  }
  
  strengthChanged(strength: number) {
    this.strength = strength;
  }
}



