import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../../core/services/base.service';
import { IReseller } from '../models/reseller';

enum ResellerAPI {
  getAll = "/reseller/getAll"
}

@Injectable({
  providedIn: 'root'
})
export class ResellerApiService {

  constructor(private appService: AppService) { }

  getAll(queryParams = null): Observable<IReseller[]> {
    return this.appService.getAll(ResellerAPI.getAll + (queryParams ? "?" + queryParams : "")).pipe(
      map(response => {
        return response.results
      })
    )
  }

}