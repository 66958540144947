import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BonificiComponent } from './bonifici/bonifici.component';
import { BonificiStatusComponent } from './bonifici-status/bonifici-status.component';
import { BonificiInsertComponent } from './bonifici-insert/bonifici-insert.component';
import { BonificiAmministratoreComponent } from './bonifici-amministratore/bonifici-amministratore.component';
import { BonificiResellerComponent } from './bonifici-reseller/bonifici-reseller.component';

const routes: Routes = [
  { path: '', redirectTo: 'home',  pathMatch: 'full' },
	{ path: '', component: BonificiComponent },
	// { path: 'new', component: NewSaleComponent },
	// { path: 'edit/:id', component: NewSaleComponent },
	// { path: 'filter/:status', component: SalesComponent }
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule.forChild(routes),
		NgxDatatableModule,
		SharedModule,
		NgSelectModule
	],
	declarations: [
		BonificiComponent,
		BonificiStatusComponent,
		BonificiInsertComponent,
		BonificiAmministratoreComponent,
		BonificiResellerComponent
	],
	exports: [
    	RouterModule
  	]
})
export class BonificiModule { }
