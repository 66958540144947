import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalesComponent } from './sales/sales.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewSaleComponent } from './new-sale/new-sale.component';
import { SaleAnalysisComponent } from './sale-analysis/sale-analysis.component';
import { RateAnalysisComponent } from './rate-analysis/rate-analysis.component';
import { RefundAnalysisComponent } from './refund-analysis/refund-analysis.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angular2-qrcode';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

const routes: Routes = [
  { path: '', redirectTo: 'home',  pathMatch: 'full' },
	{ path: '', component: SalesComponent },
	{ path: 'new', component: NewSaleComponent },
	{ path: 'edit/:id', component: NewSaleComponent },
	{ path: 'filter/:status', component: SalesComponent }
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule.forChild(routes),
		NgxDatatableModule,
		SharedModule,
		NgSelectModule,
		QRCodeModule,
		TooltipModule
	],
	declarations: [SalesComponent, NewSaleComponent, SaleAnalysisComponent, RateAnalysisComponent, RefundAnalysisComponent],
	exports: [
		RouterModule
	]
})
export class SalesModule { }
