import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { SalesModule } from './sales/sales.module';
import { SettingsModule } from './settings/settings.module';
import { ProfileRateModule } from './profile-rate/profile-rate.module';
import { UsersModule } from './users/users.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { StatisticsModule } from './statistics/statistics.module';
import { BonificiModule } from './bonifici/bonifici.module';

import { menu } from './menu';
import { routes } from './routes';
import { SellersModule } from './sellers/sellers.module';
import { ResellersModule } from './resellers/resellers.module';
import { HomeModule } from './home/home.module';
import { TecModule } from './pages/tec/tec.module';
import { ExternalApisModule } from './external-apis/external-apis.module';


@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { useHash: true }),
        PagesModule,
        SalesModule,
        SettingsModule,
        ProfileRateModule,
        SellersModule,
        StatisticsModule,
        BonificiModule,
        // ResellersModule,
        // HomeModule,
        UsersModule,
        ConfigurationModule,
        TecModule,
        ExternalApisModule
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        menuService.addMenu(menu);
    }
}
