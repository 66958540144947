import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IFirstInstalmentType } from '../../../models/instalment';

@Component({
  selector: 'sp-input-first-instalment-type-select',
  templateUrl: './input-first-instalment-type-select.component.html',
  styleUrls: ['./input-first-instalment-type-select.component.scss']
})
export class InputFirstInstalmentTypeSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IFirstInstalmentType>();

  types: IFirstInstalmentType[];

  constructor() { }

  ngOnInit() {

    this.getTypes();

  }

  getTypes() {
    this.types = [
      {
        id: 1,
        code: 'PERCENTUALE',
        description: 'rata.tipo_rata_1'
      },
      {
        id: 2,
        code: 'VALORE_FISSO',
        description: 'rata.tipo_rata_2'
      },
      {
        id: 3,
        code: 'UNIFORME',
        description: 'rata.tipo_rata_3'
      }
    ]
  }

  changed(ev: IFirstInstalmentType) {
    this.itemSelected.emit(ev);
  }

}
