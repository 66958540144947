
import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorsService } from '../../../shared/colors/colors.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../../core/settings/settings.service';
import { SalesService } from '../../../services/sales.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticsComponent implements OnInit,OnDestroy {
  activePage:String='rod';
  profile:any;
  isOpened:boolean = true;
  userSelected: any;

  isChipClosed:boolean = false;

  constructor(
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private route: ActivatedRoute,
    private salesService:SalesService) {
  }

  ngOnInit() {
    /* this.profile = localStorage.getItem("profile")
    let statusUrl = localStorage.getItem ("saleStatus");
    if (statusUrl == 'pendenti' || statusUrl == 'pagato') {
      this.activePage = 'sa'
    }
    else if (statusUrl == 'insoluti'){
      this.activePage = 'ra'
    } */
    this.profile = localStorage.getItem("profile")
  }

  setActivePage(tab){
    this.activePage = tab;
  }
  
  ngOnDestroy(){
    localStorage.removeItem("saleStatus");
    localStorage.removeItem("saleDate");
  }
  
  selectedUser(user) {
    this.userSelected = user;
  }

  clearSelectedUser() {
    this.isChipClosed = true;
  }

}
