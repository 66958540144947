import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppService } from '../../../core/services/base.service';
import { BonificiService } from '../../../services/bonifici.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';
import { Subscription } from 'rxjs';
import { BsModalService , BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoggingService } from '../../../core/services/log.service';

moment.locale('it', localization);

@Component({
  selector: 'app-bonifici-amministratore',
  templateUrl: './bonifici-amministratore.component.html',
  styleUrls: ['./bonifici-amministratore.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BonificiAmministratoreComponent implements OnInit, OnDestroy {

  rows: any[] = [];
  // allRows: any[] = [];
  totalRows: number = 0;
  @ViewChild('dataTable') table: any;
  @ViewChild('newStatementModal') modalTemplate: TemplateRef<any>;

  pathList = '/bank-statement'; /* ?dateFrom=2019-10-01&dateTo=2019-10-31&channel=STRIPE */
  pathDetail = '/bank-statement/';
  pathUpdateCost = '/bank-statement/cost';
  pathMakeStatement = '/bank-statement/make';

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'DD/MM/YYYY',
    rangeInputFormat: 'DD/MM/YYYY'
  }
  //selectedDateRange: any = null;
  dateRangeMaxDate: any = null;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  newStatementForm = this.fb.group({
    channel: ['STRIPE', Validators.required],
    dateRange: [[moment().subtract(1, 'month').startOf('month').toDate(), moment().startOf('month').subtract(1, 'month').endOf('month').toDate()], Validators.required]
  });

  channels = [
    { id : 1 , name : 'STRIPE' }
  ];

  notifyNewStatement: Subscription;
  modalRef: BsModalRef;
  tableRecordNumbers: number;
  tablePage: number = 1;
  allMerchantList: any = [];
  instalmentList: any;
  currentUserCurrency: any;
  parentPayout: any;
  toast: any;

  searchForm: FormGroup;

  includeUndone = true;

  constructor( private translate: TranslateService,
    private appService: AppService,
    private bonificiService: BonificiService,
    private modalService: BsModalService,
    private fb: FormBuilder ,
    private logger: LoggingService,
    private formBuilder: FormBuilder
  ) {

    // Overrides default total/empty messages
    translate.get("datatable_message.lista_totali_righe").subscribe((res: string ) => this.my_messages.totalMessage = res );
    translate.get("datatable_message.lista_vuota_righe").subscribe((res: string ) => this.my_messages.emptyMessage = res);

    this.searchForm = this.formBuilder.group({
      balanceDate: [null],
      status: [null],
      paymentStatus: [null]
    });

    this.toast = swal.mixin({
      toast: true,
      position: "top-end",
      timer: 3500,
      showConfirmButton: false,
    });
  }

  ngOnInit() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);
    this.loadElements();
    this.dateRangeMaxDate = moment().subtract(1, 'day').toDate();
    //this.selectedDateRange = {start: moment().startOf('month').subtract(1, 'month'), end: moment().endOf( 'month' ).subtract(1, 'month').endOf( 'month' ) };
    this.notifyNewStatement = this.bonificiService.newStatementCaller.subscribe((value: any) => {
      this.logger.log( 'ho ricevuto la chiamata dal bottone in un altro component' , '' , 200 );
      this.openModalNewStatement( this.modalTemplate );
    });
  }

  async toggleExpandRow( row , event ) {
    if (!event) {
      if ( this.allMerchantList.length === 0) {
        this.allMerchantList = await this.getAllMerchant();
        // console.log( 'this.allMerchantList - subito dopo chiamata' , this.allMerchantList )
      }

      row.payouts.forEach(element =>  {
        element.merchantInfo = this.allMerchantList.find( x => x.idMerchant === element.idMerchant );
        this.currentUserCurrency = element.merchantInfo.currency;
      });
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  async getAllMerchant() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    // Using async-await code shall look something like this
    // https://medium.com/@balramchavan/using-async-await-feature-in-angular-587dd56fdc77
    const _resp = await this.appService.getAll( '/merchant/getAll' ).toPromise();
    swal.close();
    return _resp.results;
  }

  loadElements(queryParams: string = null, showSwal: boolean = false) {
    if (showSwal) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    }

    if (!queryParams) {
      queryParams = '';
    } else {
      queryParams += '&';
    }
    
    if (!this.includeUndone) {
      queryParams += 'excludeStatus=CANCELED&';
    }

    queryParams += 'paging=true&page=' + this.tablePage + '&rows=' + this.tableRecordNumbers;

    this.bonificiService.getAllBankStatements(queryParams).subscribe((res: any) => {
      this.rows = res.results;
      this.totalRows = res.total;
      // this.allRows = this.rows;
      // this.onFlagExclusionChange(); /** Checks whether to display undone documents or not */
      swal.close();
    });
  }

  onPage(event) {
    if (this.tablePage != event.offset + 1) {
      this.tablePage = event.offset + 1;
      this.loadElements(this.generateFiltersPath(), true);
    }
  }

  toggleEditable(row) {
    if (!row.costEdit && row.costEdit != 0) {
      row.costEdit = row.cost;
    } else {
      delete row.costEdit;
    }
  }

  saveEditable(row, value) {
    row.cost = value;
    const body = { "id" : row.id , "cost" : row.cost };
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    this.updateCost( this.pathUpdateCost , body );
  }

  /*resetEditable(event, row) {
    console.log(row, event)
    row.cost = event;
  }

  saveEditable(event , row) {
    this.logger.log( 'onSave editable field' , event , 200 );
    const body = { "id" : row.id , "cost" : event };
    swal.showLoading();
    this.updateCost( this.pathUpdateCost , body );
  }

  myHandleError() {
    this.logger.log( 'onError editable field' , '' , 200 );
    swal({
      title: this.translate.instant('swal.title_Error'),
      text: this.translate.instant('error.error_0014'),
      type: "error"
    });
  }*/

  chipDeleted() {
    this.logger.log( 'inside chip deleted' , '' , 200 );
  }

  updateCost( path , body ) {
    this.bonificiService.updateCost( path , body ).subscribe((resp) => {
      this.logger.log( 'updateCost resp => ' , resp , 200 );
      if ( resp.outcome && resp.outcome.success ) {
        this.logger.log( 'abilito l\'invia per questa riga' , '' , 200 );
        this.toast({
          position: 'bottom-end',
          title: this.translate.instant('swal.title_Success'),
          text: this.translate.instant('bonifici.swal_title_cost-confirmed-success'),
          type: 'success'
        });
        this.loadElements();
      } else {
        swal({
          type: 'error',
          title: this.translate.instant('swal.title_Error'),
          html: this.translate.instant('bonifici.swal_title_cost-confirmed-error') + '<br>' + resp.message
        });
        this.logger.log( 'bottone di invia non abilitato' , '' , 200);
      }
    });
  }

  payStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-pay'),
      html : this.translate.instant('bonifici.swal_text_statement-pay'),
      type : 'warning',
      showCancelButton : true,
      showLoaderOnConfirm: true,
    }).then((value) => {
      // console.log(value)
      // swal.showLoading()
      if ( value.value === true ) {
        setTimeout(() => swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        }) , 200); // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        this.appService.getAllFromData( '/bank-statement/' + row.id + '/pay' , null ).subscribe((resp) => {
          console.log( 'bak statement pay resp' , resp );
          if ( !resp.outcome ) {
            this.logger.log('payment done' , '' , 200);
            swal({
              title: this.translate.instant('bonifici.swal_title_statement-pay-success'),
              html: this.translate.instant('bonifici.swal_text_statement-pay-success'),
              type: 'success'
            }).then(_ => {
              this.rows = this.updateRowUi( row , 'paymentStatus' , 'CONFIRMED' );
            });
          } else if ( resp.outcome.success === false ) {
            if ( resp.code === "0012" || resp.code === "0031" ) {
              swal({
                title: this.translate.instant('error.generic_error'),
                html: this.translate.instant('bonifici.error_' + resp.code) ,
                type: 'error'
              });
            } else {
              swal({
                title: resp.message,
                text: resp.description,
                type: 'error',
              });
            }
          }
          // swal.close()
        });
      } else {
        swal.noop();
      }
    });
  }

  async openModal( t_modal: ModalDirective , data = null ) {
    console.log( 't_modal' , t_modal );
    this.parentPayout = null;
    if ( data != null ) {
      this.parentPayout = data;
      console.log( 'this.parentPayout' , this.parentPayout );
      this.instalmentList = await this.getRelatedIntalmentsByPayout( data );
      console.log( 'sto per mostrare la modale');
    } else {
      this.instalmentList = null;
    }
    t_modal.show();
  }

  closeModal( t_modal: ModalDirective ) {
    console.log( 'closeModal' , t_modal );
    t_modal.hide();
  }

  openModalNewStatement( template: TemplateRef<any> ) {
    this.modalRef = this.modalService.show(template , { class: 'modal-lg' } );
  }

  makeStatement() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let body: any;
    // console.log( 'new statement form' , this.newStatementForm.controls.channel.value , this.selectedDateRange )
    body = {
      channel: this.newStatementForm.controls.channel.value,
      //dateFrom: this.selectedDateRange.start.format("YYYY-MM-DD"),
      dateFrom: moment(this.newStatementForm.controls.dateRange.value[0]).format("YYYY-MM-DD"),
      //dateTo: this.selectedDateRange.end.format("YYYY-MM-DD")
      dateTo: moment(this.newStatementForm.controls.dateRange.value[1]).format("YYYY-MM-DD")
    };
    // console.log( 'body request' , body )
    const creationSubscription = this.appService.newElement( this.pathMakeStatement , body ).subscribe(
      () => {
        // console.log( resp )
        clearTimeout(creationTimeoutId);
        creationSubscription.unsubscribe();

        swal({
          title: this.translate.instant('bonifici.swal_title_statement-make-success'),
          html: this.translate.instant('bonifici.swal_text_statement-make-success'),
          type: 'success'
        }).then(() => {
          this.modalRef.hide();
          this.loadElements();
        });
      }
    );

    const creationTimeoutId = setTimeout(() => {
      creationSubscription.unsubscribe();

      swal({
        title: this.translate.instant('bonifici.swal.warning_bank_statement'),
        text: this.translate.instant('bonifici.swal.bank_statement_error'),
        type: 'warning'
      }).then(() => {
        this.modalRef.hide();
        window.location.reload();
      });
    }, 15000);
  }

  confirmStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-confirm'),
      html : this.translate.instant('bonifici.swal_text_statement-confirm'),
      type : 'warning',
      showCancelButton : true,
    }).then((value) => {
      if ( value.value === true ) {
        // swal('TEST - Da implementare' , 'Questa funzione andrà a confermare il documento attualmente in stato bozza' , 'info')
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.appService.editElement( '/bank-statement/status/' + row.id + '/CONFIRMED' , null ).subscribe((resp) => {
          // console.log( 'response of confirm bank statement' , resp )
          if ( resp.outcome && resp.outcome.success ) {
            swal( this.translate.instant('bonifici.swal_title_statement-make-success') , this.translate.instant('bonifici.swal_text_statement-confirmed-success') , 'success' );
            this.rows = this.updateRowUi( row , 'status' , 'CONFIRMED' );
          } else {
            swal( this.translate.instant('bonifici.swal_title_statement-make-error') , this.translate.instant('bonifici.swal_text_statement-confirmed-error') , 'error');
          }
        });
      } else {
        swal.noop();
      }
    });
  }

  cancelStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-cancel'),
      html : this.translate.instant('bonifici.swal_text_bank_statement-cancel'),
      type : 'warning',
      showCancelButton : true,
    }).then((value) => {
      if ( value.value === true ) {
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.appService.editElement( '/bank-statement/status/' + row.id + '/CANCELED' , null ).subscribe((resp) => {
          // console.log( 'response of CANCELED bank statement' , resp )
          if ( resp.outcome && resp.outcome.success ) {
            swal( this.translate.instant('bonifici.swal_title_statement-cancel-success') , this.translate.instant('bonifici.swal_text_statement-canceled-success') , 'success' );
            this.rows = this.updateRowUi( row , 'status' , 'CANCELED' );
          } else {
            swal( this.translate.instant('bonifici.swal_title_statement-cancel-error') , this.translate.instant('bonifici.swal_text_statement-canceled-error') , 'error');
          }
        });
      }
    });

  }

  updateRowUi( _row , _key , _value ) {
    const _i = this.rows.indexOf(_row);
    _row[_key] = _value;
    this.rows[_i] = _row;
    // console.log( '_row' , _row )
    // console.log( 'this.rows' , this.rows[_i] )
    return this.rows = [...this.rows];
  }

  async getRelatedIntalmentsByPayout( dataPayouts ) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    const _resp = await this.appService.getAll( '/payout/' + dataPayouts.id + '/instalments' ).toPromise();
    if ( _resp.results ) {
      swal.close();
      return _resp.results;
    } else {
      swal('Ops!' , 'A causa di un problema inaspettato non è stato possibile recuperare maggiori informazioni sul bonifico selezionato' , 'error');
      return false;
    }
  }

  ngOnDestroy() {
    this.notifyNewStatement.unsubscribe();
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      this.tablePage = 1;
      this.loadElements(this.generateFiltersPath(), true);
      if ( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset();
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  onUndoneChange(event: boolean) {
    this.includeUndone = event;
    // this.onFlagExclusionChange();
    this.prepareForSearch();
  }

  // ---------- PROTECTED METHODS ---------- //

  protected downloadStatement(rowId: number) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    this.bonificiService.downloadBankStatement(rowId).subscribe(
      _ => {
        swal.close();
      }
    );
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that manages filters API url, creating custom strings with data start & end values used as request paths */
  private generateFiltersPath(): string {
    const queryParamsSearch: string[] = [];
    for (const check in this.searchForm.controls) {
      if (this.searchForm.controls[check] && this.searchForm.controls[check].value) {
        if (this.searchForm.controls[check].value.hasOwnProperty('startDate') &&
          this.searchForm.controls[check].value.startDate && this.searchForm.controls[check].value.endDate) {
          queryParamsSearch.push('startDate' + '=' + moment(this.searchForm.controls[check].value.startDate).format('YYYY-MM-DD')
            + '&' + 'endDate' + '=' + moment(this.searchForm.controls[check].value.endDate).format('YYYY-MM-DD'));
        } else if (check.endsWith('Date')) {
          queryParamsSearch.push('startDate' + '=' + moment(this.searchForm.controls[check].value).format('YYYY-MM-DD')
            + '&' + 'endDate' + '=' + moment(this.searchForm.controls[check].value).format('YYYY-MM-DD'));
        } else {
          queryParamsSearch.push(`${check}=${this.searchForm.controls[check].value}`);
        }
      }
    }
    return queryParamsSearch.join('&');
  }

  /** Method that manages when an element must be prevented from being displayed */
  // private onFlagExclusionChange() {
  //   if (!this.includeUndone) {
  //     this.rows = this.excludeUndoneDocuments();
  //   } else {
  //     if (this.allRows) {
  //       this.rows = this.allRows;
  //     }
  //   }
  // }

  /** Management method for element exclusion from list (when status CANCELED) */
  // private excludeUndoneDocuments() {
  //   const result: any = [];
  //   this.allRows.forEach(element => {
  //     if (!this.includeUndone && !this.isUndone(element)) {
  //       result.push(element);
  //     }
  //   });
  //   return result;
  // }

  // private isUndone(element: any) {
  //   return element.status === 'CANCELED' ? true : false;
  // }

}
