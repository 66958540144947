import { Component, OnInit, Input } from '@angular/core';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../core/services/base.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { UserService } from '../../../services/user.service';
import { SellerService } from '../../../services/seller.service'
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-agent-form',
  templateUrl: './agent-form.component.html',
  styleUrls: ['./agent-form.component.scss']
})
export class AgentFormComponent implements OnInit {
  @Input() userData: any;
  @Input() rates: any;
  @Input() merchantList: any;
  typeOfUser: any;
  idUser: any;
  //idReseller: any;
  idMerchant: any;
  idResponsible: any;
  profile: any;
  agentLevels: any = [];

  profileForm = this.formBuilder.group({
    idProfile: [5],
    idUser: [null],
    telephone: [''],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
    idMerchant: [null, Validators.required],
    idUserResponsible: [null, Validators.required]
  });

  days = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },
    { number: 10 },
    { number: 11 },
    { number: 12 },
    { number: 13 },
    { number: 14 },
    { number: 15 },
    { number: 16 },
    { number: 17 },
    { number: 18 },
    { number: 19 },
    { number: 20 },
    { number: 21 },
    { number: 22 },
    { number: 23 },
    { number: 24 },
    { number: 25 },
    { number: 26 },
    { number: 27 },
    { number: 28 }
  ]

  currencies = [
    {
      "id": 1, "name": "EUR", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "DOLLAR", "type": "word", "isChecked": false
    }]

  dum = [
    {
      "id": 1, "name": "First", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "Second", "type": "word", "isChecked": false
    }, {
      "id": 3, "name": "Third", "type": "date", "isChecked": false
    }]

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    private translate: TranslateService,
    public settings: SettingsService, 
    private sellerService : SellerService,
    private routerExtService: RouterExtService) {}

  ngOnInit() {
    this.typeOfUser = this.route.snapshot.params['usertype'];
    this.profile = localStorage.getItem("profile");
    this.idUser = this.route.snapshot.params['id'];

    if (this.profile != 'SUPERADMIN') {
      this.idMerchant = localStorage.getItem("idMerchant")
      this.profileForm.controls['idMerchant'].setValue(this.idMerchant)
    }
    if (this.profile == 'RESPONSIBLE') {
      this.idResponsible = localStorage.getItem("idUser")
      this.profileForm.controls['idUserResponsible'].setValue(this.idResponsible)
    }
    if (this.idUser) {
      this.idMerchant = this.userData.idMerchant
      this.profileForm.setValue({
        idProfile: 5,
        idUser: this.userData.idUser,
        telephone: this.userData.telephone ? this.userData.telephone: "",
        email: this.userData.email ? this.userData.email: "",
        name: this.userData.name ? this.userData.name: "",
        surname: this.userData.surname ? this.userData.surname: "",
        idMerchant: this.userData.idMerchant ? this.userData.idMerchant: null,
        idUserResponsible: this.userData.idUserResponsible ? this.userData.idUserResponsible: null,
      })
    }
    this.getResponsables();
  }

  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.valid) {
      this.save(this.profileForm.value)
    }
  }

  save(user) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.idUser) {
      this.appService.newElement('/user', user).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('general.success'), "", "success").then((result) => {
          this.navigationBack();
          });
        }else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
    else {
      this.appService.editElement('/user', user).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome && response.outcome.success === true) {
          swal(this.translate.instant('general.modificate'), "", "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
  }

  getResponsables() {
    let idMerchant = this.profileForm.get('idMerchant').value;
    if (idMerchant){
      this.userService.getAllUsers('idMerchant='+idMerchant+'&profile=RESPONSIBLE' ).subscribe((res: any)=> {
        this.agentLevels = res.results;  
      });
    }
  }

  onChangeMerchant(idMerchant) {
    this.idMerchant = idMerchant;

    this.profileForm.patchValue({
      idUserResponsible: null
    })

    this.getResponsables()
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/user');
    } else {
      this.location.back();
    }
  }

}
