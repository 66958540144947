import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IReseller } from '../../../models/reseller';
import { ResellerApiService } from '../../../services/reseller-api.service';

@Component({
  selector: 'sp-input-reseller-select',
  templateUrl: './input-reseller-select.component.html',
  styleUrls: ['./input-reseller-select.component.scss']
})
export class InputResellerSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IReseller>();

  resellerList: IReseller[];

  constructor(private resellerApiService: ResellerApiService) { }

  ngOnInit() {
    this.getResellerList();
  }

  getResellerList() {
    this.resellerApiService.getAll('onlyWithCompanyName=true').subscribe(resellers => {

      this.resellerList = resellers;

    })

  }

  changed(ev: IReseller) {
    this.itemSelected.emit(ev);
  }

}
