import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'sp-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label = '';
  @Input() id = '';
  @Input() default: boolean;

  @Output() itemToggled = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  changed(ev: boolean) {
    this.itemToggled.emit(ev);
  }

}
