
// import { HttpClient } from 'selenium-webdriver/http';
import { AppService } from '../../../core/services/base.service';
import { Router } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { RateService } from '../../../services/rate.service';
import { Component, OnInit, ViewChild, ViewEncapsulation , EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SharedService } from '../../../shared/shared.service';
import { SellerService } from '../../../services/seller.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-rate',
  templateUrl: './profile-rate.component.html',
  styleUrls: ['./profile-rate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileRateComponent implements OnInit {
  rateList: any = [];
  rataList: any = [];
  profile: any = {};
  rate: any = {};
  allStatus: any = [
    { "name": "Attivo" },
    { "name": "Bloccato" },
  ]
  @ViewChild('dataTable') table: any;
  sortProp: any;
  rows: any[] = [];
  expanded: any = {};
  timeout: any;
  selected = [];
  rows1: any;
  userSelected: any;
  merchantInfo: any;

  @Output() deletedChip = new EventEmitter<any>();

  sortOptions:Array<any> = [
    { "id": 1, "name": "description", "showField":"" },
    { "id": 2, "name": "instalmentNumber", "showField":"" },
    { "id": 3, "name": "firstInstalmentValue", "showField":"" },
    { "id": 4, "name": "startDelay", "showField":"" },
    { "id": 5, "name": "instalmentFrequency", "showField":"" }
  ]

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  options: any = {};
  tableRecordNumbers:number;

  searchForm: FormGroup;

  constructor(private router: Router,
    public appService: AppService,
    private logService: LoggingService,
    private translate: TranslateService,
    public colors: ColorsService,
    public settings: SettingsService,
    private rateService: RateService,
    public sharedService: SharedService,
    public sellerService : SellerService,
    private formBuilder: FormBuilder) {
      var self = this;
      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'));
      this.translate.get('sort').subscribe(function(obj:any) {
        self.sortOptions[0].showField = obj.description;
        self.sortOptions[1].showField = obj.instalment_number;
        self.sortOptions[2].showField = obj.first_instalment_value;
        self.sortOptions[3].showField = obj.start_delay_months;
        self.sortOptions[4].showField = obj.instalment_frequency;
      });
      
    // Overrides default total/empty messages
    translate.get("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );
    translate.get("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);

    this.searchForm = this.formBuilder.group({
      name: [null],
      instalmentNumber: [null],
      instalmentFrequency: [null],
      firstInstalmentType: [null],
      enabled: [null],
      idMerchant: [null]
    });
  }
  
  public ngOnInit(): void {
    this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
    this.profile = localStorage.getItem("profile")
    if (this.userSelected) {
      this.options.userSelected = this.userSelected
    }
    this.getAllRates(null, this.userSelected ? this.userSelected.idMerchant : null)
    if( localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER' ) {
      this.getMerchantInfo();
    }
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  toggleExpandRow(row,expanded) {
    this.rows1 = []
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  getAllRates(params = null, id = null) {
    let selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    let path;
    if( localStorage.getItem("profile") == "SUPERADMIN" ) {
      if(!selectedUser){
        path = "/instalment-plan/getAll"
      }
      else{
        path = "/instalment-plan/getAll/" + selectedUser.idMerchant
      }
    } else if( localStorage.getItem("profile") != "SUPERADMIN" ) {
      if( localStorage.getItem("profile") == "RESELLER" ) {
        path = "/instalment-plan/getAll/" + localStorage.getItem('idReseller')
      } else {
        path = "/instalment-plan/getAll/" + localStorage.getItem('idMerchant') 
      }
    }
    
    if(params != null) {
      path += "?" + params;
    }
    this.appService.getAll(path).subscribe((res: any) => {
      this.rows = res.results
    })
  }

  searchEvent(event) {
    let selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    let queryParamsSearch = this.getQueryParamsOfSearch(event)
    this.getAllRates(queryParamsSearch, this.userSelected ? this.userSelected.idMerchant : null)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  sortEvent(event) {
    // this.sortProp = name;
    const name = event.target.value;
    this.sortProp = name;
    var that = this;
    setTimeout(function () {
      that.rows = [...that.rows]
    })
  }

  getQueryParamsOfSearch(searchItems) {
    let queryParamsSearch = "";
    if (searchItems.keyword)
      queryParamsSearch += "keyword=" + searchItems.keyword

    if (searchItems.searchFromSelect.length > 0) {
      if (searchItems.keyword)
        queryParamsSearch += "&"
      queryParamsSearch += "profile=" + searchItems.searchFromSelect.join()
    }
    return queryParamsSearch;
  }

  statusSelectedEvent(event, row) {
    if (event.name == 'Attivo') {
      this.changeStatus({ idRata: row.id, enabled: true })
    } else if (event.name == 'Bloccato') {
      this.changeStatus({ idRata: row.id, enabled: false })
    }
    row.statusSelectOpened = false
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }

  toggleActivation(row) {
    this.changeStatus({ idRata: row.id, enabled: !row.enabled })
  }

  changeStatus(data) {
    this.appService.editElement('/instalment-plan/status', data).subscribe((res: any) => {
      this.prepareForSearch();
    })
  }

  chipDeleted() {
    this.deletedChip.emit()
  }

  selectedUser(user) {
    this.userSelected = user;

    if (this.userSelected) {
      this.searchForm.controls.idMerchant.setValue(this.userSelected.idMerchant);
      this.searchForm.controls.idMerchant.disable();
    } else {
      this.searchForm.controls.idMerchant.reset();
      this.searchForm.controls.idMerchant.enable();
    }

    this.prepareForSearch();
  }

  canEdit(rowData) {
    let loggedUser ;
    if( this.profile == "SUPERADMIN"){
      loggedUser = null
    } else if( this.profile == "RESELLER"){
      loggedUser = localStorage.getItem('idReseller')
    }
    else if( this.profile == "MANAGER"){
      loggedUser = localStorage.getItem('idMerchant')
    }

    if( rowData.idMerchant == loggedUser && (!this.merchantInfo || this.merchantInfo.state != 'INACTIVE') ) {
      return true
    } else {
      return false
    }
  }

  hasActionToShow( data ) {
    if( this.canEdit(data) ) {
      return true;
    } else {
      return false;
    }
  }

  prepareForSearch() {
    let queryParamsSearch = "";
    let idMerchant = null;
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (key != 'idMerchant') {
          if (Array.isArray(this.searchForm.controls[key].value)) {
            if (!!this.searchForm.controls[key].value.length) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
            }
          } else {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            queryParamsSearch += key + "=" + this.searchForm.controls[key].value
          }
        } else {
          idMerchant = this.searchForm.controls[key].value
        }
      }
    }

    this.getAllRates(queryParamsSearch, this.userSelected ? this.userSelected.idMerchant : idMerchant)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  prepareForCancel() {
    this.searchForm.reset();
    this.prepareForSearch();
  }

  getMerchantInfo() {
    this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((res) => {
      this.merchantInfo = res;
    })
  }

}
