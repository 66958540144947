import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IInstalmentStatus } from '../../../models/instalment';

@Component({
  selector: 'sp-input-instalment-status-select',
  templateUrl: './input-instalment-status-select.component.html',
  styleUrls: ['./input-instalment-status-select.component.scss']
})
export class InputInstalmentStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IInstalmentStatus>();

  statuses: IInstalmentStatus[];

  constructor() { }

  ngOnInit() {

    this.getStatuses();

  }

  getStatuses() {
    this.statuses = [
      {
        id: 1,
        code: 'PLANNED',
        description: 'sale.rate_detail_status.PLANNED'
      },
      {
        id: 2,
        code: 'PAID',
        description: 'sale.rate_detail_status.PAID'
      },
      {
        id: 3,
        code: 'PROCESSING',
        description: 'sale.rate_detail_status.PROCESSING'
      },
      {
        id: 4,
        code: 'ERROR',
        description: 'sale.rate_detail_status.ERROR'
      },
      {
        id: 5,
        code: 'CLOSED',
        description: 'sale.rate_detail_status.CLOSED'
      },
      {
        id: 6,
        code: 'CANCELED',
        description: 'sale.rate_detail_status.CANCELED'
      },
      {
        id: 7,
        code: 'UNSOLVED',
        description: 'sale.rate_detail_status.UNSOLVED'
      },
      {
        id: 8,
        code: 'ONGOING_INQUIRY',
        description: 'sale.rate_detail_status.ONGOING_INQUIRY'
      },
      {
        id: 9,
        code: 'CLOSED_INQUIRY',
        description: 'sale.rate_detail_status.CLOSED_INQUIRY'
      }
    ]
  }

  changed(ev: IInstalmentStatus) {
    this.itemSelected.emit(ev);
  }

}
