import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { StatisticsComponent } from './statistics/statistics.component';
import { RateOfDayComponent } from './rate-of-day/rate-of-day.component';
import { FeeStatisticsComponent } from './fee-statistics/fee-statistics.component';

const routes: Routes = [
  { path: '', redirectTo: 'home',  pathMatch: 'full' },
	{ path: '', component: StatisticsComponent },
	// { path: 'new', component: NewSaleComponent },
	// { path: 'edit/:id', component: NewSaleComponent },
	// { path: 'filter/:status', component: SalesComponent }
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule.forChild(routes),
		NgxDatatableModule,
		SharedModule,
		NgSelectModule,
	],
	declarations: [StatisticsComponent , RateOfDayComponent, FeeStatisticsComponent],
	exports: [
        RouterModule
    ]
})
export class StatisticsModule { }
