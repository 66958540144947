import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-external-api',
  templateUrl: './external-api.component.html',
  styleUrls: ['./external-api.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ExternalApiComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {
  }

}
