import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';


@Injectable({
  providedIn: 'root'
})
export class SellerService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }


  getAllSellers(queryParams = null) {
    var path = environment.restBaseUrl + "/merchant/getAll"
    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  insertSeller(seller){
    var path = environment.restBaseUrl + "/merchant/";
    return this.http
    .post<any[]>(path, seller, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("insertSeller", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("insertSeller", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getSellerDatas(idMerchant) {
    var path = environment.restBaseUrl + "/merchant/"+idMerchant

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  updateSellerDatas(seller){
    var path = environment.restBaseUrl + "/merchant";
    return this.http
    .put<any[]>(path, seller, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("updateSellerDatas", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("updateSellerDatas", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getProfileDatas() {
    var path = environment.restBaseUrl + "/user/profile"

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getSellerDetails(idMerchant) {
    var path = environment.restBaseUrl + "/merchant/"+idMerchant
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res && res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));

  }

  changeStatus(status){
    var path = environment.restBaseUrl + "/user";
    return this.http
    .post<any[]>(path, status, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("insertUser", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("insertUser", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getSellerProfile(idMerchant) {
    var path = environment.restBaseUrl + "/merchant/"+idMerchant+"/getAll"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  confirmTosMerchant(token) {
    var path = environment.restBaseUrl + "/merchant/tos/" + token
    return this.http
    .put<any[]>(path, null , this.getRequestOptionArgsNull())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("tosMerchant", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("tosMerchant", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  statusTos( idMerchant ) {
    var path = environment.restBaseUrl + "/merchant/tos-accepted/"+idMerchant
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getFeeConfigurationProfiles() {
    var path = environment.restBaseUrl + "/fee-configuration/profiles"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  createFeeConfiguration( request ) {
    var path = environment.restBaseUrl + "/fee-configuration";
    return this.http
    .post<any[]>(path, request , this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("insertUser", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("insertUser", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getConfigurations( idMerchant = null ) {
    var path = environment.restBaseUrl + '/fee-configuration'
    if( idMerchant != null ) {
      path = path + '/?idMerchant=' + idMerchant
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));

  }

  updateConfiguration( confObj ) {
    var path = environment.restBaseUrl + "/fee-configuration";
    return this.http
    .put<any[]>(path, confObj, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("updateSellerDatas", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("updateSellerDatas", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  deleteConfiguration( idConfiguration ) {
    var path = environment.restBaseUrl + '/fee-configuration/' + idConfiguration;
    let options = {
      headers: this.getRequestOptionArgs(),
    }
    return this.http
      .delete<any[]>(path, this.getRequestOptionArgs() )
      .pipe(map(
      (response: any) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':  localStorage.getItem("token") || ''
      })
    };
    return httpOptions;
  }

  getRequestOptionArgsNull() : any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }
}
