import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IMerchant } from '../../../models/merchant';
import { MerchantApiService } from '../../../services/merchant-api.service';

@Component({
  selector: 'sp-input-merchant-select',
  templateUrl: './input-merchant-select.component.html',
  styleUrls: ['./input-merchant-select.component.scss']
})
export class InputMerchantSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IMerchant>();

  merchantList: IMerchant[];

  constructor(private merchantApiService: MerchantApiService) { }

  ngOnInit() {
    this.getMerchantList();
  }

  getMerchantList() {
    this.merchantApiService.getAll().subscribe(merchants => {

      this.merchantList = merchants;

    })

  }

  changed(ev: IMerchant) {
    this.itemSelected.emit(ev);
  }

}
