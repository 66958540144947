
import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder} from '@angular/forms';
import { Location } from '@angular/common';
import { AppService } from '../../../core/services/base.service';
import { LoggingService } from '../../../core/services/log.service';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { UserService} from '../../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-all-users-form',
  templateUrl: './all-users-form.component.html',
  styleUrls: ['./all-users-form.component.scss']
})

export class AllUsersFormComponent implements OnInit {
  @Input() userData: any;
  idProfile: any;
  // displayEmail: boolean;
  selectedProfile: any;
  isUpdate;
  typeOfUser:any;
  profileForm = this.formBuilder.group({
    idProfile: [1],
    idUser: [null],
    telephone: [''],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],

  });

  msgTranslation;
  errorTranslation;
  errorMessage;
  originalUserDatas: {};

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private userService: UserService,
    private route:ActivatedRoute,
    private location: Location,
    private routerExtService: RouterExtService) {
      this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
    }

  ngOnInit() {
    this.typeOfUser = this.route.snapshot.params['usertype'];
    if(this.userData != null) {
      this.isUpdate = true;
      this.profileForm.setValue({
        idProfile: 1,
        idUser: this.userData.idUser,
        telephone: this.userData.telephone ? this.userData.telephone: "",
        email: this.userData.email ? this.userData.email: "",
        name: this.userData.name ? this.userData.name: "",
        surname: this.userData.surname ? this.userData.surname: "",
      })
    }
  }

  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.status == 'VALID') {
      this.save(this.profileForm.value)
    }
  }

  save(user) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.isUpdate) {
      this.userService.insertUser(user).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('general.success'), "", "success").then((result) => {
            this.navigationBack();
          });
        } else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        swal("Error", "", "error");
      })
    }
    else {
      this.userService.updateUserDatas(user).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('general.modificate'), "", "success").then((result) => {
            this.navigationBack();
          });
        } else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal("Error", this.errorMessage, "error");
      })
    }
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/user');
    } else {
      this.location.back();
    }
  }
}

