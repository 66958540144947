
import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorsService } from '../../../shared/colors/colors.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../../core/settings/settings.service';
import { SalesService } from '../../../services/sales.service';
import { SellerService } from '../../../services/seller.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalesComponent implements OnInit,OnDestroy {
  activePage:String='sa';
  profile:any;
  userSelected: any;
  public merchantDetail: any;

  isChipClosed:boolean = false;

  constructor(
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private route: ActivatedRoute,
    private salesService:SalesService , 
    public sellerService:SellerService) {
      this.merchantDetail = {softwareHouse : false}
    }

  ngOnInit() {
    if(localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER')  {
      this.getMerchantDetail()
    }
    this.profile = localStorage.getItem("profile")
    let statusUrl = localStorage.getItem ("saleStatus");
    if (statusUrl == 'pendenti' || statusUrl == 'pagato') {
      this.activePage = 'sa'
    }
    else if (statusUrl == 'insoluti'){
      this.activePage = 'ra'
    }
    const activePage = localStorage.getItem("activePage");
    if (activePage) {
      this.activePage = activePage;
      localStorage.removeItem("activePage");
    }
  }

  setActivePage(tab){
    this.activePage = tab;
  }
  
  ngOnDestroy(){
    localStorage.removeItem("saleStatus");
    localStorage.removeItem("saleDate");
  }
  
  selectedUser(user) {
    this.userSelected = user;
  }

  clearSelectedUser() {
    this.isChipClosed = true;
  }

  getMerchantDetail() {
    this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((res) => {
      this.merchantDetail = res;
      this.getMerchantStatusTos();
    })
  }

  getMerchantStatusTos() {
    this.sellerService.statusTos( localStorage.getItem('idMerchant') ).subscribe( (res) => {
      this.merchantDetail.tos_accepted = res.accepted
    })
  }

}
