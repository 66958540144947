import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    profile:any;
    constructor( private settings: SettingsService, private router: Router) {
      let { navigationUrl } = history.state;
      navigationUrl = navigationUrl || this.router.url;
      if (navigationUrl.startsWith('/user')) {
        navigationUrl = '/user';
      } else if (navigationUrl.startsWith('/profile-rate')) {
        navigationUrl = '/profile-rate';
      }
      this.profile = localStorage.getItem("profile");
      // Check token data
      if (localStorage.getItem("token") === null) {
        this.settings.sessionExpired();
        this.router.navigate(["/login/0"], { state: { navigationUrl } });
      }

    }

    ngOnInit() {
    }

}
