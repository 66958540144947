import { Component, OnInit , ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { AppService } from '../../../core/services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { SellerService } from '../../../services/seller.service';
import { SalesService } from '../../../services/sales.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';
import swal from 'sweetalert2';

moment.locale('it', localization);

@Component({
  selector: 'app-fee-statistics',
  templateUrl: './fee-statistics.component.html',
  styleUrls: ['./fee-statistics.component.scss']
})
export class FeeStatisticsComponent implements OnInit {
  locationPage: string;
  profile: any;
  tableRecordNumbers: number;
  date: Date;
  options: any = [];
  rows: any[] = [];
  merchantList: any = [];
  rowDetails: any = [];
  _startTime = ' 00:00:00';
  _endTime = ' 23:59:59';
  _moreInfo: any;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  @ViewChild('dataTable') table: any;

  searchForm: FormGroup;

  constructor(
    private datepipe: DatePipe ,
    private sharedService: SharedService ,
    protected appService: AppService,
    private translate: TranslateService ,
    private sellerService: SellerService,
    private saleService: SalesService,
    private formBuilder: FormBuilder) {
      this.locationPage = 'stats-fee';
      this.profile = localStorage.getItem('profile');
      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);

      // Overrides default total/empty messages
      translate.get("datatable_message.lista_totali_righe").subscribe((res: string ) => this.my_messages.totalMessage = res );
      translate.get("datatable_message.lista_vuota_righe").subscribe((res: string ) => this.my_messages.emptyMessage = res);

      this.searchForm = this.formBuilder.group({
        //dateRange: [{ startDate: moment().subtract(30, 'days'), endDate: moment() }, Validators.required],
        //dateFrom: [{ startDate: moment().subtract(30, 'days'), endDate: moment().subtract(30, 'days') }, Validators.required],
        //dateTo: [{ startDate: moment(), endDate: moment() }, Validators.required],
        dateRange: [[ moment().subtract(30, 'days').toDate(), moment().toDate() ], Validators.required],
        idMerchant: [null]
      });
  }

  ngOnInit() {
    this.date = new Date();
    this.options.dates = this.initDefaultRangeDates("chip");
    this.initFeeTable();
  }

  initDefaultRangeDates( return_type: string = null ) {
    const date = new Date();
    const today = this.sharedService.formatDateForServer(date);
    date.setDate(date.getDate() - 30);
    const beforeDate = this.sharedService.formatDateForServer(date);
    let queryParamsDate;
    if ( return_type === null ) {
      queryParamsDate = "startDate=" + beforeDate + "&endDate=" + today;
    } else if ( return_type === "obj" ) {
      queryParamsDate = { date : { startDate : beforeDate , endDate : today } };
    } else if ( return_type === "chip" ) {
      queryParamsDate = beforeDate + "/" + today;
    }
    return queryParamsDate;
  }

  searchEvent( event ) {
    this.getFeeList( event );
    if ( this.table ) {
      this.table.offset = 0;
    }
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      const queryParamsSearch = {};
      for (const key in this.searchForm.controls) {
        /*if (key === 'dateRange') {
          queryParamsSearch['startDate'] = this.searchForm.controls[key].value.startDate;
          queryParamsSearch['endDate'] = this.searchForm.controls[key].value.endDate;
        }*/
        if (key.startsWith('date')) {
          if (key == 'dateFrom') {
            queryParamsSearch['startDate'] = this.searchForm.controls[key].value.startDate;
          } else if (key == 'dateTo') {
            queryParamsSearch['endDate'] = this.searchForm.controls[key].value.endDate;
          } else if (key == 'dateRange') {
            queryParamsSearch['startDate'] = moment(this.searchForm.controls[key].value[0]);
            queryParamsSearch['endDate'] = moment(this.searchForm.controls[key].value[1]);
          }
        } else {
          if (this.searchForm.controls[key].value) {
            queryParamsSearch[key] = this.searchForm.controls[key].value;
          }
        }
      }

      this.getFeeList(queryParamsSearch);
      if ( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset({
      //dateRange: { startDate: moment().subtract(30, 'days'), endDate: moment() }
      //dateFrom: { startDate: moment().subtract(30, 'days'), endDate: moment().subtract(30, 'days') },
      //dateTo: { startDate: moment(), endDate: moment() },
      dateRange: [ moment().subtract(30, 'days').toDate(), moment().toDate() ]
    });
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  getFeeList( qParams = null ) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let params = '';
    if (qParams != null ) {
      params += '?';
      if (qParams.startDate) {
        params += 'dateFrom=' + qParams.startDate.format("YYYY-MM-DD") + this._startTime + '&';
        params += 'dateTo=' + qParams.endDate.format("YYYY-MM-DD") + this._endTime;
      }
      if (qParams.idMerchant) {
        params += '&idMerchant=' + qParams.idMerchant;
      }
    } else {
      params += '?';
      params += 'dateFrom=' + moment().subtract(30, 'days').format("YYYY-MM-DD") + this._startTime + '&';
      params += 'dateTo=' + moment().format("YYYY-MM-DD") + this._endTime;
    }

    this.appService.getAll('/sales/fees' + params).subscribe((response) => {
      this.rows = response.fees;
      setTimeout(() => {
        this.findMerchantById();
        this.rows = this.sharedService.revertArray( this.rows );
      });
      swal.close();
    });
  }

  getMerchantList() {
    this.appService.getAll('/merchant/getAll').subscribe((response) => {
      this.merchantList = response.results;
    });
  }

  findMerchantById() {
    this.rows.forEach(element => {
      element.merchantDetail = this.merchantList.find( x => x.idMerchant === element.idMerchant );
    });
  }

  initFeeTable() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    this.appService.getAll('/merchant/getAll').subscribe((response) => {
      this.merchantList = response.results;
      this.getFeeList();
    });
  }

  toggleExpandRow(row, expanded) {
    if (!expanded && !row.feeDetail) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      this.sellerService.getSellerDetails(row.idMerchant).subscribe((res: any) => {
        row['detail'] = res;
        this.table.rowDetail.toggleExpandRow(row);
        swal.close();
      });
      if ( row.type === 'instalment' ) {
        this.saleService.getSalesDetails(row.idSale).subscribe((response) => {
          row['feeDetail'] = this.getInstalmentOrder( response.results , row.id );
          swal.close();
        });
      } else if ( row.type === 'payout' ) {
        // row['feeDetail'] = this.getPayoutDetail( row.id )
        this.appService.getElement('/payout/' + row.id).subscribe((response) => {
          row['feeDetail'] = response;
          swal.close();
        });
      }
    } else {
      this.table.rowDetail.toggleExpandRow(row);
    }
  }

  getInstalmentDetail( idSale , idInstalment = null ) {
    let r;
    this.saleService.getSalesDetails(idSale).subscribe((response) => {
      r = response.results;
      if ( idInstalment ) {
        r = this.getInstalmentOrder( r , idInstalment );
      }
      this._moreInfo = r;
    });
  }

  getPayoutDetail( idPayout ) {
    let r;
    this.appService.getElement('/payout/' + idPayout).subscribe((response) => {
      r = response.results;
    });
    return r;
  }

  getInstalmentOrder( sale , instalment ) {
    const resultInstalment = sale.find(x => x.id === instalment );
    resultInstalment.order = sale.findIndex(x => x.id === instalment );
    return resultInstalment;
  }

}
