import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'sp-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss']
})
export class InputDatepickerComponent implements OnInit {

  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() locale: any = {applyLabel: 'ok', clearLabel: 'clear', format: 'DD/MM/YYYY'};
  @Input() showClearButton: boolean = false;
  @Input() autoApply: boolean = true;
  @Input() showDropdowns: boolean = true;
  @Input() singleDatePicker: boolean = true;
  @Input() opens: string = 'center';
  @Input() drops: string = 'down';

  constructor() { }

  ngOnInit() {
  }

}
