import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
//import { InputEditorModule, TextAreaEditorModule } from 'angular-inline-editors-4';
import { NgxSummernoteModule } from 'ngx-summernote';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { AdvanceSearchComponent } from '../core/advance-search/advance-search.component';
import { ChipComponent } from '../routes/elements/chip/chip.component';
import { SidebarUsersComponent } from '../layout/sidebar-users/sidebar-users.component';
import { VatNumberValidator } from './validators/vat-number.directive';
import { FixedNumberValidator } from './validators/fiscal-code.directive';
import { StatusSelectComponent } from '../routes/elements/status-select/status-select.component';
import { PasswordValidator } from './validators/password.validator';
import { InsertUserComponent } from '../routes/users/insert-user/insert-user.component';

import { SafePipe } from './pipes/safe.pipe';

import { AllUsersFormComponent } from '../routes/forms/all-users-form/all-users-form.component';
import { ResellerFormComponent } from '../routes/forms/reseller-form/reseller-form.component';
import { ManagerFormComponent } from '../routes/forms/manager-form/manager-form.component';
import { ResponsableFormComponent } from '../routes/forms/responsable-form/responsable-form.component';
import { AgentFormComponent } from '../routes/forms/agent-form/agent-form.component';
import { SmoothHeightComponent } from './smoth-height.component';
import { MerchantFormComponent } from '../routes/forms/merchant-form/merchant-form.component';
import { ExternalApplicationFormComponent } from '../routes/forms/external-application-form/external-application-form.component';
import { ExternalMerchantFormComponent } from '../routes/forms/external-merchant-form/external-merchant-form.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { StatusTrashComponent } from '../routes/elements/status-trash/status-trash.component';
import { FilterSearchComponent } from './component/filter-search/filter-search.component';
import { InputTextComponent } from './component/base/input-text/input-text.component';
import { InputTextRangeComponent } from './component/base/input-text-range/input-text-range.component';
import { InputCheckboxComponent } from './component/base/input-checkbox/input-checkbox.component';
import { InputDatepickerComponent } from './component/base/input-datepicker/input-datepicker.component';
import { InputDatepickerRangeComponent } from './component/base/input-datepicker-range/input-datepicker-range.component';
import { InputDatepickerBootstrapComponent } from './component/base/input-datepicker-bootstrap/input-datepicker-bootstrap.component';
import { InputUserSelectComponent } from './component/base/input-user-select/input-user-select.component';
import { InputMerchantSelectComponent } from './component/base/input-merchant-select/input-merchant-select.component';
import { InputResellerSelectComponent } from './component/base/input-reseller-select/input-reseller-select.component';
import { InputUserStatusSelectComponent } from './component/base/input-user-status-select/input-user-status-select.component';
import { InputMerchantStatusSelectComponent } from './component/base/input-merchant-status-select/input-merchant-status-select.component';
import { InputSaleStatusSelectComponent } from './component/base/input-sale-status-select/input-sale-status-select.component';
import { InputInstalmentStatusSelectComponent } from './component/base/input-instalment-status-select/input-instalment-status-select.component';
import { InputInstalmentDisputedStatusSelectComponent } from './component/base/input-instalment-disputed-status-select/input-instalment-disputed-status-select.component';
import { InputInstalmentRefundStatusSelectComponent } from './component/base/input-instalment-refund-status-select/input-instalment-refund-status-select.component';
import { InputInstalmentFrequencySelectComponent } from './component/base/input-instalment-frequency-select/input-instalment-frequency-select.component';
import { InputFirstInstalmentTypeSelectComponent } from './component/base/input-first-instalment-type-select/input-first-instalment-type-select.component';
import { InputTransferStatusSelectComponent } from './component/base/input-transfer-status-select/input-transfer-status-select.component';
import { InputDocumentStatusSelectComponent } from './component/base/input-document-status-select/input-document-status-select.component';
import { InputPaymentStatusSelectComponent } from './component/base/input-payment-status-select/input-payment-status-select.component';
import { InputStatementStatusSelectComponent } from './component/base/input-statement-status-select/input-statement-status-select.component';
import { InputStatementPaymentStatusSelectComponent } from './component/base/input-statement-payment-status-select/input-statement-payment-status-select.component';
import { InputStatusSelectComponent } from './component/base/input-status-select/input-status-select.component';
import { InputSortingSelectComponent } from './component/base/input-sorting-select/input-sorting-select.component';
import { InputLanguageSelectComponent } from './component/base/input-language-select/input-language-select.component';
import { InlineEditComponent } from './component/base/inline-edit/inline-edit.component';
import { FilterSectionComponent } from './component/base/filter-section/filter-section.component';
import { StripeElementComponent } from './component/stripe-element/stripe-element.component';

import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');

import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale, frLocale, deLocale, esLocale } from 'ngx-bootstrap/locale';
defineLocale('it', itLocale);
defineLocale('fr', frLocale);
defineLocale('de', deLocale);
defineLocale('es', esLocale);

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToasterModule.forRoot(),
        NgSelectModule,
        NgxDaterangepickerMd.forRoot(),
        AngularDualListBoxModule,
		/*InputEditorModule,
		TextAreaEditorModule*/
        NgxSummernoteModule
    ],
    providers: [
        ColorsService
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        SafePipe,
        AdvanceSearchComponent,
        ChipComponent,
        SidebarUsersComponent,
        VatNumberValidator,
        PasswordValidator,
        FixedNumberValidator,
        StatusSelectComponent,
        StatusTrashComponent,
        InsertUserComponent,
        ResellerFormComponent,
        AllUsersFormComponent,
        ResponsableFormComponent,
        AgentFormComponent,
        ManagerFormComponent,
        SmoothHeightComponent,
        MerchantFormComponent,
        ExternalApplicationFormComponent,
        ExternalMerchantFormComponent,
        FooterComponent,
        FilterSearchComponent,
        InputTextComponent,
        InputTextRangeComponent,
        InputCheckboxComponent,
        InputDatepickerComponent,
        InputDatepickerRangeComponent,
        InputDatepickerBootstrapComponent,
        InputUserSelectComponent,
        InputMerchantSelectComponent,
        InputResellerSelectComponent,
        InputUserStatusSelectComponent,
        InputMerchantStatusSelectComponent,
        InputSaleStatusSelectComponent,
        InputInstalmentStatusSelectComponent,
        InputInstalmentDisputedStatusSelectComponent,
        InputInstalmentRefundStatusSelectComponent,
        InputInstalmentFrequencySelectComponent,
        InputFirstInstalmentTypeSelectComponent,
        InputTransferStatusSelectComponent,
        InputDocumentStatusSelectComponent,
        InputPaymentStatusSelectComponent,
        InputStatementStatusSelectComponent,
        InputStatementPaymentStatusSelectComponent,
        InputStatusSelectComponent,
        InputSortingSelectComponent,
        InputLanguageSelectComponent,
        InlineEditComponent,
        FilterSectionComponent,
        StripeElementComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        DatepickerModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToasterModule,
        NgxSummernoteModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        SafePipe,
        AdvanceSearchComponent,
        ChipComponent,
        SidebarUsersComponent,
        VatNumberValidator,
        PasswordValidator,
        FixedNumberValidator,
        StatusSelectComponent,
        StatusTrashComponent,
        InsertUserComponent,
        ResellerFormComponent,
        AllUsersFormComponent,
        ResponsableFormComponent,
        AgentFormComponent,
        ManagerFormComponent,
        SmoothHeightComponent,
        MerchantFormComponent,
        ExternalApplicationFormComponent,
        ExternalMerchantFormComponent,
        FooterComponent,
        FilterSearchComponent,
        InputTextComponent,
        InputTextRangeComponent,
        InputCheckboxComponent,
        InputDatepickerComponent,
        InputDatepickerRangeComponent,
        InputDatepickerBootstrapComponent,
        InputUserSelectComponent,
        InputMerchantSelectComponent,
        InputResellerSelectComponent,
        InputUserStatusSelectComponent,
        InputMerchantStatusSelectComponent,
        InputSaleStatusSelectComponent,
        InputInstalmentStatusSelectComponent,
        InputInstalmentDisputedStatusSelectComponent,
        InputInstalmentRefundStatusSelectComponent,
        InputInstalmentFrequencySelectComponent,
        InputFirstInstalmentTypeSelectComponent,
        InputTransferStatusSelectComponent,
        InputDocumentStatusSelectComponent,
        InputPaymentStatusSelectComponent,
        InputStatementStatusSelectComponent,
        InputStatementPaymentStatusSelectComponent,
        InputStatusSelectComponent,
        InputSortingSelectComponent,
        InputLanguageSelectComponent,
        InlineEditComponent,
        FilterSectionComponent,
        StripeElementComponent
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
