import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IUserStatus } from '../../../models/user';

@Component({
  selector: 'sp-input-user-status-select',
  templateUrl: './input-user-status-select.component.html',
  styleUrls: ['./input-user-status-select.component.scss']
})
export class InputUserStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IUserStatus>();

  statuses: IUserStatus[];

  constructor() { }

  ngOnInit() {

    this.getStatuses();

  }

  getStatuses() {
    this.statuses = [
      {
        id: 1,
        code: 'Nuovo',
        description: 'user.nuovo'
      },
      {
        id: 2,
        code: 'Attivo',
        description: 'user.attivo'
      },
      {
        id: 3,
        code: 'Bloccato',
        description: 'user.bloccato'
      }
    ]
  }

  changed(ev: IUserStatus) {
    this.itemSelected.emit(ev);
  }

}
