import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../../core/services/base.service';
import { IMerchant } from '../models/merchant';

enum MerchantAPI {
  getAll = "/merchant/getAll"
}

@Injectable({
  providedIn: 'root'
})
export class MerchantApiService {

  constructor(private appService: AppService) { }

  getAll(): Observable<IMerchant[]> {
    return this.appService.getAll(MerchantAPI.getAll).pipe(
      map(response => {
        return response.results
      })
    )
  }

}