import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input('word') word: any;
  @Input('id') id: any;
  @Input('type') type: any;
  @Input('title') title: any;
  dates:any;
  user: any;
  isMerchant: boolean = false

  @Output() delete = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    if(this.type == 'date' || this.type == 'day')
    this.dates = this.word.split('/');
    // console.log(this.word,"this.word")
    this.user = JSON.parse(localStorage.getItem("selectedUser"))
    if (typeof this.user !== 'undefined') {
      this.isMerchant = true
    }
  }
  deleteChip() {
    this.delete.emit({ "id": this.id, "word": this.word });
  }
  updateChipValue(){
    if( this.type == 'day' ) {
      this.dates = this.word;
    } else if ( this.type == 'date' ) {
      this.dates = this.word.split('/');
    }
    
  }
  ngOnChanges() {
    this.updateChipValue()
  }
}
