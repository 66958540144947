import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISortingType } from '../../../models/base';

@Component({
  selector: 'sp-input-sorting-select',
  templateUrl: './input-sorting-select.component.html',
  styleUrls: ['./input-sorting-select.component.scss']
})
export class InputSortingSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() sortings: ISortingType[] = [];
  @Output() itemSelected = new EventEmitter<ISortingType>();

  constructor() { }

  ngOnInit() {
  }

  changed(ev: ISortingType) {
    this.itemSelected.emit(ev);
  }

}
