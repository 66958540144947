import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SharedModule } from '../../shared/shared.module';
import { UserComponent } from './user.component';
import { UserManageComponent } from './user.manage.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TableExpandableRows } from './data-table/data-table.component';
import { InsertUserComponent } from './insert-user/insert-user.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { Ng9PasswordStrengthBarModule } from 'ng9-password-strength-bar';

const routes: Routes = [
  {
    path: '',
    component: UserComponent,
  },
  {
    path: 'new/:usertype',
    component: InsertUserComponent,
  },
  { path: 'edit/:usertype/:id', component: InsertUserComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'insert-user', component: InsertUserComponent },
  { path: 'new-profile', component: UserProfileComponent },
  { path: 'activate', component: ActivateUserComponent },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    NgSelectModule,
    AngularDualListBoxModule,
    Ng9PasswordStrengthBarModule,
  ],
  declarations: [
    ActivateUserComponent,

    UserComponent,
    UserManageComponent,
    ProfileComponent,
    ChangePasswordComponent,
    TableExpandableRows,
    UserProfileComponent,

  ],
  exports: [
    RouterModule,
  ]
})
export class UsersModule {

}
