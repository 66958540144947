import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;
  public profile = localStorage.getItem('profile')

  @Output() filterAdd: EventEmitter<boolean> = new EventEmitter()

  constructor(private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }

  getAllSales(queryParams = null, id = null) {
    var path = environment.restBaseUrl + "/sales/list" ;
    /*if( this.settings.getLocalStorage('profile') != "SUPERADMIN" ) {
      if ( this.settings.getLocalStorage('profile') == "RESELLER" ) {
        path += "/" + this.settings.getLocalStorage("idReseller");
      } else {
        path += "/" + this.settings.getLocalStorage("idMerchant");
      }
    }*/
    if (id) {
      path += "/" + id
    }
    
    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }
  
  getSalesDetails(idSale) {
    var path = environment.restBaseUrl + "/instalment/list?idSale=" + idSale
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getAllRate(queryParams, dateParams) {
    var path = environment.restBaseUrl + "/instalment-plan/list"
    if (queryParams) {
      path += "&" + queryParams
    }

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  searchForClients(name) {
    var path = environment.restBaseUrl + "/customer/find/" + name
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }
  formatDate(javascriptDate:Date) {
    var month = javascriptDate.getMonth() < 10 ? "0" + javascriptDate.getMonth() : javascriptDate.getMonth();
    var day = javascriptDate.getDate() < 10 ? "0" + javascriptDate.getDate() : javascriptDate.getDate();
    return javascriptDate.getFullYear() + "-" + month + "-" + day;
  }

  getRateDetails(idSale) {
    var path = environment.restBaseUrl + "/sales/" + idSale;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getInstalmentPaymentDetails(idInstalment) {
    var path = environment.restBaseUrl + "/instalment/payment/" + idInstalment;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));

  }
  
  getClient() {
    return {
      "id": 1, "name": "Hello", "type": "word", "isChecked": false
    }
  }
  getAllClients() {
    var path = environment.restBaseUrl + "/customer/getAll"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  insertSale(sale) {
    var path = environment.restBaseUrl + "/sales";
    return this.http
      .post<any[]>(path, sale, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("insertSale", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("insertSale", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  sendInstalmentMail( idInstalment ) {
    var path = environment.restBaseUrl + "/payment/mail/" + idInstalment;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("sendInstalmentMail", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("sendInstalmentMail", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  generateInstalmentCode( idInstalment ) {
    var path = environment.restBaseUrl + "/instalment/generate-code/" + idInstalment;
    return this.http.put<any[]>(path, '' , this.getRequestOptionArgs())
    .pipe(map(
      (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("generateInstalmentCode", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("generateInstalmentCode", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
      return throwError(error);
    }
    ));
  }

  getInstalmentPaymentLink( idInstalment ) {
    var path = environment.restBaseUrl + "/payment/link/" + idInstalment;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("getInstalmentPaymentLink", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("getInstalmentPaymentLink", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
      return throwError(error);
    }
    ));
  }

  cancelSale(idSale) {
    var path = environment.restBaseUrl + "/sales/" + idSale + "/cancel";
    return this.http
      .put<any[]>(path, '', this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("cancelSale", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("cancelSale", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  editSale(idSale, sale) {
    var path = environment.restBaseUrl + "/sales/" + idSale + "/edit";
    return this.http
      .put<any[]>(path, sale, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("editSale", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("editSale", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  sendSaleEditedMail( idSale ) {
    var path = environment.restBaseUrl + "/sales/edited/mail/" + idSale;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("sendSaleEditedMail", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("sendSaleEditedMail", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getSaleData(token) {
    var path = environment.restBaseUrl + "/sales/data/" + token;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgsNull())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("getSaleData", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("getSaleData", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse.error);
      }
    ));
  }

  confirmSaleChange(token) {
    var path = environment.restBaseUrl + "/sales/change/" + token;
    return this.http
      .put<any[]>(path, null, this.getRequestOptionArgsNull())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("confirmSaleChange", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("confirmSaleChange", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse.error);
      }
    ));
  }

  insertTopUpSale(sale) {
    var path = environment.restBaseUrl + "/sales/topup";
    return this.http
      .post<any[]>(path, sale, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("insertTopUpSale", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("insertTopUpSale", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  getSalePMData(token) {
    var path = environment.restBaseUrl + "/sale/pm/data/" + token;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgsNull())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("getSalePMData", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("getSalePMData", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse.error);
      }
    ));
  }

  getSalePaymentMethods(token) {
    var path = environment.restBaseUrl + "/sale/pm/list/" + token;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgsNull())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("getSalePaymentMethods", "SUCCESS", 200);
          return res;
        } else {
          this.logger.log("getSalePaymentMethods", "FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse.error);
      }
    ));
  }

  editSalePaymentMethod(token, paymentMethod) {
    var path = environment.restBaseUrl + "/sale/pm/edit/" + token;
    return this.http
      .put<any[]>(path, paymentMethod, this.getRequestOptionArgsNull())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("editSalePaymentMethod", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("editSalePaymentMethod", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse.error);
      }
    ));
  }

  getSalePaymentMethodEditLink( idSale ) {
    var path = environment.restBaseUrl + "/sale/pm/" + idSale + "/edit/link";
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("getSalePaymentMethodEditLink", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("getSalePaymentMethodEditLink", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
      return throwError(error);
    }
    ));
  }

  sendSalePaymentMethodEditMail( idSale ) {
    var path = environment.restBaseUrl + "/sale/pm/" + idSale + "/edit/mail";
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("sendSalePaymentMethodEditMail", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("sendSalePaymentMethodEditMail", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") || ''
      })
    };
    return httpOptions;
  }

  getRequestOptionArgsNull() : any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

}

// {
//   "service":"fdagfdgdada",
// "firstInstalmentType":"1",
//  "email":"fsdgh@gmail.com",
//   "name":"fsdgs@gmail.com",
//   "surname":"fsdg@gmail.com",
//    "phoneNumber":"dsg@gmail.com" }
