import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef , SimpleChanges } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../services/base.service';
import { LoggingService } from '../services/log.service';
import * as moment from 'moment';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
        height: '0px'
      })),
      state('final', style({
        height: '*'
      }), { params: { expandedHeight: '{{expandedHeight}}' } }),
      transition('initial=>final', animate('.2s ease')),
      transition('final=>initial', animate('.1s ease'))
    ]),
  ]
})
export class AdvanceSearchComponent implements OnInit {
  profiles: any = [];
  chipFromKeyword = "";
  backdate: any;
  chipsFromSelect = [];
  chipsFromCheckbox = [];
  searchedSaleFields: any = {};
  hasSearched: any;
  selectedDateRange: any ;
  @Input('expandedHeight') expandedHeight: any;
  @Input('title') title: any;
  @Input('sortOptions') sortOptions: any;
  @Input('intervalOptions') intervalOptions: any;
  @Input('selectOptions') selectOptions: any;
  @Input('checkboxes') checkboxes: any;
  @Input('options') options: any;
  @Input('page') page: any;
  @Input('expiredOptions') expiredOptions : any;

  @Input('sortOptionsPlaceholder') sortDropdownsPlaceholder: any;
  @Input('intervalDropdownsPlaceholder') intervalDropdownsPlaceholder: any;
  @Input('selectOptionsPlaceholder') selectOptionsPlaceholder: any;

  @Output() searchEvent = new EventEmitter<any>();
  @Output() sortEvent = new EventEmitter<any>();
  @Output() intervalEvent = new EventEmitter<any>();
  @Output() changeAlert = new EventEmitter<any>();
  @Output() deletedChip = new EventEmitter<any>();
  @Output() exclusionEvent = new EventEmitter<any>();

  currentState = 'initial';
  searchWord: any;
  openedPanel: any;
  selectedOption: any = null
  drop = new FormControl(null);
  pageType: any;
  profili: any;
  idProfili: any;
  selectedDay: any;
  selectedUser: any;
  searchedRateFields: any;

  saleForm = this.fb.group({
    "nameRegistred": [''],
    "importfrom": [''],
    "importto": [''],
    "date": [''],
    "state": [null],
    "service": [''],
  });

  rateForm = this.fb.group({
    "keyword": [''],
    // "importfrom": [''],
    // "importto": [''],
    "date": [''],
    // "state": [null],
    // "service": [''],
  });

  statsRateForm = this.fb.group({
    "day": ['']
  });

  statsFeeForm = this.fb.group({
    "daterange_fee" : [''],
    "merchant" : ['all']
  })

  bankStatementForm = this.fb.group({
    "channel" : ['all'] 
  })

  status = [
    { id: "1", name: "new" },
    { id: "2", name: "active" },
    { id: "3", name: "blocked" },
    { id: "4", name: "closed" },
    { id: "5", name: "canceled" }
  ]
  merchantList: any;
  channelList = [
    { id: "1" , name: "STRIPE" }
  ]

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private basic: AppService,
    private logger: LoggingService,
    private changeDetector: ChangeDetectorRef , 
    private sharedService: SharedService) {
      if (this.showProfileList()) {
        this.getLevel();
      }
    }

  showProfileList() {
    if (!this.activeRoute("profile-rate") && !this.activeRoute("reseller") && !this.activeRoute("sales")) {
      return true;
    }
    else {
      return false;
    }
  }

  ngOnInit() {
    this.logger.log( 'this.page' , this.page , 200 )
    if( this.page == 'rate-analysis' ) {
      this.selectedDateRange = {start: moment(), end: moment().add(30, 'days') }
    } else if( this.page == 'stats-rate') {
      this.selectedDay = {start: moment() }
    } else {
      this.selectedDateRange = {start: moment().subtract(30, 'days'), end: moment() }
    }
    this.profili = localStorage.getItem("profile");
    this.idProfili = this.route.snapshot.params['id'];
    this.pageType = this.route.snapshot.params['pageType'];
    // console.log(this.route.snapshot.url,'this.route.snapshot.params')
    if (!this.options) {
      this.options = {};
    }
    this.openedPanel = false;
    if (this.intervalOptions) {
      for (var i = 0; i < this.intervalOptions.length; i++) {
        this.intervalOptions[i]['startDate'] = this.formatDateForServer(this.displayDate(this.intervalOptions[i].removedMonth))
        this.intervalOptions[i]['endDate'] = this.formatDateForServer(new Date());
      }
    }
    if (!this.expandedHeight)
      this.expandedHeight = 150;

    if( this.page == 'stats-fee' ) {
      this.getMerchantList()
    }
    // this.searchForSale( this.initDefaultRangeDates( 'obj' ) )
    // this.initDefaultRangeDates( 'obj' )
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnChanges() {
  }
    
  changeStateOfPanel() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  deleteChipFromKeyword() {
    this.chipFromKeyword = null;
    let chipsFromSelectOnlyNames = this.getOnlyNames(this.chipsFromSelect)
    this.searchEvent.emit({ "keyword": this.chipFromKeyword, "searchFromSelect": chipsFromSelectOnlyNames, "searchFromCheckbox": this.checkboxes })
  }

  deleteChipFromSelect(event) {
    this.chipsFromSelect = this.removeFromArray(this.chipsFromSelect, event);
    let chipsFromSelectOnlyNames = this.getOnlyNames(this.chipsFromSelect)
    this.searchEvent.emit({ "keyword": this.chipFromKeyword, "searchFromSelect": chipsFromSelectOnlyNames, "searchFromCheckbox": this.checkboxes })

  }

  deleteChipFromCheckbox(event) {1
    this.disableCheckbox(this.chipsFromCheckbox, event);
    this.chipsFromCheckbox = this.removeFromArray(this.chipsFromCheckbox, event);
  }

  disableCheckbox(array, chip) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == chip.id) {
        array[i].isChecked = false;
      }
    }
    return false
  }

  removeFromArray(array, element) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == element.id) {
        array.splice(i, 1);
        break
      }
    }
    return array
  }

  checkIfExistOnArrayByName(array, element) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].name == element.name) {
        return true
      }
    }
    return false
  }

  checkIfExistOnArrayById(array, element) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == element.id) {
        return true
      }
    }
    return false
  }

  togglePannel() {
    // this.selectedDateRange = null;

    this.changeStateOfPanel()
    var that = this;
    if (this.currentState == 'initial')
      this.openedPanel = !this.openedPanel
    else
      this.openedPanel = !this.openedPanel
    this.searchWord = "";
  }

  checkValue(event) {
    if (event.isChecked) {
      this.chipsFromCheckbox.push(event)
    }
    else {
      this.chipsFromCheckbox = this.removeFromArray(this.chipsFromCheckbox, event)
    }
  }

  getLevel() {
    this.basic.getAll('/user/profile')
      .subscribe((response) => {
        this.profiles = response.results;
        this.profiles.forEach(element => {
          element['name'] = element.description;
          element['type'] = 'word';
          element['typeOfSearch'] = 'level';
        });
      },
        (error) => {
          this.logger.log("Error", error, 200);
        }
      );
  }

  profile(event) {

    if (this.profili == 'MANAGER') {
      let selected = this.profiles[event - 3]
      if (selected) {
        let founded = this.checkIfExistOnArrayById(this.chipsFromSelect, selected)
        if (!founded)
          this.chipsFromSelect.push(selected)
        this.drop.reset()
      }
    }
    else if (this.profili == 'RESPONSIBLE') {
      let selected = this.profiles[event - 5]
      if (selected) {
        let founded = this.checkIfExistOnArrayById(this.chipsFromSelect, selected)
        if (!founded)
          this.chipsFromSelect.push(selected)
        this.drop.reset()
      }
    }
    else {
      let selected = this.profiles[event - 1]
      if (selected) {
        let founded = this.checkIfExistOnArrayById(this.chipsFromSelect, selected)
        if (!founded)
          this.chipsFromSelect.push(selected)
        this.drop.reset()
      }
    }
  }

  search() {
    this.openedPanel = false
    this.changeStateOfPanel();
    if (this.page == "new-sale") {
      this.searchForSale(this.saleForm.value)
    } else if ( this.page == "stats-rate" ) {
      this.options.day = this.selectedDay.start.format("YYYY-MM-DD")
      this.searchForRate()
    } else if(this.page == "rate-analysis") {
      // this.selectedDay = this.selectedDateRange
      // this.searchForRate()
      this.searchForSale( this.rateForm.value )
    } else if (this.page == "stats-fee") {
      this.searchForStatsFee( this.statsFeeForm.value )
    } else if ( this.page == "bank-statement" ) {
      this.searchForChannel( this.bankStatementForm.value )
    } else {
      if (this.searchWord.length != 0) {
        this.chipFromKeyword = this.searchWord;
      }
      this.searchWord = "";
      let chipsFromSelectOnlyNames = this.getOnlyNames(this.chipsFromSelect)
      this.searchEvent.emit({ "keyword": this.chipFromKeyword, "searchFromSelect": chipsFromSelectOnlyNames, "searchFromCheckbox": this.checkboxes })
    }
  }

  manageExpandedPanel(values) {
    /* console.log( 'manageExpandedPanel' , this.selectedDateRange , values ) */
    // console.log( 'manageExpandedPanel this.page' , this.page )
    if( this.page == "rate-analysis" ) {
      if ( (this.selectedDateRange && this.selectedDateRange.start) || values.nameRegistred.length != 0 || values.keyword.length != 0 ) {
        this.hasSearched = true
      }
      else {
        this.hasSearched = false
      }
    } else {
      if ((this.selectedDateRange && this.selectedDateRange.start) || values.importfrom.length != 0 || values.importto.length != 0 || values.nameRegistred.length != 0 || values.keyword.length != 0 || values.service.length != 0 || values.state) {
        this.hasSearched = true
      }
      else {
        this.hasSearched = false
      }
    }
    
  }

  searchForSale(values) {
    console.log('searchForSale - value', values)
    this.manageExpandedPanel(values)
    if (values.importfrom && values.importto)
      this.searchedSaleFields.import = values.importfrom + " - " + values.importto;
    else if (values.importfrom && !values.importto) {
      this.searchedSaleFields.import = values.importfrom + " - ∞"
    }
    else if (!values.importfrom && values.importto) {
      this.searchedSaleFields.import = "∞ - " + values.importto;
    }

    if( values.nameRegistred ) {
      this.searchedSaleFields.nameRegistred = values.nameRegistred;
    }

    if( values.keyword ) {
      this.searchedSaleFields.keyword = values.keyword;
    }
    console.log('searchForSale - this.searchedSaleFields', this.searchedSaleFields)
    
    this.searchedSaleFields.service = values.service;
    if (values.state) {
      this.clearStatus();
    }
    this.searchedSaleFields.state = values.state;
    if (this.selectedDateRange)
      if (this.selectedDateRange.start){
        this.searchedSaleFields.date = this.selectedDateRange.start.format("YYYY-MM-DD") + "/" + this.selectedDateRange.end.format("YYYY-MM-DD");
        this.options.dates = null
      }    
    this.searchEvent.emit(this.prepareSaleSearch(this.searchedSaleFields))
  }

  searchForRate() {
    if (this.selectedDay) {
      if (this.selectedDay.start) {
        // this.searchedSaleFields.date = this.selectedDay.start.format("YYYY-MM-DD") + "/" + this.selectedDay.end.format("YYYY-MM-DD");
        this.options.day = this.selectedDay.start.format("YYYY-MM-DD") + "/" + this.selectedDay.end.format("YYYY-MM-DD");
      }
    }
    this.searchEvent.emit(this.prepareRateSearch(this.options))
  }

  searchForStatsFee( values ) {
    if (this.selectedDateRange) {
      if (this.selectedDateRange.start){
        this.searchedSaleFields.date = this.selectedDateRange.start.format("YYYY-MM-DD") + "/" + this.selectedDateRange.end.format("YYYY-MM-DD");
        this.options.dates = null
      }
    }
    this.searchEvent.emit(this.prepareFeeSearch(values))
  }

  prepareFeeSearch(object) {
    let preparedObject : any = {}
    if(this.searchedSaleFields.date) {
      preparedObject.startDate = this.selectedDateRange.start.format("YYYY-MM-DD")
      preparedObject.endDate = this.selectedDateRange.end.format("YYYY-MM-DD")
    }
    if(object.merchant != 'all') {
      preparedObject.idMerchant = object.merchant
      let _merchant = this.merchantList.find( x => x.idMerchant === object.merchant )
      this.searchedSaleFields.nameRegistred = _merchant.companyName
    }
    return preparedObject
  }

  prepareRateSearch(object) {
    let preparedObject: any = {};
    if (object.day && object.day.length > 0) {
      preparedObject.startDate = object.day.split("/")[0];
      preparedObject.endDate = object.day.split("/")[1];
    }
    return preparedObject;
  }

  prepareSaleSearch(object) {
    let preparedObject: any = {};
    if (object.date && object.date.length > 0) {
      preparedObject.startDate = object.date.split("/")[0];
      preparedObject.endDate = object.date.split("/")[1];
    } // Aggiungo else dove controllo se è stringa e splitto per start date e end date
    if (object.import && object.import.length > 0) {
      preparedObject.importfrom = object.import.split(" - ")[0];
      preparedObject.importto = object.import.split(" - ")[1];
    }
    if (object.state && object.state.length > 0)
      preparedObject.status = object.state;
    if (object.nameRegistred && object.nameRegistred.length > 0)
      preparedObject.nameRegistred = object.nameRegistred;
    if (object.keyword && object.keyword.length > 0)
      preparedObject.keyword = object.keyword;
    if (object.service && object.service.length > 0)
      preparedObject.service = object.service;
    return preparedObject;
  }

  getOnlyNames(array) {
    let arrayWithNames = []
    array.forEach(element => {
      arrayWithNames.push(element.name)
    });
    return arrayWithNames
  }

  sort(event) {
    this.sortEvent.emit(event.target.value)
  }

  interval(event) {
    this.intervalEvent.emit(event.target.value)
  }

  displayDate(removedMonth) {
    if (removedMonth >= 1) {
      var today = new Date()
      var month, year, date;
      year = today.getFullYear()
      month = today.getMonth()
      date = today.getDate()
      return new Date(year, month - removedMonth, date)
    }
    else {
      var week = new Date();
      week.setDate(week.getDate() - 7);
      return week
    }
  }

  formatDateForServer(date) {
    date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return date;
  }

  clearUser() {
    this.options.userSelected = null
    delete this.options.userSelected
    localStorage.removeItem("selectedUser")
    this.changeAlert.emit()
    this.chipDelete()
  }

  clearStatus() {
    this.options.status = null
    delete this.options.status
    localStorage.removeItem("saleStatus")
    this.changeAlert.emit()
  }

  clearDates() {
    // this.selectedDateRange = null
    this.options.dates = null
    delete this.options.dates
    localStorage.removeItem("saleDate")
    this.changeAlert.emit()
  }

  clearDay() {
    this.options.day = null
    delete this.options.day
    // localStorage.removeItem("saleDate")
    this.changeAlert.emit()
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    //   return JSON.stringify(obj) === JSON.stringify({})
    return true;
  }

  choosedDate(event) {
  }

  clearFormControlName(form, name) {
    this.searchedSaleFields[name] = null
    if (name == 'import') {
      form.get("importfrom").setValue('');
      form.get("importto").setValue('');
    } else if (name == 'state') {
      form.get(name).setValue(null);
    } else {
      form.get(name).setValue('');
    }
    this.searchForSale(this.rateForm.value)
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  chipDelete() {
    this.deletedChip.emit();
  }

  hasFilterMerchant() {
    // console.log( 'hasFilterMerchant' )
    let placeholderUser = JSON.parse(localStorage.getItem("selectedUser"))
    if (placeholderUser != null && this.page != 'reseller' && this.page != 'seller' && this.page != 'rateOfDay') {
      this.options.userSelected = placeholderUser;
      return true;
    }
    else {
      return false;
    }

  }

  checkSelectedUser() {
    let placeholderUser = JSON.parse(localStorage.getItem("selectedUser"))
    if (placeholderUser != null && this.page != 'reseller' && this.page != 'seller' && this.page != 'rateOfDay') {
      this.options.userSelected = placeholderUser
      return true;
    } else {
      this.options.userSelected = null
      delete this.options.userSelected
      return false
    }
  }

  checkSelectedDay() {
    if( this.options.day ) {
      // console.log('checkSelectedDay' , this.options.day);
      return true;
    } else {
      return false;
    }
  }

  initDefaultRangeDates( return_type : string = null ) {
    localStorage.setItem("saleDate" , "1")
    var date = new Date();
    let today = this.sharedService.formatDateForServer(date);
    date.setDate(date.getDate() - 30);
    let beforeDate = this.sharedService.formatDateForServer(date);
    let queryParamsDate = {}
    queryParamsDate = "startDate=" + beforeDate + "&endDate=" + today;
    if ( return_type = null ){
      queryParamsDate = "startDate=" + beforeDate + "&endDate=" + today;
    } else if ( return_type == "obj" ) {
      queryParamsDate = { date : { startDate : beforeDate , endDate : today } }
      this.selectedDateRange = {start: moment(), end: moment().add(30, 'days') }
    } else if ( return_type == "chip" ) {
      queryParamsDate = beforeDate + "/" + today
    }
    return queryParamsDate;
  }

  getMerchantList() {
    this.basic.getAll('/merchant/getAll').subscribe((response) => {
      if( response['results'] ) {
        this.merchantList = response.results
      }
    })
  }

  exclusionToggle( event ) {
    this.exclusionEvent.emit( event )
  }

  searchForChannel( object ) {
    this.searchedSaleFields.channel = object.channel
    console.log( 'this.searchedSaleFields' , this.searchedSaleFields )
    this.hasSearched = true
    this.searchEvent.emit( object )
  }

  deleteChipChannel() {
    delete this.searchedSaleFields.channel
    console.log( this.searchedSaleFields )
    this.hasSearched = false // Questo vale solo nel caso di una ricerca basata su un campo solo 
    this.searchEvent.emit({channel : 'all'})
  }

}
