import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'sp-input-datepicker-bootstrap',
  templateUrl: './input-datepicker-bootstrap.component.html',
  styleUrls: ['./input-datepicker-bootstrap.component.scss']
})
export class InputDatepickerBootstrapComponent implements OnInit, AfterViewInit {

  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() locale: any = {dateInputFormat: 'DD/MM/YYYY'};
  @Input() showClearButton: boolean = false;
  @Input() minMode: BsDatepickerViewMode = 'day';
  @Input() singleDatePicker: boolean = true;
  @Input() placement: string = 'bottom';

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false
  }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.locale.dateInputFormat == 'DD/MM/YYYY') {
      switch (this.minMode) {
        case 'year':
          this.locale.dateInputFormat = 'YYYY';
          break;
        case 'month':
          this.locale.dateInputFormat = 'MM/YYYY';
          break;
        case 'day':
          this.locale.dateInputFormat = 'DD/MM/YYYY';
          break;
      }
    }
    if (!this.singleDatePicker) {
      this.locale.rangeInputFormat = this.locale.dateInputFormat;
    }
    this.bsConfig = {
      ...this.bsConfig,
      ...this.locale,
      minMode: this.minMode
    }
  }

  clear() {
    this.control.patchValue(null);
  }

}
