import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { AppService } from '../../../core/services/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-merchant-form',
  templateUrl: './merchant-form.component.html',
  styleUrls: ['./merchant-form.component.scss']
})

export class MerchantFormComponent implements OnInit {
  @Input() userData: any;
  @Input() rates: any;
  @Input() resellers: any;
  countryList: any = [];
  vatList: any = [];
  vatStandard = null;
  flagPath: string;
  @Input() set sellerData(sellerData : any){ 
    if ( sellerData ) {
      
      this.profileForm.setValue({

        companyName: sellerData.companyName ? sellerData.companyName : "",

        // taxCode: sellerData.taxCode ? sellerData.taxCode : "",
        // bankAlias: sellerData.bankAlias ? sellerData.bankAlias : "",
        vatNumber: sellerData.vatNumber ? sellerData.vatNumber : "",
        sdi: sellerData.sdi ? sellerData.sdi : "",
        resellerId: sellerData.resellerId ? sellerData.resellerId : null,
        pecAddress: sellerData.pecAddress ? sellerData.pecAddress : "",
        emailCommunication: sellerData.emailCommunication ? sellerData.emailCommunication : null,
        currency: sellerData.currency ? sellerData.currency : null,
        fixedFee: sellerData.fixedFee || sellerData.fixedFee == 0 ? sellerData.fixedFee : null,
        percentageFee: sellerData.percentageFee || sellerData.percentageFee == 0 ? sellerData.percentageFee : null,
        fixedFeeSmall: sellerData.fixedFeeSmall || sellerData.fixedFeeSmall == 0 ? sellerData.fixedFeeSmall : null,
        percentageFeeSmall: sellerData.percentageFeeSmall || sellerData.percentageFeeSmall == 0 ? sellerData.percentageFeeSmall : null,
        feeSmallMaxAmount: sellerData.feeSmallMaxAmount || sellerData.feeSmallMaxAmount == 0 ? sellerData.feeSmallMaxAmount : null,
        demo: sellerData.demo ? sellerData.demo : false,
        phoneNumber: sellerData.phoneNumber ? sellerData.phoneNumber : "",
        paymentDay: sellerData.paymentDay ? sellerData.paymentDay : null,
        acceptPrepaid: sellerData.acceptPrepaid ? sellerData.acceptPrepaid : false,
        amex: sellerData.amex ? sellerData.amex : false,
        sepa: sellerData.sepa ? sellerData.sepa : false,
        paypal: sellerData.paypal ? sellerData.paypal : false,
        klarna: sellerData.klarna ? sellerData.klarna : false,
        linkFirstPayment : sellerData.linkFirstPayment ? sellerData.linkFirstPayment : false,
        softwareHouse: sellerData.softwareHouse ? sellerData.softwareHouse : false,
        syncFA: sellerData.syncFA ? sellerData.syncFA : false,
        payoutType: sellerData.payoutType ? sellerData.payoutType : null,
        transferDays: sellerData.transferDays ? sellerData.transferDays : 1,
        transferFrequency: sellerData.transferFrequency ? sellerData.transferFrequency : null,
        transferFee: sellerData.transferFee >= 0 ? sellerData.transferFee : null,
        transferBalanceMinAmount: sellerData.transferBalanceMinAmount >= 0 ? sellerData.transferBalanceMinAmount : null,
        country: sellerData.country ? sellerData.country : null,
        idVat: sellerData.idVat ? sellerData.idVat : null,
        saiotSenderCode: sellerData.saiotSenderCode || null,
        state: sellerData.state || null,
        manageRefund: sellerData.manageRefund || false,
        payoutMaxRows: sellerData.payoutMaxRows || null
      })
      this.payoutType = sellerData.payoutType;
      this.transferFreq = sellerData.transferFrequency;

      if (sellerData.country) {
        this.getAllVatsOfCountry(sellerData.country);
      } else {
        this.resetVats();
      }
    }

  };

  typeOfUser:any;
  idUser: any;
  email: any;

  idMerchant: any;
  profile: any;
  selectedResellerId: any;
  payoutType;
  transferFreq;

  profileForm = this.formBuilder.group({
    //idProfile: ['3'],
    companyName: ['', Validators.required],

    vatNumber: ['', Validators.required],
    sdi: [''],
    resellerId: [null, Validators.required],
    pecAddress: ['', Validators.required],
    emailCommunication: [null],
    // taxCode: [''],
    // bankAlias: [''],
    currency: [null, Validators.required],
    fixedFee: [null, Validators.required],
    percentageFee: [null, Validators.required],
    fixedFeeSmall: [null, Validators.required],
    percentageFeeSmall: [null, Validators.required],
    feeSmallMaxAmount: [null],
    demo: [false],
    acceptPrepaid: [false],
    amex: [false],
    sepa: [false],
    paypal: [false],
    klarna: [false],
    linkFirstPayment: [false],
    softwareHouse: [false],
    syncFA: [false],
    phoneNumber: [''],
    paymentDay: [null, Validators.required],
    payoutType: [null, Validators.required],
    transferFrequency: [null, Validators.required],
    transferDays: [1],
    transferFee: [null , Validators.required],
    transferBalanceMinAmount: [null , Validators.required],
    country: [null, Validators.required],
    idVat: [null],
    saiotSenderCode: [null],
    state: [null , Validators.required],
    manageRefund: [false],
    payoutMaxRows: [null]
  });

  statuses = [
    {id: 1, name: 'ACTIVE'},
    {id: 2, name: 'INACTIVE'},
    {id: 3, name: 'BLOCKED'}
  ]

  prepaid=[
    {string:'Accetta' , val:1},
    {string:'NON accetta' , val:0}
  ]

  days=[
    {number:1},
    {number:2},
    {number:3},
    {number:4},
    {number:5},
    {number:6},
    {number:7},
    {number:8},
    {number:9},
    {number:10},
    {number:11},
    {number:12},
    {number:13},
    {number:14},
    {number:15},
    {number:16},
    {number:17},
    {number:18},
    {number:19},
    {number:20},
    {number:21},
    {number:22},
    {number:23},
    {number:24},
    {number:25},
    {number:26},
    {number:27},
    {number:28}
  ]

  daysForWeek= [
    {number:1 , description: 'Sunday'},
    {number:2 , description: 'Monday'},
    {number:3 , description: 'Tuesday'},
    {number:4 , description: 'Wednesday'},
    {number:5 , description: 'Thursday'},
    {number:6 , description: 'Friday'},
    {number:7 , description: 'Saturday'}
  ]

  payoutTypes = [
    {"id": 1 , "name":"CUMULATIVE"},
    {"id": 2 , "name":"PROGRESSIVE"}
  ]

  transferFrequencies = [
    {"id": 1 , "name":"DAILY"},
    {"id": 2 , "name":"WEEKLY"},
    {"id": 3 , "name":"MONTHLY"},
    {"id": 4 , "name":"MANUAL"}
  ]

  currencies = [
    {
      "id": 1, "name": "EUR", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "USD", "type": "word", "isChecked": false
    }]

  dum = [
    {
      "id": 1, "name": "First", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "Second", "type": "word", "isChecked": false
    }, {
      "id": 3, "name": "Third", "type": "date", "isChecked": false
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    public settings: SettingsService,
    private translate: TranslateService,
    private routerExtService: RouterExtService) {}

  ngOnInit() {
    this.typeOfUser = this.route.snapshot.params['usertype']
    this.profile = localStorage.getItem("profile");
    console.log("PROFILO",this.profile);
    this.idUser = this.route.snapshot.params['id'];

    this.getJSONConfiguration()
    this.getAssetsFlagPath()
    console.log( 'this.profileForm' , this.profileForm )
    if (this.profile != 'SUPERADMIN') {
      this.profileForm.get('resellerId').setValue(null)
      this.profileForm.controls['resellerId'].setValidators([])
      this.profileForm.controls['resellerId'].updateValueAndValidity()
    }
    if (this.idUser) {
      // console.log(' if (this.idUser) ' + this.sellerData.companyName)
      // this.idMerchant = this.userData.idMerchant
      /*this.profileForm.setValue({
        //idProfile: '3',
        companyName: this.sellerData.companyName ? this.sellerData.companyName : "ciccio sample",
        //telephone: this.userData.telephone ? this.userData.telephone : "",
        //email: this.userData.email ? this.userData.email : "",
        //name: this.userData.name ? this.userData.name : "",
        //surname: this.userData.surname ? this.userData.surname : "",
        /*taxCode: this.userData.fiscalCode ? this.userData.taxCode : "",*/
        /*bankAlias: this.userData.bankAlias ? this.userData.bankAlias : "",
        vatNumber: this.userData.vatNumber ? this.userData.vatNumber : "",
        //instalmentPlan: this.userData.instalmentPlanList ? this.sharedService.getOnlyAFieldFromArray('id', this.userData.instalmentPlanList) : [],
        resellerId: this.userData.resellerId ? this.userData.resellerId : null,
        pecAddress: this.userData.pecAddress ? this.userData.pecAddress : "",
        currency: this.userData.currency ? this.userData.currency : null,
        fixedFee: this.userData.fixedFee ? this.userData.fixedFee : null,
        percentageFee: this.userData.percentageFee ? this.userData.percentageFee : null,
        demo: this.userData.demo ? this.userData.demo : "",
        phoneNumber: this.userData.phoneNumber ? this.userData.phoneNumber : "",
        paymentDay: this.userData.paymentDay ? this.userData.paymentDay : null
      })*/
    }
  }

  getAllVatsOfCountry(country = null) {
    let path = "/vat/vats"
    if (country) {
      path += "?" + "country=" + country
    }
    this.appService.getAll(path).subscribe((res:any)=>{
      this.vatList = res.results.filter(result => !result.favorite);
      this.vatStandard = res.results.find(result => result.favorite);
    })
  }

  resetVats() {
    this.vatList = [];
    this.vatStandard = null;
    this.profileForm.patchValue({ idVat: null });
  }

  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.valid) {
      if(this.selectedResellerId) {
        this.profileForm.value.resellerId = this.selectedResellerId;
      }
      this.profileForm.value.demo = false;
      this.save(this.profileForm.value)
    }
  }

  save(manager) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.idUser) {
      console.log('sono in servizio creazione merchant')
      this.appService.newElement('/merchant', manager).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('seller.swal_user_created_ok'), "" , "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
    else {
      console.log('sono in servizio modifica merchant')
      manager['idMerchant'] = this.idUser
      console.log('manager' , manager )
      this.appService.editElement('/merchant', manager).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome && response.outcome.success === true) {
          swal(this.translate.instant('seller.swal_user_update_ok'), "" , "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
  }

  onChangeReseller(reseller) {
    this.selectedResellerId = reseller
  }

  onChangeCountry(country) {
    if (country) {
      this.getAllVatsOfCountry(country.code)
      this.profileForm.patchValue({ idVat: null })
    } else {
      this.resetVats();
    }
  }

  onPayoutTypeSelected(event) {
    this.payoutType = event
    this.profileForm.get('transferFrequency').setValue('DAILY')
    this.transferFreq = 'DAILY'
  }

  onFreqSelected(event) {
    this.transferFreq = event
  }

  getJSONConfiguration() {
    let fileConfigurations = require('./../../../../assets/data/country-list.json');
    this.countryList = fileConfigurations;
  }

  getAssetsFlagPath() {
    let flagPath = './../../../../assets/img/country/'
    this.flagPath = flagPath;
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/seller');
    } else {
      this.location.back();
    }
  }
}
