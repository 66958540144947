import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppService } from '../../../core/services/base.service';
import { BonificiService } from '../../../services/bonifici.service';
import { ResellerService } from '../../../services/reseller.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';
import { Subscription } from 'rxjs';
import { BsModalService , BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoggingService } from '../../../core/services/log.service';
import { SettingsService } from '../../../core/settings/settings.service';

moment.locale('it', localization);

@Component({
  selector: 'app-bonifici-reseller',
  templateUrl: './bonifici-reseller.component.html',
  styleUrls: ['./bonifici-reseller.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BonificiResellerComponent implements OnInit, OnDestroy {

  rows: any[] = [];
  // allRows: any[] = [];
  totalRows: number = 0;
  resellerList: any = [];
  profile: any;
  @ViewChild('dataTable') table: any;
  @ViewChild('newStatementModal') modalTemplate: TemplateRef<any>;

  pathUpdateFee = '/reseller-statement/fee';
  pathUpdateDetailFee = '/reseller-statement/[id]/detail/fee';
  pathMakeStatement = '/reseller-statement/make';

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'DD/MM/YYYY',
    rangeInputFormat: 'DD/MM/YYYY'
  }
  dateRangeMaxDate: any = null;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  newStatementForm = this.fb.group({
    idReseller: [null, Validators.required],
    feeFrequency: [{value: null, disabled: true}],
    dateRange: [{value: null, disabled: true}, Validators.required]
  });

  notifyNewStatement: Subscription;
  modalRef: BsModalRef;
  tableRecordNumbers: number;
  tablePage: number = 1;
  toast: any;

  searchForm: FormGroup;

  includeUndone = true;

  constructor( private translate: TranslateService,
    private appService: AppService,
    private bonificiService: BonificiService,
    private resellerService: ResellerService,
    private modalService: BsModalService,
    private fb: FormBuilder ,
    private logger: LoggingService,
    private formBuilder: FormBuilder,
    public settings: SettingsService
  ) {
    this.profile = localStorage.getItem('profile');

    // Overrides default total/empty messages
    translate.get("datatable_message.lista_totali_righe").subscribe((res: string ) => this.my_messages.totalMessage = res );
    translate.get("datatable_message.lista_vuota_righe").subscribe((res: string ) => this.my_messages.emptyMessage = res);

    this.searchForm = this.formBuilder.group({
      idReseller: [null],
      balanceDate: [null],
      reseller: [null],
      status: [null],
      paymentStatus: [null]
    });

    this.toast = swal.mixin({
      toast: true,
      position: "top-end",
      timer: 3500,
      showConfirmButton: false,
    });
  }

  ngOnInit() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);
    this.loadElements();
    if (localStorage.getItem('profile') === "SUPERADMIN") {
      this.loadResellers();
    }
    this.dateRangeMaxDate = moment().subtract(1, 'day').toDate();
    this.notifyNewStatement = this.bonificiService.newStatementCaller.subscribe((value: any) => {
      this.logger.log( 'ho ricevuto la chiamata dal bottone in un altro component' , '' , 200 );
      this.openModalNewStatement( this.modalTemplate );
    });
  }

  loadElements(queryParams: string = null, showSwal: boolean = false) {
    if (showSwal) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    }

    if (!queryParams) {
      queryParams = '';
    } else {
      queryParams += '&';
    }
    
    if (!this.includeUndone) {
      queryParams += 'excludeStatus=CANCELED&';
    }

    queryParams += 'paging=true&page=' + this.tablePage + '&rows=' + this.tableRecordNumbers;

    this.bonificiService.getAllResellerStatements(queryParams).subscribe((res: any) => {
      this.rows = res.results;
      this.totalRows = res.total;
      // this.allRows = this.rows;
      // this.onFlagExclusionChange(); /** Checks whether to display undone documents or not */
      swal.close();
    });
  }

  loadResellers() {
    this.resellerService.getAllResellers('onlyWithCompanyName=true').subscribe((res: any) => {
      this.resellerList = res.results;
    });
  }

  onPage(event) {
    if (this.tablePage != event.offset + 1) {
      this.tablePage = event.offset + 1;
      this.loadElements(this.generateFiltersPath(), true);
    }
  }
  
  toggleEditable(row, field) {
    if (!row[field + 'Edit'] && row[field + 'Edit'] != 0) {
      row[field + 'Edit'] = row[field];
    } else {
      delete row[field + 'Edit'];
    }
  }

  saveEditable(row, value, field) {
    row[field] = value;
    const body = {
      id: row.id,
      fixedFee: row.fixedFee,
      percentageFee: row.percentageFee,
      percentageVolumeFee: row.percentageVolumeFee,
      fixedTransactionFee: row.fixedTransactionFee
    };
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    this.updateFees( this.pathUpdateFee, body );
  }

  updateFees( path, body ) {
    this.bonificiService.updateFees( path, body ).subscribe((resp) => {
      this.logger.log( 'updateFees resp => ' , resp , 200 );
      if ( resp.outcome && resp.outcome.success ) {
        this.toast({
          position: 'bottom-end',
          title: this.translate.instant('swal.title_Success'),
          text: this.translate.instant('bonifici.swal_title_fee-confirmed-success'),
          type: 'success'
        });
        this.loadElements();
      } else {
        swal({
          type: 'error',
          title: this.translate.instant('swal.title_Error'),
          html: this.translate.instant('bonifici.swal_title_fee-confirmed-error') + '<br>' + resp.message
        });
      }
    });
  }
  
  async toggleExpandRow( row , event ) {
    if (!event) {
      if ( !row.details ) {
        await this.loadElementDetails(row);
      }
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  async loadElementDetails(row) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    // Using async-await code shall look something like this
    // https://medium.com/@balramchavan/using-async-await-feature-in-angular-587dd56fdc77
    const _resp = await this.bonificiService.getAllResellerStatementDetails(row.id, null).toPromise();
    row.details = _resp.results;
    swal.close();
    return _resp.results;
  }

  canEdit() {
    return localStorage.getItem("profile") == "SUPERADMIN";
  }
  
  toggleEditableDetail(row, rowDetail, field) {
    if (!rowDetail[field + 'Edit'] && rowDetail[field + 'Edit'] != 0) {
      rowDetail[field + 'Edit'] = rowDetail[field];
    } else {
      delete rowDetail[field + 'Edit'];
    }
  }

  saveEditableDetail(row, rowDetail, value, field) {
    rowDetail[field] = value;
    const body = {
      id: rowDetail.id,
      fixedFee: rowDetail.fixedFee,
      percentageFee: rowDetail.percentageFee,
      percentageVolumeFee: rowDetail.percentageVolumeFee,
      fixedTransactionFee: rowDetail.fixedTransactionFee
    };
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    this.updateDetailFees( this.pathUpdateDetailFee.replace('[id]', row.id), body );
  }

  updateDetailFees( path, body ) {
    this.bonificiService.updateFees( path, body ).subscribe((resp) => {
      this.logger.log( 'updateFees resp => ' , resp , 200 );
      if ( resp.outcome && resp.outcome.success ) {
        this.toast({
          position: 'bottom-end',
          title: this.translate.instant('swal.title_Success'),
          text: this.translate.instant('bonifici.swal_title_fee-confirmed-success'),
          type: 'success'
        });
        this.loadElements();
      } else {
        swal({
          type: 'error',
          title: this.translate.instant('swal.title_Error'),
          html: this.translate.instant('bonifici.swal_title_fee-confirmed-error') + '<br>' + resp.message
        });
      }
    });
  }

  getDetailPeriod(rowDetail) {
    const date = moment();
    let dateFormat = 'DD MMM YYYY';
    if (rowDetail.refYear) {
      date.set('year', rowDetail.refYear);
      dateFormat = 'YYYY';
    }
    if (rowDetail.refMonth) {
      date.set('month', rowDetail.refMonth - 1);
      dateFormat = 'MMM YYYY';
    }
    return date.startOf('month').format(dateFormat);
  }

  invoiceStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-invoice'),
      html : this.translate.instant('bonifici.swal_text_statement-invoice'),
      type : 'warning',
      showCancelButton : true,
    }).then((value) => {
      if ( value.value === true ) {
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.appService.editElement( '/reseller-statement/' + row.id + '/payment-status/CONFIRMED' , null ).subscribe((resp) => {
          if ( resp.outcome && resp.outcome.success ) {
            swal({
              title: this.translate.instant('bonifici.swal_title_statement-make-success'),
              html: this.translate.instant('bonifici.swal_text_statement-invoice-success'),
              type: 'success'
            }).then(_ => {
              this.rows = this.updateRowUi( row , 'paymentStatus' , 'CONFIRMED' );
            });
          } else {
            swal({
              title: this.translate.instant('bonifici.swal_title_statement-make-error'),
              html: this.translate.instant('bonifici.swal_text_statement-invoice-error'),
              type: 'error'
            });
          }
        });
      } else {
        swal.noop();
      }
    });
  }

  openModalNewStatement( template: TemplateRef<any> ) {
    this.newStatementForm.reset();
    this.modalRef = this.modalService.show(template , { class: 'modal-lg' } );
  }

  makeStatement() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let dateToLastFrequency: moment.unitOfTime.StartOf = 'day';
    switch (this.newStatementForm.controls.feeFrequency.value) {
      case 'YEARLY':
        dateToLastFrequency = 'year';
        break;
      case 'MONTHLY':
        dateToLastFrequency = 'month';
        break;
      default:
        break;
    }
    let body = {
      idReseller: this.newStatementForm.controls.idReseller.value,
      dateFrom: moment(this.newStatementForm.controls.dateRange.value[0]).startOf(dateToLastFrequency).format("YYYY-MM-DD"),
      dateTo: moment(this.newStatementForm.controls.dateRange.value[1]).endOf(dateToLastFrequency).format("YYYY-MM-DD")
    };
    this.appService.newElement( this.pathMakeStatement , body ).subscribe(
      (resp) => {
        if ( resp.outcome && resp.outcome.success ) {
          swal({
            title: this.translate.instant('bonifici.swal_title_statement-make-success'),
            html: this.translate.instant('bonifici.swal_text_statement-make-success'),
            type: 'success'
          }).then(() => {
            this.modalRef.hide();
            this.loadElements();
          });
        } else {
          swal({
            title: this.translate.instant('bonifici.swal_title_statement-make-error'),
            html: this.translate.instant('bonifici.swal_text_statement-make-error') + '<br>' + this.settings.manageErrorMsg(resp.outcome),
            type: 'error'
          });
        }
      }
    );
  }

  confirmStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-confirm'),
      html : this.translate.instant('bonifici.swal_text_statement-confirm'),
      type : 'warning',
      showCancelButton : true,
    }).then((value) => {
      if ( value.value === true ) {
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.appService.editElement( '/reseller-statement/' + row.id + '/status/SAVED' , null ).subscribe((resp) => {
          if ( resp.outcome && resp.outcome.success ) {
            swal( this.translate.instant('bonifici.swal_title_statement-make-success') , this.translate.instant('bonifici.swal_text_statement-confirmed-success') , 'success' );
            this.rows = this.updateRowUi( row , 'status' , 'SAVED' );
          } else {
            swal( this.translate.instant('bonifici.swal_title_statement-make-error') , this.translate.instant('bonifici.swal_text_statement-confirmed-error') , 'error');
          }
        });
      } else {
        swal.noop();
      }
    });
  }

  sendStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-send'),
      html : this.translate.instant('bonifici.swal_text_statement-send'),
      type : 'warning',
      showCancelButton : true,
    }).then((value) => {
      if ( value.value === true ) {
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.appService.editElement( '/reseller-statement/' + row.id + '/status/CONFIRMED' , null ).subscribe((resp) => {
          if ( resp.outcome && resp.outcome.success ) {
            swal( this.translate.instant('bonifici.swal_title_statement-make-success') , this.translate.instant('bonifici.swal_text_statement-sent-success') , 'success' );
            this.rows = this.updateRowUi( row , 'status' , 'CONFIRMED' );
          } else {
            swal( this.translate.instant('bonifici.swal_title_statement-make-error') , this.translate.instant('bonifici.swal_text_statement-sent-error') , 'error');
          }
        });
      } else {
        swal.noop();
      }
    });
  }

  cancelStatement( row ) {
    swal({
      title : this.translate.instant('bonifici.swal_title_statement-cancel'),
      html : this.translate.instant('bonifici.swal_text_reseller_statement-cancel'),
      type : 'warning',
      showCancelButton : true,
    }).then((value) => {
      if ( value.value === true ) {
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.appService.editElement( '/reseller-statement/' + row.id + '/status/CANCELED' , null ).subscribe((resp) => {
          // console.log( 'response of CANCELED bank statement' , resp )
          if ( resp.outcome && resp.outcome.success ) {
            swal( this.translate.instant('bonifici.swal_title_statement-cancel-success') , this.translate.instant('bonifici.swal_text_statement-canceled-success') , 'success' );
            this.rows = this.updateRowUi( row , 'status' , 'CANCELED' );
          } else {
            swal( this.translate.instant('bonifici.swal_title_statement-cancel-error') , this.translate.instant('bonifici.swal_text_statement-canceled-error') , 'error');
          }
        });
      }
    });
  }

  updateRowUi( _row , _key , _value ) {
    const _i = this.rows.indexOf(_row);
    _row[_key] = _value;
    this.rows[_i] = _row;
    // console.log( '_row' , _row )
    // console.log( 'this.rows' , this.rows[_i] )
    return this.rows = [...this.rows];
  }

  onChangeReseller(idReseller) {
    if (!idReseller) {
      const bsConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: 'DD/MM/YYYY',
        rangeInputFormat: 'DD/MM/YYYY',
        minMode: 'day'
      };
      this.bsConfig = { ...this.bsConfig, ...bsConfig };
      this.dateRangeMaxDate = moment().subtract(1, 'day').toDate();
      this.newStatementForm.get('feeFrequency').setValue(null);
      this.newStatementForm.get('dateRange').disable();
    } else {
      const reseller = this.resellerList.find(reseller => reseller.idReseller == idReseller);
      switch (reseller.feeFrequency) {
        case 'YEARLY': {
          const bsConfig: Partial<BsDatepickerConfig> = {
            dateInputFormat: 'YYYY',
            rangeInputFormat: 'YYYY',
            minMode: 'year'
          };
          this.bsConfig = { ...this.bsConfig, ...bsConfig };
          this.dateRangeMaxDate = moment().subtract(1, 'year').toDate();
          this.newStatementForm.get('dateRange').enable();
          break;
        }
        case 'MONTHLY': {
          const bsConfig: Partial<BsDatepickerConfig> = {
            dateInputFormat: 'MM/YYYY',
            rangeInputFormat: 'MM/YYYY',
            minMode: 'month'
          };
          this.bsConfig = { ...this.bsConfig, ...bsConfig };
          this.dateRangeMaxDate = moment().subtract(1, 'month').toDate();
          this.newStatementForm.get('dateRange').enable();
          break;
        }
        default: {
          const bsConfig: Partial<BsDatepickerConfig> = {
            dateInputFormat: 'DD/MM/YYYY',
            rangeInputFormat: 'DD/MM/YYYY',
            minMode: 'day'
          };
          this.bsConfig = { ...this.bsConfig, ...bsConfig };
          this.dateRangeMaxDate = moment().subtract(1, 'day').toDate();
          this.newStatementForm.get('dateRange').disable();
          break;
        }
      }
      this.newStatementForm.get('feeFrequency').setValue(reseller.feeFrequency || null);
    }
    this.newStatementForm.get('dateRange').setValue(null);
  }

  ngOnDestroy() {
    this.notifyNewStatement.unsubscribe();
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      this.tablePage = 1;
      this.loadElements(this.generateFiltersPath(), true);
      if ( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset();
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  onUndoneChange(event: boolean) {
    this.includeUndone = event;
    // this.onFlagExclusionChange();
    this.prepareForSearch();
  }

  protected downloadStatement(rowId: number) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    this.bonificiService.downloadResellerStatement(rowId).subscribe(
      _ => {
        swal.close();
      }
    );
  }

  private generateFiltersPath(): string {
    const queryParamsSearch: string[] = [];
    for (const check in this.searchForm.controls) {
      if (this.searchForm.controls[check] && this.searchForm.controls[check].value) {
        if (this.searchForm.controls[check].value.hasOwnProperty('startDate') &&
          this.searchForm.controls[check].value.startDate && this.searchForm.controls[check].value.endDate) {
          queryParamsSearch.push('startDate' + '=' + moment(this.searchForm.controls[check].value.startDate).format('YYYY-MM-DD')
            + '&' + 'endDate' + '=' + moment(this.searchForm.controls[check].value.endDate).format('YYYY-MM-DD'));
        } else if (check.endsWith('Date')) {
          queryParamsSearch.push('startDate' + '=' + moment(this.searchForm.controls[check].value).format('YYYY-MM-DD')
            + '&' + 'endDate' + '=' + moment(this.searchForm.controls[check].value).format('YYYY-MM-DD'));
        } else {
          queryParamsSearch.push(`${check}=${this.searchForm.controls[check].value}`);
        }
      }
    }
    return queryParamsSearch.join('&');
  }

  /** Method that manages when an element must be prevented from being displayed */
  // private onFlagExclusionChange() {
  //   if (!this.includeUndone) {
  //     this.rows = this.excludeUndoneDocuments();
  //   } else {
  //     if (this.allRows) {
  //       this.rows = this.allRows;
  //     }
  //   }
  // }

  /** Management method for element exclusion from list (when status CANCELED) */
  // private excludeUndoneDocuments() {
  //   const result: any = [];
  //   this.allRows.forEach(element => {
  //     if (!this.includeUndone && !this.isUndone(element)) {
  //       result.push(element);
  //     }
  //   });
  //   return result;
  // }

  // private isUndone(element: any) {
  //   return element.status === 'CANCELED' ? true : false;
  // }

}
