import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISaleStatus } from '../../../models/sale';

@Component({
  selector: 'sp-input-sale-status-select',
  templateUrl: './input-sale-status-select.component.html',
  styleUrls: ['./input-sale-status-select.component.scss']
})
export class InputSaleStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<ISaleStatus>();

  statuses: ISaleStatus[];

  constructor() { }

  ngOnInit() {

    this.getStatuses();

  }

  getStatuses() {
    this.statuses = [
      {
        id: 1,
        code: 'new',
        description: 'sale.sale_status.new'
      },
      {
        id: 2,
        code: 'active',
        description: 'sale.sale_status.active'
      },
      {
        id: 3,
        code: 'blocked',
        description: 'sale.sale_status.blocked'
      },
      {
        id: 4,
        code: 'frozen',
        description: 'sale.sale_status.frozen'
      },
      {
        id: 5,
        code: 'closed',
        description: 'sale.sale_status.closed'
      },
      {
        id: 6,
        code: 'canceled',
        description: 'sale.sale_status.canceled'
      }
    ]
  }

  changed(ev: ISaleStatus) {
    this.itemSelected.emit(ev);
  }

}
