import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';


@Injectable({
    providedIn: 'root'
})

export class BonificiService {

  @Output() newStatementCaller = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService
  ) {}

  getAllPayouts(queryParams?: any) {
      const basePath = environment.restBaseUrl + "/payout/getAll";
      const path = queryParams ? `${basePath}?${queryParams}` : basePath;

      return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
          (response: HttpResponse<any>) => {
              const outcome = response['outcome'];
              const dataResponse = response['data'];
              // this.totalItems = dataResponse['total'];
              if (outcome.success === true) {
                  this.logger.log("Service:", "SUCCESS", 200);
                  return dataResponse;
              } else {
                  this.logger.log("Service:", "FAILURE", 200);
                  outcome.message = this.settings.manageErrorMsg(outcome);
                  return outcome;
              }
          }
      ), catchError((errorResponse: HttpErrorResponse) => {
          const res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
              this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
          }
      ));
  }

  getAllInstallmentsByPayoutId( id: number, queryParams?: any ) {
      const basePath = environment.restBaseUrl + "/payout/" + id + "/instalments";
      const path = queryParams ? `${basePath}?${queryParams}` : basePath;

      return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
          (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          // this.totalItems = dataResponse['total'];
          if (outcome.success === true) {
              this.logger.log("Service:", "SUCCESS", 200);
              return dataResponse;
          } else {
              this.logger.log("Service:", "FAILURE", 200);
              outcome.message = this.settings.manageErrorMsg(outcome);
              return outcome;
          }

          }
      ), catchError((errorResponse: HttpErrorResponse) => {
          const res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
              this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
          }
      ));
  }

  getAllBankStatements(queryParams?: any) {
    const basePath = environment.restBaseUrl + "/bank-statement/getAll";
    const path = queryParams ? `${basePath}?${queryParams}` : basePath;

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
            const outcome = response['outcome'];
            const dataResponse = response['data'];
            // this.totalItems = dataResponse['total'];
            if (outcome.success === true) {
                this.logger.log("Service:", "SUCCESS", 200);
                return dataResponse;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                outcome.message = this.settings.manageErrorMsg(outcome);
                return outcome;
            }
        }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
        }
    ));
  }

  downloadBankStatement(statementId: number) {
    const path = environment.restBaseUrl + "/payout/" + statementId + "/download";

    return this.http.get(path, this.getRequestOptionsDownload()).pipe(map(
      (response) => {
        const file = new Blob([response], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.logger.log("Service:", "SUCCESS", 200);
      },
    ), catchError((errorResponse: HttpErrorResponse) => {
          return throwError(errorResponse.error);
        }
    ));
  }

  getLastPayout( idMerchant = null ) {
    let path = environment.restBaseUrl + "/payout/last";
    if (idMerchant) {
      path = environment.restBaseUrl + "/payout/last/" + idMerchant;
    }

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
            const outcome = response['outcome'];
            const dataResponse = response['data'];
            // this.totalItems = dataResponse['total'];
            if (outcome.success === true) {
                this.logger.log("Service:", "SUCCESS", 200);
                return dataResponse;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                outcome.message = this.settings.manageErrorMsg(outcome);
                return outcome;
            }
        }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
        }
    ));
  }

  getAllResellerStatements(queryParams?: any) {
    const basePath = environment.restBaseUrl + "/reseller-statement/getAll";
    const path = queryParams ? `${basePath}?${queryParams}` : basePath;

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
            const outcome = response['outcome'];
            const dataResponse = response['data'];
            // this.totalItems = dataResponse['total'];
            if (outcome.success === true) {
                this.logger.log("Service:", "SUCCESS", 200);
                return dataResponse;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                outcome.message = this.settings.manageErrorMsg(outcome);
                return outcome;
            }
        }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
        }
    ));
  }

  getAllResellerStatementDetails(id, queryParams?: any) {
    const basePath = environment.restBaseUrl + "/reseller-statement/" + id + "/detail/getAll";
    const path = queryParams ? `${basePath}?${queryParams}` : basePath;

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
            const outcome = response['outcome'];
            const dataResponse = response['data'];
            // this.totalItems = dataResponse['total'];
            if (outcome.success === true) {
                this.logger.log("Service:", "SUCCESS", 200);
                return dataResponse;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                outcome.message = this.settings.manageErrorMsg(outcome);
                return outcome;
            }
        }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
        }
    ));
  }

  downloadResellerStatement(statementId: number) {
    const path = environment.restBaseUrl + "/reseller-statement/" + statementId + "/download";

    return this.http.get(path, this.getRequestOptionsDownload()).pipe(map(
      (response) => {
        const file = new Blob([response], {type: 'text/csv'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.logger.log("Service:", "SUCCESS", 200);
      },
    ), catchError((errorResponse: HttpErrorResponse) => {
          return throwError(errorResponse.error);
        }
    ));
  }

  getRequestOptionArgs(): any {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("token") || ''
        })
      };
      return httpOptions;
  }

  getRequestOptionsDownload(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':  localStorage.getItem("token") || '',
      }),
      responseType: 'blob'
    };
    return httpOptions;
  }

  makePayout( id: number ) {
      const path = environment.restBaseUrl + "/payout/make-payout";
      return this.http
          .post<any[]>( path , { "idMerchant" : id , "channel" : "STRIPE" } , this.getRequestOptionArgs() )
          .pipe(map(
              (response: HttpResponse<any>) => {
              const res: any = response;

              if (res.outcome.success === true) {
                  const dataResponse = res.data;
                  this.logger.log("Service:", "SUCCESS", 200);
                  return dataResponse;
              } else {
                  this.logger.log("Service:", "FAILURE", 200);
                  const outcome = res.outcome;
                  return outcome;
              }
              }
          ) ,
          catchError((error: HttpErrorResponse) => {
              this.logger.log("Service Fail. Error:", error, 200);
              return throwError(error);
          }
      ));
  }

  newBankStatementEmit() {
    this.newStatementCaller.emit();
  }

  // modify of an element
  updateCost(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  updateFees(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  draftPayout( channel , idMerchant ) {
    const path = environment.restBaseUrl + "/payout/draft-payout/?channel=" + channel + "&idMerchant=" + idMerchant;

      return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
          (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          // this.totalItems = dataResponse['total'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }

          }
      ), catchError((errorResponse: HttpErrorResponse) => {
          const res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
          }
      ));
  }

  payPayout( id: number, channel: string ) {
      const path = environment.restBaseUrl + "/payout/payment";
      return this.http
          .post<any[]>( path , { id , channel } , this.getRequestOptionArgs() )
          .pipe(map(
              (response: HttpResponse<any>) => {
              const res: any = response;

              if (res.outcome.success === true) {
                  const dataResponse = res.data;
                  this.logger.log("Service:", "SUCCESS", 200);
                  return dataResponse;
              } else {
                  this.logger.log("Service:", "FAILURE", 200);
                  const outcome = res.outcome;
                  return outcome;
              }
              }
          ) ,
          catchError((error: HttpErrorResponse) => {
              this.logger.log("Service Fail. Error:", error, 200);
              return throwError(error);
          }
      ));
  }

}
