import { Component, OnInit , ViewChild , ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { BonificiService } from '../../../services/bonifici.service';
import { SellerService } from '../../../services/seller.service';
import { ExcelService } from '../../../shared/excel.service';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';

moment.locale('it', localization);

@Component({
  selector: 'app-bonifici-status',
  templateUrl: './bonifici-status.component.html',
  styleUrls: ['./bonifici-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BonificiStatusComponent implements OnInit {

  rows: any[] = [];
  totalRows: number = 0;
  options: any = [];
  dayModel: any;
  date: any;
  selected: any;
  rowDetail: any = [];
  merchantList: any = [];
  tableRecordNumbers: number;
  tablePage: number = 1;
  tableDetailPage: number[] = [];
  actualCurrency: string;

  profile: any;
  drop = new FormControl(null);
  selectedForPayout: any = null ;

  @ViewChild('dataTable') table: any;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  swal_error : string;
  swal_success : string;
  swal_success_txt: string;
  swal_error_txt : string;

  searchForm: FormGroup;

  constructor(
    private excelService: ExcelService,
    private bonificiService: BonificiService,
    private sellerService: SellerService,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) {
    this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);
    // console.log( 'this.tableRecordNumbers' , this.tableRecordNumbers )
    this.profile = localStorage.getItem('profile');

    // Overrides default total/empty messages
    translate.get("datatable_message.lista_totali_righe").subscribe((res: string ) => this.my_messages.totalMessage = res );
    translate.get("datatable_message.lista_vuota_righe").subscribe((res: string ) => this.my_messages.emptyMessage = res);

    this.translate.get('bonifici.swal.title_Error').subscribe((res : string ) => this.swal_error = res )
    this.translate.get('bonifici.swal.title_Success').subscribe((res : string ) => this.swal_success = res )
    this.translate.get('bonifici.swal.content_Success').subscribe((res : string ) => this.swal_success_txt = res )
    this.translate.get('bonifici.swal.content_Error').subscribe((res : string ) => this.swal_error_txt = res )

    this.searchForm = this.formBuilder.group({
      idMerchant: [null],
      //creation: [null],
      creationDate: [null],
      //lastModified: [null],
      lastModifiedDate: [null],
      //collection: [null],
      collectionDate: [null],
      status: [null]
    });
  }

  ngOnInit() {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);
    this.tableDetailPage = Array.from(new Array(this.tableRecordNumbers), () => 1);

    if ( localStorage.getItem('profile') === "SUPERADMIN" || localStorage.getItem('profile') === "RESELLER" ) {
      this.sellerService.getAllSellers().subscribe((res: any) => {
        this.merchantList = res.results;
        this.getAllPayouts();
      });
    } else {
      this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((res: any) => {
        this.merchantList = res;
        this.getAllPayouts();
      });
    }

  }

  async toggleExpandRow(row, expanded) {
    if (!expanded && !row.transferDetail) {
      row = await this.getAllInstallmentsByPayoutId(row);
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  getAllPayouts(queryParams: string = null, showSwal: boolean = false) {
    if (showSwal) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    }

    if (!queryParams) {
      queryParams = '';
    } else {
      queryParams += '&';
    }

    queryParams += 'paging=true&page=' + this.tablePage + '&rows=' + this.tableRecordNumbers;

    this.bonificiService.getAllPayouts(queryParams).subscribe((res: any) => {
      this.rows = res.results;
      this.totalRows = res.total;
      if (this.rows) {
        if ( localStorage.getItem('profile') === "SUPERADMIN" || localStorage.getItem('profile') === "RESELLER" ) {
          this.rows.forEach(element => {
            element.merchantDetail = this.merchantList.find( x => x.idMerchant === element.idMerchant );
          });
        } else {
          this.rows.forEach(element => {
            element.merchantDetail = this.merchantList;
          });
        }
      }
      swal.close();
    });
  }

  onPage(event) {
    if (this.tablePage != event.offset + 1) {
      this.tablePage = event.offset + 1;
      this.tableDetailPage = Array.from(new Array(this.tableRecordNumbers), () => 1);
      this.getAllPayouts(this.generateFiltersPath(), true);
    }
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      this.tablePage = 1;
      this.tableDetailPage = Array.from(new Array(this.tableRecordNumbers), () => 1);
      this.getAllPayouts(this.generateFiltersPath(), true);
      if ( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset();
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  getRowIndex(row) {
    const rowIndex = this.rows.indexOf(row);
    return rowIndex;
  }

  async getAllInstallmentsByPayoutId(row, exporting = false) {
    const rowIndex = this.getRowIndex(row);
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let queryParams = '';
    if (!exporting) {
      queryParams = 'paging=true&page=' + this.tableDetailPage[rowIndex] + '&rows=' + this.tableRecordNumbers;
    }
    const res = await this.bonificiService.getAllInstallmentsByPayoutId( row.id, queryParams ).toPromise();
    let results = [];
    if (res) {
      if (!exporting) {
        row['transferDetail'] = res.results;
        row['transferDetailTotal'] = res.total;
        this.actualCurrency = row.merchantDetail.currency;
      } else {
        results = res.results;
      }
    }
    swal.close();
    if (!exporting) {
      return row;
    } else {
      return results;
    }
  }

  onDetailPage(event, row) {
    const rowIndex = this.getRowIndex(row);
    if (this.tableDetailPage[rowIndex] != event.offset + 1) {
      this.tableDetailPage[rowIndex] = event.offset + 1;
      this.getAllInstallmentsByPayoutId(row);
    }
  }

  async export(row) {
    const rows = await this.getAllInstallmentsByPayoutId(row, true);
    var data = [];
    var rateHeader = {
      saleName: 'Nome vendita',
      name: 'Cognome nome',
      netAmount: 'Totale bonifico',
      amount: 'Pagato dai clienti',
      percentageFeeAmount: 'Commissione %',
      fixedFeeAmount: 'Commissione fissa',
      feeAmount: 'Totale trattenute',
      paymentDate: 'Data pagamento',
      idPayment: 'Codice di pagamento',
      idPaymentInstalment: 'Codice di pagamento rata'
    }
    data.push(rateHeader)
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      var rate = {
        saleName: element.sale.service ? element.sale.service : 'Non disponibile',
        name: element.customer.fullName ? element.customer.fullName : element.customer.surname + ' ' + element.customer.name,
        netAmount: element.netAmount,
        amount: element.amount,
        percentageFeeAmount : element.percentageAmountVatInc,
        fixedFeeAmount: element.fixedFeeVatInc,
        feeAmount: element.feeAmount,
        paymentDate: element.paymentDate,
        idPayment: element.paymentId ? element.paymentId : '',
        idPaymentInstalment: element.instalmentPaymentId ? element.instalmentPaymentId : ''
      }
      data.push(rate)
    }
    this.excelService.export(data,'Bonifico'+ new Date().getTime())
  }

  makePayout() {
    if ( this.selectedForPayout != null ) {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      this.bonificiService.makePayout( this.selectedForPayout ).subscribe((res: any) => {
        if ( res.success === false ) {
          swal("Error" , res.message , "error");
        } else {
          swal("Succes" , "" , "success");
        }
      });
    }
  }

  selectChange(event: any) {
    if ( event != null ) {
      this.selectedForPayout = event.idMerchant;
    }
  }

  retryPayment(row) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    this.bonificiService.payPayout( row.id, row.channel ).subscribe((res: any) => {
      if ( res.success === false ) {
        swal(this.swal_error , this.swal_error_txt + res.message , "error")
      } else {
        if (res.status == 'CONFIRMED_ERROR') {
          swal(this.swal_error , this.swal_error_txt + res.reason , "error")
        } else {
          swal(this.swal_success , this.swal_success_txt , "success").then(() => {
            this.getAllPayouts(this.generateFiltersPath(), true);
          });
        }
      }
    });
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that manages filters API url, creating custom strings with data start & end values used as request paths */
  private generateFiltersPath(): string {
    const queryParamsSearch: string[] = [];
    for (const check in this.searchForm.controls) {
      if (this.searchForm.controls[check] && this.searchForm.controls[check].value) {
        if (this.searchForm.controls[check].value.hasOwnProperty('startDate') &&
          this.searchForm.controls[check].value.startDate && this.searchForm.controls[check].value.endDate) {
          queryParamsSearch.push('startDate' + check.charAt(0).toUpperCase() + check.slice(1)
            + '=' + moment(this.searchForm.controls[check].value.startDate).format('YYYY-MM-DD')
            + '&' + 'endDate' + check.charAt(0).toUpperCase() + check.slice(1)
            + '=' + moment(this.searchForm.controls[check].value.endDate).format('YYYY-MM-DD'));
        } else if (check.endsWith('Date')) {
          queryParamsSearch.push('startDate' + check.charAt(0).toUpperCase() + check.replace('Date', '').slice(1)
            + '=' + moment(this.searchForm.controls[check].value).format('YYYY-MM-DD')
            + '&' + 'endDate' + check.charAt(0).toUpperCase() + check.replace('Date', '').slice(1)
            + '=' + moment(this.searchForm.controls[check].value).format('YYYY-MM-DD'));
        } else {
          queryParamsSearch.push(`${check}=${this.searchForm.controls[check].value}`);
        }
      }
    }
    return queryParamsSearch.join('&');
  }

}
