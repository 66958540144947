import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  dropdowns = [{
    "id": 1, "name": "Hello", "type": "word", "isChecked": false
  }, {
    "id": 2, "name": "Noel", "type": "word", "isChecked": false
  }, {
    "id": 3, "name": "John", "type": "date", "isChecked": false
  }]
  constructor(private location: Location,
    private router: Router,
    private routerExtService: RouterExtService) { }

  ngOnInit() {
  }

  onSubmit(){

  }

  navigationBack(){
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/user');
    } else {
      this.location.back();
    }
  }
}
