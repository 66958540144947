import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss']
})
export class StatusSelectComponent implements OnInit {
  @Input('allStatus') allStatus: any;
  @Output() statusSelectedEvent = new EventEmitter<any>();
  @Output() hideSelectEvent = new EventEmitter<any>();
  dropdownIsOpen: any = true;

  @ViewChild('trigger') trigger: ElementRef;

  constructor() { }

  ngOnInit() {
    console.log(this.allStatus)
  }
  selectStatus(status) {
    this.triggerFalseClick()
    this.statusSelectedEvent.emit(status)
  }
  hideSelect() {
    this.triggerFalseClick()
    this.hideSelectEvent.emit()
  }

  triggerFalseClick() {
    this.trigger.nativeElement.click();
  }
}
