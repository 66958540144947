import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

interface TransferStatus {
  id: number;
  value: string;
  description: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sp-input-transfer-status-select',
  templateUrl: './input-transfer-status-select.component.html',
  styleUrls: ['./input-transfer-status-select.component.scss']
})
export class InputTransferStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() multiple = false;

  @Output() itemSelected = new EventEmitter<TransferStatus>();

  statusList: TransferStatus[];

  constructor() { }

  ngOnInit() {
    this.generateStatusList();
  }

  generateStatusList() {
    this.statusList = [
      {
        id: 0,
        value: "INITIATED",
        description: 'bonifici.bonifico_status.INITIATED'
      },
      {
        id: 1,
        value: "CONFIRMED",
        description: 'bonifici.bonifico_status.CONFIRMED'
      },
      {
        id: 2,
        value: "ERROR",
        description: 'bonifici.bonifico_status.ERROR'
      },
      {
        id: 4,
        value: "PENDING",
        description: 'bonifici.bonifico_status.PENDING'
      },
      {
        id: 5,
        value: "CANCELED",
        description: 'bonifici.bonifico_status.CANCELED'
      },
      {
        id: 6,
        value: "WAITING_BALANCE",
        description: 'bonifici.bonifico_status.WAITING_BALANCE'
      },
      {
        id: 7,
        value: "CONFIRMED_ERROR",
        description: 'bonifici.bonifico_status.CONFIRMED_ERROR'
      },
      {
        id: 8,
        value: "CONFIRMED_PENDING",
        description: 'bonifici.bonifico_status.CONFIRMED_PENDING'
      },
      {
        id: 9,
        value: "CONFIRMED_CANCELED",
        description: 'bonifici.bonifico_status.CONFIRMED_CANCELED'
      },
    ];
  }

  changed(ev: TransferStatus) {
    this.itemSelected.emit(ev);
  }

}
