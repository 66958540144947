import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
// import { ImageCroppedEvent } from 'ngx-image-cropper/src/image-cropper.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SharedService } from '../../../shared/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingService } from '../../../services/setting.service';
import { SettingsService } from '../../../core/settings/settings.service';
import swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-change-logo',
  templateUrl: './change-logo.component.html',
  styleUrls: ['./change-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeLogoComponent implements OnInit {
  @ViewChild('uploaderInput')
  uploaderInputVariable: ElementRef;
  imageChangedEvent: any = null;
  croppedImage: any = null;
  logoPath: any;
  oldLogoPath:any;

  constructor(
    public settings: SettingsService,
    private settingService: SettingService,
    private sharedService: SharedService,
    private http: HttpClient) { }

  ngOnInit() {
    if (localStorage.getItem('logo')) {
      this.logoPath = localStorage.getItem('logo')
    }
    else {
      this.logoPath = 'assets/img/logo-single.png'
    }
    this.oldLogoPath = this.logoPath
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.sharedService.changeLogo(this.croppedImage)
  }

  cancelUpload() {
    this.resetFileChooser();
    this.sharedService.changeLogo(this.oldLogoPath)
  }

  saveUpload() {
    this.uploadLogo(this.croppedImage);
  }

  uploadLogo(logo) {
    let fullname = this.imageChangedEvent.target.files[0].name;
    let name = fullname.substring(0, fullname.lastIndexOf("."));

    let file = {
      "name": name,
      "fileData": logo
    }

    this.settingService.uploadSettingsPhoto(file).subscribe((response: any) => {
      if (response.outcome.success === true) {
        this.logoPath = 'data:image/' + response.data.extention + ';base64,' + response.data.encodedMedia ;
        this.oldLogoPath = this.logoPath;
        localStorage.setItem('logo', this.logoPath);
        swal("Success", "", "success");
        this.resetFileChooser();
      } else {
        swal("Error", "", "error");
        this.resetFileChooser();
      }
    })
  }

  resetFileChooser() {
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.uploaderInputVariable.nativeElement.value = "";
  }

  imageLoaded() {

  }

  loadImageFailed() {

  }
}
