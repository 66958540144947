import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IStatus } from '../../../models/base';

@Component({
  selector: 'sp-input-status-select',
  templateUrl: './input-status-select.component.html',
  styleUrls: ['./input-status-select.component.scss']
})
export class InputStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() multiple = false;
  @Output() itemSelected = new EventEmitter<IStatus>();

  statuses: IStatus[];

  constructor() { }

  ngOnInit() {

    this.getStatuses();

  }

  getStatuses() {
    this.statuses = [
      {
        id: 1,
        value: true,
        description: 'general.enabled'
      },
      {
        id: 2,
        value: false,
        description: 'general.disabled'
      }
    ];
  }

  changed(ev: IStatus) {
    this.itemSelected.emit(ev);
  }

}
