import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService) { }

  getAllUsers(userName) {
    var username = userName == null ? localStorage.getItem("username") : userName;
    if (localStorage.getItem("profile") == "RESELLER" || localStorage.getItem("profile") == "SUPERADMIN") {
      var path = environment.restBaseUrl + "/merchant/dashboard/getAll"
      return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          //this.totalItems = dataResponse['total'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }

        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
    }
  }
  getSalesCount(userName = null) {
    var username = userName == null ? localStorage.getItem("username") : userName;
    var path = environment.restBaseUrl + "/dashboard/sales/" + username + "/count"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));

  }

  getSalesValue(timePeriod, userName = null) {
    var username = userName == null ? localStorage.getItem("username") : userName;
    var path = environment.restBaseUrl + "/dashboard/sales/" + username + "/?startDate=" + this.formatDate(timePeriod.startDate) + "&endDate=" + this.formatDate(timePeriod.endDate);
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }

    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));

  }

  getSalesDate(timePeriod, userName = null) {
    var username = userName == null ? localStorage.getItem("username") : userName;
    var path = environment.restBaseUrl + "/dashboard/sales/" + username + "/detailed/?startDate=" + this.formatDate(timePeriod.startDate) + "&endDate=" + this.formatDate(timePeriod.endDate);
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  formatDate(javascriptDate: Date) {
    var month = javascriptDate.getMonth() < 10 ? "0" + (javascriptDate.getMonth()+1) : (javascriptDate.getMonth()+1) ;
    var day = javascriptDate.getDate() < 10 ? "0" + javascriptDate.getDate() : javascriptDate.getDate();
    return javascriptDate.getFullYear() + "-" + month + "-" + day;
  }
  getTableDetails() {
    var path = environment.restBaseUrl + "/dashboard/sales/admin/detailed/?startDate=2018-11-23&endDate=2018-11-29"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }

    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));

  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") || ''
      })
    };
    return httpOptions;
  }
}
