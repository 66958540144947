import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class RateService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }

  getAllRates(queryParams = null, userName) {
    var username = userName == null ? localStorage.getItem("username") : userName;
    // var path = environment.restBaseUrl + "/instalment-plan/" + username + "/list"
    var path = environment.restBaseUrl + "/instalment-plan/list" 
    if( localStorage.getItem('profile') != "SUPERADMIN" ) {
      if( localStorage.getItem('profile') == "RESELLER" ) {
        path += "/" + localStorage.getItem('idReseller')
      } else {
        path += "/" + localStorage.getItem('idMerchant')
      }
    } 
    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getAllInstalments(queryParams = null, id = null) {
    let path = environment.restBaseUrl + "/instalment/list"
    /*if( localStorage.getItem("profile") != "SUPERADMIN" ) {
      if( localStorage.getItem("profile") == "RESELLER" ) {
        path += "/" + localStorage.getItem("idReseller");
      } else {
        path += "/" + localStorage.getItem("idMerchant");
      }
    }*/
    if (id) {
      path += "/" + id
    }

    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getAllInstalmentRefunds(queryParams = null, id = null) {
    let path = environment.restBaseUrl + "/instalment/refund/list"
    /*if( localStorage.getItem("profile") != "SUPERADMIN" ) {
      if( localStorage.getItem("profile") == "RESELLER" ) {
        path += "/" + localStorage.getItem("idReseller");
      } else {
        path += "/" + localStorage.getItem("idMerchant");
      }
    }*/
    if (id) {
      path += "/" + id
    }

    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getInstalmentSummary(queryParams = null, idMerchant = null) {
    let path = environment.restBaseUrl + "/instalment/summary"
    if (idMerchant) {
      path += "/" + idMerchant
    }
    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getInstalmentPaymentSummary(queryParams = null, idMerchant = null) {
    let path = environment.restBaseUrl + "/instalment/payment/summary"
    if (idMerchant) {
      path += "/" + idMerchant
    }
    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") || ''
      })
    };
    return httpOptions;
  }
}
