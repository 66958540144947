import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';


import { AppService } from '../../core/services/base.service';
import { LoggingService } from '../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '../../services/user.service';
import { SharedService } from '../../shared/shared.service';
import { SellerService } from '../../services/seller.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Output() deletedChip = new EventEmitter;
  
  sort: any;

  checkboxes = [{
    "id": 1, "name": "enabled", "type": "word", "isChecked": false, "typeOfSearch": "checkbox"
  }, {
    "id": 2, "name": "deleted", "type": "word", "isChecked": false, "typeOfSearch": "checkbox"
  }
  ]
  sortOptions = [
    { "id": 1, "name": "name", "showField":"" },
    // { "id": 2, "name": "surname", "showField":"" },
    { "id": 3, "name": "state", "showField":"" },
    { "id": 4, "name": "profile", "showField":"" }
  ]

  selectOptions = [
    {
      "id": 1, "name": "Amministratore", "type": "word", "typeOfSearch": "level"
    }, {
      "id": 2, "name": "Rivenditore", "type": "word", "typeOfSearch": "level"
    }, {
      "id": 3, "name": "Gestore", "type": "word", "typeOfSearch": "level"
    },
    {
      "id": 4, "name": "Responsabile", "type": "word", "typeOfSearch": "level"
    },
    {
      "id": 5, "name": "Agente", "type": "word", "typeOfSearch": "level"
    }]

  pathListUsers = "/admin/user/users";
  columnsTranslation;
  public singleData;
  queryParamsSearch: any = "";
  queryParamsSort: any = "";
  public rows: Array<any> = [];
  public columns: Array<any> = [];
  public elements: Array<any>; // = TableData;

  userSelected: any;
  profile: any;
  merchantInfo: any;

  searchForm: FormGroup;

  constructor(
    private sharedService: SharedService,
    public userService: UserService,
    public sellerService : SellerService,
    public http: HttpClient,
    public appService: AppService,
    private router: Router,
    private logService: LoggingService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
    this.appService.paging = true;
    
    var self = this;

    /*this.translate.get('sort').subscribe(function(obj:any) {
      self.sortOptions[3].showField = obj.profile;
    });*/
    this.translate.get('sort').subscribe(function(obj:any) {
      self.sortOptions[0].showField = obj.name;
      // self.sortOptions[1].showField = obj.surname;
      self.sortOptions[1].showField = obj.state;
      self.sortOptions[2].showField = obj.profile;
    });

    this.searchForm = this.formBuilder.group({
      name: [null],
      surname: [null],
      email: [null],
      profile: [],
      status: [],
      idMerchant: [null]
    });
  }

  public ngOnInit(): void {
    this.profile = localStorage.getItem('profile');
    if( localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER' ) {
      this.getMerchantInfo();
    }
  }

  searchEvent(event) {
    let queryParamsSearch = this.getQueryParamsOfSearch(event)
    this.sharedService.reloadUserTable(queryParamsSearch);
  }

  sortEvent(event) {
    // this.sortProp = name;
    const name = event.target.value;
    this.sharedService.sortTable(name);
  }

  getQueryParamsOfSearch(searchItems) {
    let queryParamsSearch = "";
    if (searchItems.keyword)
      queryParamsSearch += "keyword=" + searchItems.keyword

    if (searchItems.searchFromSelect.length > 0) {
      if (searchItems.keyword)
        queryParamsSearch += "&"
      queryParamsSearch += "profile=" + searchItems.searchFromSelect.join()
    }
    return queryParamsSearch;
  }

  public loadElements() {
    this.appService.getList(this.pathListUsers, null)
      .subscribe((response) => {
        this.elements = response.results;
        this.logService.log("Lista utenti ", this.elements, 300);
      },
      (error) => {
        this.logService.log("Error", error, 200);
      }
    );
  }

  createElement() {
    this.router.navigate(['/user/new/']);
  }

  manageElement(id) {
    this.router.navigate(['/user/edit/' + id]);
  }

  deleteElement(id) {
    this.appService.getList(this.pathListUsers, null)
      .subscribe((response) => {
        this.elements = response.results;
        this.logService.log("Lista utenti ", this.elements, 300);
      },
      (error) => {
        this.logService.log("Error", error, 200);
      }
    );
  }

  public onChangePage(dataPagination: any) {
    this.appService.page = dataPagination.page;
    this.loadElements();
  }

  public onCellClick(data: any): any {
    console.log(data);
  }

  selectedUser(user) {
    this.userSelected = user;

    if (this.userSelected) {
      this.searchForm.controls.idMerchant.setValue(this.userSelected.idMerchant);
      this.searchForm.controls.idMerchant.disable();
    } else {
      this.searchForm.controls.idMerchant.reset();
      this.searchForm.controls.idMerchant.enable();
    }

    this.prepareForSearch();
  }

  chipDeleted() {
    this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
    this.sharedService.selectedUserChangeEmit()
    this.deletedChip.emit()
  }

  prepareForSearch() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (Array.isArray(this.searchForm.controls[key].value)) {
          if (!!this.searchForm.controls[key].value.length) {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
          }
        } else {
          if (queryParamsSearch) {
            queryParamsSearch += "&"
          }
          queryParamsSearch += key + "=" + this.searchForm.controls[key].value
        }
      }
    }

    this.sharedService.reloadUserTable(queryParamsSearch);
  }

  prepareForCancel() {
    this.searchForm.reset({
      idMerchant: this.userSelected ? this.userSelected.idMerchant : null
    });
    this.prepareForSearch();
  }

  getMerchantInfo() {
    this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((res) => {
      this.merchantInfo = res;
    })
  }

}
