import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IUserType } from '../../../models/user';
import { UserApiService } from '../../../services/user-api.service';

@Component({
  selector: 'sp-input-user-select',
  templateUrl: './input-user-select.component.html',
  styleUrls: ['./input-user-select.component.scss']
})
export class InputUserSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IUserType>();

  profiles: IUserType[];

  constructor(private userApiService: UserApiService) { }

  ngOnInit() {

    this.getLevels();

  }

  getLevels() {
    this.userApiService.getLevels().subscribe(levels => {      
      this.profiles = levels
    });
  }

  changed(ev: IUserType) {
    this.itemSelected.emit(ev);
  }

}
