import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  constructor(private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }

  finalizeExternalSale( token , succes) {
    var path = environment.restBaseUrl + "/gestionale/vendita/finish/" + token + "/" + succes
    console.log( 'finalizeExternal - token:' , token , 'succes' , succes)
    return this.http
      .post<any[]>(path, "", this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("serverTOserver", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("serverTOserver", " FAILURE", 200);
            return res;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error)
        }
      ))
  }

  getRequestOptionArgs(): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };
    return httpOptions;
  }

}
