import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { AppService } from '../../../core/services/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-external-application-form',
  templateUrl: './external-application-form.component.html',
  styleUrls: ['./external-application-form.component.scss']
})

export class ExternalApplicationFormComponent implements OnInit {
  countryList: any = [];
  vatList: any = [];
  vatStandard = null;
  flagPath: string;
  @Input() set externalApplicationData(externalApplicationData : any){ 
    console.log('ExternalApplicationData' , externalApplicationData )
    if ( externalApplicationData ) {
      
      this.profileForm.setValue({
        name: externalApplicationData.name || "",
        code: externalApplicationData.code || null,
        type: externalApplicationData.type || null,
        currency: externalApplicationData.currency || null,
        fixedFee: externalApplicationData.fixedFee || externalApplicationData.fixedFee == 0 ? externalApplicationData.fixedFee : null,
        percentageFee: externalApplicationData.percentageFee || externalApplicationData.percentageFee == 0 ? externalApplicationData.percentageFee : null,
        fixedFeeSmall: externalApplicationData.fixedFeeSmall || externalApplicationData.fixedFeeSmall == 0 ? externalApplicationData.fixedFeeSmall : null,
        percentageFeeSmall: externalApplicationData.percentageFeeSmall || externalApplicationData.percentageFeeSmall == 0 ? externalApplicationData.percentageFeeSmall : null,
        feeSmallMaxAmount: externalApplicationData.feeSmallMaxAmount || externalApplicationData.feeSmallMaxAmount == 0 ? externalApplicationData.feeSmallMaxAmount : null,
        acceptPrepaid: externalApplicationData.acceptPrepaid || false,
        amex: externalApplicationData.amex || false,
        sepa: externalApplicationData.sepa || false,
        paypal: externalApplicationData.paypal || false,
        klarna: externalApplicationData.klarna || false,
        country : externalApplicationData.country || null,
        idVat: externalApplicationData.idVat || null,
        webhookUrl: externalApplicationData.webhookUrl || null,
        saiotSenderCode: externalApplicationData.saiotSenderCode || null
      })

      if (externalApplicationData.country) {
        this.getAllVatsOfCountry(externalApplicationData.country);
      } else {
        this.resetVats();
      }
    }

  };

  typeOfUser:any;
  idUser: any;
  email: any;

  idExternalApplication: any;
  profile: any;
  transferFreq;

  profileForm = this.formBuilder.group({
    name: ['', Validators.required],
    code: [null, Validators.required],
    type: [null, Validators.required],
    currency: [null, Validators.required],
    fixedFee: [null, Validators.required],
    percentageFee: [null, Validators.required],
    fixedFeeSmall: [null, Validators.required],
    percentageFeeSmall: [null, Validators.required],
    feeSmallMaxAmount: [null],
    acceptPrepaid: [false],
    amex: [false],
    sepa: [false],
    paypal: [false],
    klarna: [false],
    country : [null, Validators.required],
    idVat: [null],
    webhookUrl: [null],
    saiotSenderCode: [null]
  });

  types = [
    { "name": "MARKETPLACE" },
    { "name": "SOFTWARE" }
  ]

  prepaid=[
    {string:'Accetta' , val:1},
    {string:'NON accetta' , val:0}
  ]

  currencies = [
    {
      "id": 1, "name": "EUR", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "USD", "type": "word", "isChecked": false
    }]

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    public settings: SettingsService,
    private translate: TranslateService,
    private routerExtService: RouterExtService) {}

  ngOnInit() {
    this.typeOfUser = this.route.snapshot.params['usertype']
    this.profile = localStorage.getItem("profile");
    this.idUser = this.route.snapshot.params['id'];

    this.getJSONConfiguration()
    this.getAssetsFlagPath()
    console.log( 'this.profileForm' , this.profileForm )
  }

  getAllVatsOfCountry(country = null) {
    let path = "/vat/vats"
    if (country) {
      path += "?" + "country=" + country
    }
    this.appService.getAll(path).subscribe((res:any)=>{
      this.vatList = res.results.filter(result => !result.favorite);
      this.vatStandard = res.results.find(result => result.favorite);
    })
  }

  resetVats() {
    this.vatList = [];
    this.vatStandard = null;
    this.profileForm.patchValue({ idVat: null });
  }

  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.valid) {
      this.save(this.profileForm.value)
    }
  }

  save(externalApplication) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.idUser) {
      this.appService.newElement('/external-application', externalApplication).subscribe((response: any) => {
        if (response.outcome.success === true) {
          swal(this.translate.instant('external-application.swal_user_created_ok'), "" , "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
    else {
      externalApplication['id'] = this.idUser
      this.appService.editElement('/external-application', externalApplication).subscribe((response: any) => {
        if (response.outcome && response.outcome.success === true) {
          swal(this.translate.instant('external-application.swal_user_update_ok'), "" , "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
        (error) => {
          swal("Error", "", "error");
        })
    }
  }

  onChangeCountry(country) {
    if (country) {
      this.getAllVatsOfCountry(country.code)
      this.profileForm.patchValue({ idVat: null })
    } else {
      this.resetVats();
    }
  }

  getJSONConfiguration() {
    let fileConfigurations = require('./../../../../assets/data/country-list.json');
    this.countryList = fileConfigurations;
  }

  getAssetsFlagPath() {
    let flagPath = './../../../../assets/img/country/'
    this.flagPath = flagPath;
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/external/application');
    } else {
      this.location.back();
    }
  }
}
