import { Component, OnInit, EventEmitter , Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormBuilder, Validators } from '@angular/forms';

import { AppService } from '../../../core/services/base.service';
import { FilterPageType } from '../../../core/classes/interfaces.interface';

import * as moment from 'moment';
import { takeRightWhile } from 'lodash';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
        height: '0px'
      })),
      state('final', style({
        height: '*'
      }), { params: { expandedHeight: '{{expandedHeight}}' } }),
      transition('initial=>final', animate('.8s ease')),
      transition('final=>initial', animate('.4s ease'))
    ]),
  ]
})
export class FilterSearchComponent implements OnInit {

  @Input() page: string;
  @Output() searchEvent = new EventEmitter<any>();
  @Output() changeAlert = new EventEmitter<any>();
  @Output() sortEvent = new EventEmitter<any>();
  @Output() exclusionEvent = new EventEmitter<any>();
  
  @Input('sortOptions') sortOptions: any;
  @Input('sortOptionsPlaceholder') sortDropdownsPlaceholder: any;
  @Input('expiredOptions') expiredOptions : any;
  @Input('selectOptions') selectOptions: any;
  @Input('selectOptionsPlaceholder') selectOptionsPlaceholder: any;
  @Input('intervalOptions') intervalOptions: any;

  eFilterPageType = FilterPageType;

  searchedSaleFields: any = {};
  chipFromKeyword = "";
  chipsFromSelect = [];
  chipsFromCheckbox = [];
  options: any = {}; // TODO: Da tipizzare
  openedPanel: boolean;
  chips: Array<any>;
  checkboxes: any; // FIXME: verificare se serve e se scazza chi riceve l'emit
  merchantList: any; // TODO: Da tipizzare
  profili: string;
  currentState: string = 'initial';
  profiles: any;
  drop = new FormControl(null);

  status = [
    { id: "1", name: "new" },
    { id: "2", name: "active" },
    { id: "3", name: "blocked" },
    { id: "4", name: "closed" },
    { id: "5", name: "canceled" }
  ]

  saleForm = this.fb.group({
    "nameRegistred": [''],
    "import": this.fb.group({
      "importfrom": [''],
      "importto": [''],
    }),    
    "date": [''],
    "dateRange": [''],
    "state": [null],
    "service": [''],
  });

  installmentForm = this.fb.group({
    keyword: [''],
    date: [''],
    dateRange: ['']
  })

  statsRateForm = this.fb.group({
    "day": ['']
  });

  statsFeeForm = this.fb.group({
    "daterange_fee" : [''],
    "merchant" : ['all']
  })

  bankStatementForm = this.fb.group({
    "channel" : ['all'] 
  })

  baseForm = this.fb.group({
    keyword: [''],
    userRole: [null]
  })

  constructor( private fb: FormBuilder, private router: Router,
    private basic: AppService) { 
      if (this.showProfileList()) {
        this.getLevel();
      }
    }

  ngOnInit() {
    this.openedPanel = false;
    this.profili = localStorage.getItem("profile");

    if( this.page == FilterPageType.AnalisiRate || this.page == FilterPageType.AnalisiRimborsi ) {
      this.installmentForm.controls.dateRange.setValue( {startDate: moment(), endDate: moment().add(30, 'days') })
    } else if( this.page == FilterPageType.RateDelGiorno ) {
      this.statsRateForm.controls.day.setValue( {startDate: moment(), endDate: moment() } )
    } else if( this.page == FilterPageType.RiepilogoFee ) {
      this.statsFeeForm.controls.daterange_fee.setValue( {startDate: moment().subtract(30, 'days'), endDate: moment() } )
    } else {
      this.saleForm.controls.dateRange.setValue( {startDate: moment().subtract(30, 'days'), endDate: moment() } )
    }

    if( this.page == 'stats-fee' ) {
      this.getMerchantList()
    }

    this.search();

  }

  getMerchantList() {
    this.basic.getAll('/merchant/getAll').subscribe((response) => {
      if( response['results'] ) {
        this.merchantList = response.results
      }
    })
  }

  search() {
    switch (this.page) {
      case FilterPageType.AnalisiVendite:
        this.searchForSale(this.saleForm.value)
        break;
      case FilterPageType.AnalisiRate:
      case FilterPageType.AnalisiRimborsi:
        this.searchForSale( this.installmentForm.value )
        break;
      case FilterPageType.RateDelGiorno:
        this.searchForRate();
        break;
      case FilterPageType.RiepilogoFee:
        this.searchForStatsFee( this.statsFeeForm.value );
        break;
      default:
        if (this.baseForm.value) {
          this.chipFromKeyword = this.baseForm.value.keyword;
          this.chips = this.prepareChips( this.baseForm.value );
        } else {
          this.chipFromKeyword = null;
        }
        // this.baseForm.value.keyword = "";
        let chipsFromSelectOnlyNames = this.getOnlyNames(this.chipsFromSelect)
        this.openedPanel = false;
        // this.togglePannel();
        this.currentState = 'initial';
        this.searchEvent.emit({ "keyword": this.chipFromKeyword, "searchFromSelect": chipsFromSelectOnlyNames, "searchFromCheckbox": this.checkboxes })
        break;
    }
    
  }

  searchForSale(values) {
    // this.manageExpandedPanel(values)

    this.searchedSaleFields = values;

    if( values.import && values.import.importto && values.import.importfrom) {
      this.searchedSaleFields.import = values.import.importfrom + " - " + values.import.importto;
    } /* else {
      this.searchedSaleFields.import = null;
      // this.searchedSaleFields.import.importfrom = null;
      // this.searchedSaleFields.import.importto = null;
    } */
    
    // if (values.importfrom && values.importto)
    //   this.searchedSaleFields.import = values.importfrom + " - " + values.importto;
    // else if (values.importfrom && !values.importto) {
    //   this.searchedSaleFields.import = values.importfrom + " - ∞"
    // }
    // else if (!values.importfrom && values.importto) {
    //   this.searchedSaleFields.import = "∞ - " + values.importto;
    // }

    /* if( values.nameRegistred ) {
      this.searchedSaleFields.nameRegistred = values.nameRegistred;
    } */

    // if( values.keyword ) {
    //   this.searchedSaleFields.keyword = values.keyword;
    // }
    // console.log('searchForSale - this.searchedSaleFields', this.searchedSaleFields)
    
    // this.searchedSaleFields.service = values.service;
    // if (values.state) {
    //   this.clearStatus();
    // }
    // this.searchedSaleFields.state = values.state;
    if (values.dateRange && values.dateRange.startDate && values.dateRange.endDate) {
      console.log('searchForSale if - values', values)
      this.searchedSaleFields.date = {};
      this.searchedSaleFields.date['startDate'] = values.dateRange.startDate.format("YYYY-MM-DD");
      this.searchedSaleFields.date['endDate'] = values.dateRange.endDate.format("YYYY-MM-DD");
      this.options.dates = values.dateRange.startDate.format("YYYY-MM-DD") + '/' + values.dateRange.endDate.format("YYYY-MM-DD");
    } else {
      console.log('searchForSale else - values', values)
      this.searchedSaleFields.date = null;
      this.options.dates = null;
    } 

    this.openedPanel = false;
    // this.togglePannel();
    this.currentState = 'initial';
    // this.changeStateOfPanel();
    this.searchEvent.emit(this.prepareSaleSearch(this.searchedSaleFields));

    this.chips = this.prepareChips( this.searchedSaleFields );
  }

  prepareSaleSearch(object) {
    let preparedObject: any = {};
    if (object.date) {
      preparedObject.startDate = object.date.startDate;
      preparedObject.endDate = object.date.endDate;
    } // Aggiungo else dove controllo se è stringa e splitto per start date e end date
    if (object.import && object.import.length > 0) {
      preparedObject.importfrom = object.import.split(" - ")[0];
      preparedObject.importto = object.import.split(" - ")[1];
    }
    if (object.state && object.state.length > 0)
      preparedObject.status = object.state;
    if (object.nameRegistred && object.nameRegistred.length > 0)
      preparedObject.nameRegistred = object.nameRegistred;
    if (object.keyword && object.keyword.length > 0)
      preparedObject.keyword = object.keyword;
    if (object.service && object.service.length > 0)
      preparedObject.service = object.service;
    return preparedObject;
  }

  searchForRate() {

    if (this.statsRateForm) {
      if (this.statsRateForm.value.day && this.statsRateForm.value.day.startDate && this.statsRateForm.value.day.endDate) {
        this.options.day = this.statsRateForm.value.day.startDate.format("YYYY-MM-DD") + "/" + this.statsRateForm.value.day.endDate.format("YYYY-MM-DD");
      } else {
        this.options.day = null;
      }
    }

    this.openedPanel = false;
    // this.togglePannel();
    this.currentState = 'initial';
    // this.changeStateOfPanel();
    this.searchEvent.emit(this.prepareRateSearch(this.options));

    this.chips = this.prepareChips( this.options );
  }

  prepareRateSearch(object) {
    let preparedObject: any = {};
    if (object.day && object.day.length > 0) {
      preparedObject.startDate = object.day.split("/")[0];
      preparedObject.endDate = object.day.split("/")[1];
    }
    return preparedObject;
  }

  searchForStatsFee( values ) {
    this.searchedSaleFields = values;

    if ( values.daterange_fee ) {
      if ( values.daterange_fee.startDate ){
        this.searchedSaleFields.daterange_fee.startDate = values.daterange_fee.startDate.format("YYYY-MM-DD");
        this.searchedSaleFields.daterange_fee.endDate = values.daterange_fee.endDate.format("YYYY-MM-DD");
        // this.options.dates = null
        // this.options.dates = values.daterange_fee.startDate.format("YYYY-MM-DD") + "/" + values.daterange_fee.endDate.format("YYYY-MM-DD");
      }
    } /* else {
      this.searchedSaleFields.daterange_fee = null;
      this.options.dates = null;
    } */

    this.openedPanel = false;
    // this.togglePannel();
    this.currentState = 'initial';
    // this.changeStateOfPanel();

    // this.searchEvent.emit(this.prepareFeeSearch(values))
    this.searchEvent.emit(this.prepareFeeSearch( this.searchedSaleFields ))

    this.chips = this.prepareChips( this.searchedSaleFields );
  }

  prepareFeeSearch(object) {
    let preparedObject : any = {};
    console.log('prepareFeeSearch - object', object)
    if(object.daterange_fee) {
      preparedObject.startDate = object.daterange_fee.startDate;
      preparedObject.endDate = object.daterange_fee.endDate;
    }
    if(object.merchant != 'all') {
      preparedObject.idMerchant = object.merchant;
      let _merchant = this.merchantList.find( x => x.idMerchant === object.merchant )
      console.log( 'merchantList', this.merchantList, 'object.merchant', object.merchant , this.merchantList.find( x => x.idMerchant === object.merchant ))
      this.searchedSaleFields.merchant = _merchant;
    }
    return preparedObject
  }

  clearFormControlName(form, name) {

    console.log('form', form, 'name', name)
    this.searchedSaleFields[name] = null;
    if (name == 'import') {
      form.get("import.importfrom").setValue('');
      form.get("import.importto").setValue('');
    } else if (name == 'state') {
      form.get(name).setValue(null);
    } else if( name == 'dateRange' || name == 'date' ) {
      form.get('dateRange').setValue(null);
      form.get('date').setValue(null);
    } else if(name == 'day') {
      form.get('day').setValue(null);
    } else if( name == 'merchant') {
      form.get(name).setValue('all');
    } else {
      form.get(name).setValue('');
    }

    switch (this.page) {
      case FilterPageType.AnalisiVendite:
        this.searchForSale(form.value)
        break;
      case FilterPageType.AnalisiRate:
      case FilterPageType.AnalisiRimborsi:
        this.searchForSale( form.value )
        break;
      case FilterPageType.RateDelGiorno:
        this.searchForRate();
        break;
      case FilterPageType.RiepilogoFee:
        console.log('form.value', form.value);
        this.searchForStatsFee( form.value );
        break;
      default:
        this.chips = this.prepareChips( form.value );
        this.searchEvent.emit({ "keyword": "", "searchFromSelect": [], "searchFromCheckbox": "" })
        break;
    }

    // this.searchForSale(form.value);
    // this.searchForRate();
    // this.searchForStatsFee(form.value);
    // this.searchEvent.emit({ "keyword": "", "searchFromSelect": [], "searchFromCheckbox": "" })

  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    //   return JSON.stringify(obj) === JSON.stringify({})
    return true;
  }

  // hasFilterMerchant() {
  //   // console.log( 'hasFilterMerchant' )
  //   let placeholderUser = JSON.parse(localStorage.getItem("selectedUser"))
  //   if (placeholderUser != null && this.page != 'reseller' && this.page != 'seller' && this.page != 'rateOfDay') {
  //     this.options.userSelected = placeholderUser;
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }

  // }

  checkSelectedUser() {}

  clearDates() {
    this.options.dates = null
    delete this.options.dates
    localStorage.removeItem("saleDate")
    this.changeAlert.emit()
  }

  onDateRangeChange( event ) {
    console.log( 'onDateRangeChange - event' , event )
  }

  togglePannel() {
    this.openedPanel = !this.openedPanel;
    this.changeStateOfPanel();
  }

  changeStateOfPanel() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  prepareChips( searchedFilters ) {
    let result : Object = {};
    let arr: Array<any> = [];
    let typeChip: string;

    Object.keys(searchedFilters).forEach( (key, index) => {

      // if( searchedFilters[key] && typeof searchedFilters[key] != 'object' ) {
      //   result[key] = searchedFilters[key];
      // } else if( searchedFilters[key] && typeof searchedFilters[key] == 'object' && Object.keys(searchedFilters).length ) {
      //   result[key] = searchedFilters[key];
      // } else {
      //   result[key] = null;
      // }

      switch (key) {
        case 'nameRegistred':
        case 'service':
          typeChip = 'word'
          break;
        case 'date':
        case 'dateRange':
        case 'daterange_fee':
          typeChip = 'date';
          break;
        case 'day':
          typeChip = 'day';
        default:
          typeChip = 'word';
          break;
      }

      console.log('prepareChips - searchedFilters' , searchedFilters )

      let element: Object = {};
      if( searchedFilters[key] && typeof searchedFilters[key] != 'object' && key != 'merchant' ) {
        let val = searchedFilters[key];
        if( key == 'day' ) {
          val = searchedFilters[key].split('/')[0];
        }
        element = { 
          id: index, 
          name : key,
          type: typeChip, 
          value: val };
        arr.push( element );
      } else if( key == 'dateRange' && searchedFilters[key].startDate && typeof searchedFilters[key] == 'object' ) {
        let val = searchedFilters[key].startDate.format("YYYY-MM-DD") + '/' + searchedFilters[key].endDate.format("YYYY-MM-DD");
        element = { id: index, name: 'date', type: typeChip, value: val };
        arr.push( element );
      } else if(key == 'daterange_fee' && searchedFilters[key].startDate && typeof searchedFilters[key] == 'object' ) {
        let val = searchedFilters[key].startDate + '/' + searchedFilters[key].endDate;
        element = { id: index, name: 'daterange_fee', type: typeChip, value: val };
        arr.push( element );
      } else if( key == 'import' && searchedFilters[key].importfrom && typeof searchedFilters[key] == 'object' ) {
        let val = searchedFilters[key].importfrom + ' - ' + searchedFilters[key].importto;
        element = { id: index, name: key, type: typeChip, value: val };
        arr.push( element );
      } else if( key == 'userRole' && searchedFilters[key] ) {
        element = { 
          id: index, 
          name : key,
          type: typeChip, 
          value: searchedFilters[key].name };
        arr.push( element );
      } else if( key == 'merchant' && searchedFilters[key] != 'all' ) {
        element = { 
          id: index, 
          name : key,
          type: typeChip, 
          value: searchedFilters[key].companyName };
        arr.push( element );
      }
    })

    return arr;

  }

  getVisibleForm() {
    switch (this.page) {
      case FilterPageType.AnalisiVendite:
        return this.saleForm;
      case FilterPageType.AnalisiRate:
      case FilterPageType.AnalisiRimborsi:
        return this.installmentForm;
      case FilterPageType.RateDelGiorno:
        return this.statsRateForm;
      case FilterPageType.RiepilogoFee:
        return this.statsFeeForm;
      case FilterPageType.EstrattoConto:
        return this.bankStatementForm;
      default:
        return this.baseForm;
    }
  }

  showProfileList() {
    if (!this.activeRoute("profile-rate") && !this.activeRoute("reseller") && !this.activeRoute("sales") && !this.activeRoute("seller")) {
      return true;
    }
    else {
      return false;
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  sort(event) {
    this.sortEvent.emit(event)
  }

  getLevel() {
    this.basic.getAll('/user/profile')
      .subscribe((response) => {
        this.profiles = response.results;
        this.profiles.forEach(element => {
          element['name'] = element.description;
          element['type'] = 'word';
          element['typeOfSearch'] = 'level';
        });
      },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  profile(event) {
    this.chipsFromSelect = [];
    if (this.profili == 'MANAGER') {
      let selected = this.profiles[event.id - 3];
      if (selected) {
        let founded = this.checkIfExistOnArrayById(this.chipsFromSelect, selected);
        if (!founded)
          this.chipsFromSelect.push(selected);
        // this.baseForm.controls.userRole.reset();
      }
    }
    else if (this.profili == 'RESPONSIBLE') {
      let selected = this.profiles[event.id - 5];
      if (selected) {
        let founded = this.checkIfExistOnArrayById(this.chipsFromSelect, selected);
        if (!founded)
          this.chipsFromSelect.push(selected);
        // this.baseForm.controls.userRole.reset();
      }
    }
    else {
      let selected = this.profiles[event.id - 1];
      if (selected) {
        let founded = this.checkIfExistOnArrayById(this.chipsFromSelect, selected);
        if (!founded)
          this.chipsFromSelect.push(selected);
        // this.baseForm.controls.userRole.reset();
      }
    }
  }

  checkIfExistOnArrayById(array, element) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == element.id) {
        return true
      }
    }
    return false
  }

  getOnlyNames(array) {
    let arrayWithNames = []
    array.forEach(element => {
      arrayWithNames.push(element.name)
    });
    return arrayWithNames
  }

  exclusionToggle( event ) {
    this.exclusionEvent.emit( event )
  }

}
