import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ILanguage } from '../../../models/base';
import { TranslatorService } from '../../../../core/translator/translator.service';

@Component({
  selector: 'sp-input-language-select',
  templateUrl: './input-language-select.component.html',
  styleUrls: ['./input-language-select.component.scss']
})
export class InputLanguageSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<ILanguage>();

  languageList: ILanguage[];

  constructor(private translator: TranslatorService) { }

  ngOnInit() {
    this.getLanguageList();
  }

  getLanguageList() {
    this.languageList = this.translator.getAvailableLanguages();
  }

  changed(ev: ILanguage) {
    this.itemSelected.emit(ev);
  }

}
