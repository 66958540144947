import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { SettingsService } from '../../../core/settings/settings.service';
import swal from 'sweetalert2';
import { AppService } from '../../../core/services/base.service';
import { LoggingService } from '../../../core/services/log.service';
import { DualListComponent } from 'angular-dual-listbox';
import { SharedService } from '../../../shared/shared.service';
import { Location } from '@angular/common';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-responsable-form',
  templateUrl: './responsable-form.component.html',
  styleUrls: ['./responsable-form.component.scss']
})
export class ResponsableFormComponent implements OnInit {
  @Input() userData: any;
  @Input() rates: any;
  @Input() merchantList: any;
  typeOfUser: any;
  //merchantList: Array<any>;
  profile: any;
  levels: any;
  userId: any;
  idReseller:any;
  agents: Array<any>;
  isUpdate;
  idMerchant; //CAPIRE DOVE USARLO
  
  tab = 1;
  keepSorted = true;
  key: string;
  keyId: string;
  display: any;
  filter = false;
  userAdd = '';
  disabled = false;
  sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;
  users: Array<any>;

  public source: Array<any> = new Array<any>();
  public confirmed: Array<any> = new Array<any>();

  responsabileForm = this.formBuilder.group({
    idProfile: [4],
    idUser: [null],
    telephone: [''],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
    idMerchant: [null, Validators.required],
  })

  days=[
    {number:1},
    {number:2},
    {number:3},
    {number:4},
    {number:5},
    {number:6},
    {number:7},
    {number:8},
    {number:9},
    {number:10},
    {number:11},
    {number:12},
    {number:13},
    {number:14},
    {number:15},
    {number:16},
    {number:17},
    {number:18},
    {number:19},
    {number:20},
    {number:21},
    {number:22},
    {number:23},
    {number:24},
    {number:25},
    {number:26},
    {number:27},
    {number:28}
  ]

  currencies = [
    {
      "id": 1, "name": "EUR", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "DOLLAR", "type": "word", "isChecked": false
    }]

  dum = [
    {
      "id": 1, "name": "First", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "Second", "type": "word", "isChecked": false
    }, {
      "id": 3, "name": "Third", "type": "date", "isChecked": false
  }]

  msgTranslation;
  errorTranslation;
  errorMessage;
  originalUserDatas: {};

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private userService: UserService,
    public settings: SettingsService,
    private basic: AppService,
    private logService: LoggingService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private location: Location,
    private routerExtService: RouterExtService) {
      this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
  }

  ngOnInit() {
    this.idReseller = localStorage.getItem("idReseller");
   
    this.typeOfUser = this.route.snapshot.params['usertype']
    this.profile = localStorage.getItem("profile");
    this.userId = this.route.snapshot.params['id'];

    if (this.profile != 'SUPERADMIN') {
      this.idMerchant = localStorage.getItem("idMerchant")
      this.responsabileForm.controls['idMerchant'].setValue(this.idMerchant)
    }

    if (this.userId) {
      this.idMerchant = this.userData.idMerchant
      this.responsabileForm.setValue({
        idProfile: 4,
        idUser: this.userData.idUser,
        idMerchant: this.userData.idMerchant,
        telephone: this.userData.telephone ? this.userData.telephone : "",
        email: this.userData.email ? this.userData.email : "",
        name: this.userData.name ? this.userData.name : "",
        surname: this.userData.surname ? this.userData.surname : "",
      })
    }
  }

  addConfirmed() {
    if (this.userData && this.userData.merchantList != null) {
      var found = false;
      for (var i = 0; i < this.userData.merchantList.length; i++) {
        found = false;
        for (var j = 0; j < this.source.length; j++) {
          if (this.userData.merchantList[i].id == this.source[j].idUser) {
            found = true;
            this.confirmed.push(this.source[j])
            break;
          }
        }
        if (!found) {
          this.confirmed.push({ id: this.userData.merchantList[i].id, name: this.userData.merchantList[i].name, surname: this.userData.merchantList[i].surname })
        }
      }
    }
  }

  onChangeMerchant(idMerchant) {
    this.idMerchant = idMerchant;
  }

  private agentLabel(item: any) {
    return item.name + " " + item.surname
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }

  onSubmit() {
    for (var i in this.responsabileForm.controls) {
      this.responsabileForm.controls[i].markAsTouched();
    }
    if (this.responsabileForm.status == 'VALID') {
      this.save(this.responsabileForm.value)
    }
  }

  save(user) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.userId) {
      this.userService.insertUser(user).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('general.success'), "", "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        swal("Error", "", "error");
      })
    }
    else {
      user['idMerchant'] = this.idMerchant;
      user['idUser'] = this.userId;
      this.userService.updateUserDatas(user).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('general.modificate'), "", "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal("Error", this.errorMessage, "error");
      })
    }
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/user');
    } else {
      this.location.back();
    }
  }
}
