import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IInstalmentFrequency } from '../../../models/instalment';

@Component({
  selector: 'sp-input-instalment-frequency-select',
  templateUrl: './input-instalment-frequency-select.component.html',
  styleUrls: ['./input-instalment-frequency-select.component.scss']
})
export class InputInstalmentFrequencySelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IInstalmentFrequency>();

  frequencies: IInstalmentFrequency[];

  constructor() { }

  ngOnInit() {

    this.getFrequencies();

  }

  getFrequencies() {
    this.frequencies = [
      {
        id: 1,
        code: 'MENSILE',
        description: 'rata.frequenza_1'
      },
      {
        id: 2,
        code: 'BIMESTRALE',
        description: 'rata.frequenza_2'
      },
      {
        id: 3,
        code: 'TRIMESTRALE',
        description: 'rata.frequenza_3'
      },
      {
        id: 4,
        code: 'SEMESTRALE',
        description: 'rata.frequenza_6'
      },
      {
        id: 5,
        code: 'ANNUALE',
        description: 'rata.frequenza_12'
      }
    ]
  }

  changed(ev: IInstalmentFrequency) {
    this.itemSelected.emit(ev);
  }

}
