import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../../../core/services/base.service';
import { SalesService } from '../../../../../services/sales.service';
import { LoggingService } from '../../../../../core/services/log.service';
import { Router , ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethodType } from '../../../../../core/classes/interfaces.interface';
import { StripeElementComponent } from '../../../../../shared/component/stripe-element/stripe-element.component';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';

moment.locale('it', localization);

@Component({
  selector: 'app-edit-method',
  templateUrl: './edit-method.component.html',
  styleUrls: ['./edit-method.component.scss']
})
export class EditMethodComponent implements OnInit {
  
  @ViewChild(StripeElementComponent) private _stripe: StripeElementComponent;
  public activated: boolean;
  public responseArrived: boolean;
  public errorMessage: any = '';
  public saleData: any;
  public salePaymentMethods: any;
  // public isTec = 0;
  // public isGDPR = 0;
  public configurationSettings: any;
  salePaymentMethodType: string;
  salePaymentMethod: any;
  selectedPaymentMethodType: string;
  selectedPaymentMethod: any;

  stripeValid: number = 0;
  elements: any;
  cardElement: any;
  sepaElement: any;
  paymentElement: any;
  stripe: any;
  stripeForm: any;
  token: any;
  PaymentMethodType = PaymentMethodType;
  availablePaymentMethods = [PaymentMethodType.CARD, PaymentMethodType.SEPA_DEBIT, PaymentMethodType.PAYPAL];
  editPaymentMethodSuccess: boolean = false;
  saving = false;

  constructor(
    private salesService: SalesService, 
    private appService: AppService, 
    private logger: LoggingService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data.card != null && data.card) {
        this.availablePaymentMethods = [PaymentMethodType.CARD];
      }
    });
    this.route.params.subscribe(params => {
      this.token = params['token'];
      if( this.token ) {
        this.checkToken();
      }
    });
  }

  async checkToken() {
    let resp = await this.salesService.getSalePMData(this.token).toPromise();
    this.responseArrived = true;
    // this.logger.log( 'loadData - resp' , resp , 200)
    if (!resp || !resp.outcome || resp.outcome.success == false) {
      this.activated = false;
    } else {
      this.saleData = resp.data;
      let respPaymentMethods = await this.salesService.getSalePaymentMethods(this.token).toPromise();
      if (!respPaymentMethods || !respPaymentMethods.outcome || respPaymentMethods.outcome.success == false) {
        this.activated = false;
      } else {
        this.salePaymentMethods = respPaymentMethods.data;
        this.setSalePaymentMethod();
        let respSettings = await this.appService.getAllFromData('/sale/pm/settings/STRIPE', this.token).toPromise();
        if (respSettings && respSettings.configuration) {
          this.configurationSettings = respSettings.configuration;
          this.activated = true;
        } else {
          this.activated = false;
        }
      }
    }
  }

  setSalePaymentMethod() {
    if (this.salePaymentMethods) {
      this.salePaymentMethod = this.salePaymentMethods.cards.find(card => card.current);
      if (this.salePaymentMethod) {
        this.salePaymentMethodType = PaymentMethodType.CARD;
      } else {
        this.salePaymentMethod = this.salePaymentMethods.sepas.find(sepa => sepa.current);
        if (this.salePaymentMethod) {
          this.salePaymentMethodType = PaymentMethodType.SEPA_DEBIT;
        }
      }
    }
  }

  isPaymentMethodAvailable( paymentMethodType ) {
    return this.availablePaymentMethods.includes(paymentMethodType);
  }

  isPaymentMethodSelected( paymentMethod ) {
    return !!this.selectedPaymentMethod && this.selectedPaymentMethod.idCustomerPM == paymentMethod.idCustomerPM;
  }

  isNewPaymentMethodSelected() {
    return !this.selectedPaymentMethod;
  }

  rowPaymentMethodSelected( paymentMethod, paymentMethodType ) {
    this.selectedPaymentMethodType = paymentMethodType;
    this.selectedPaymentMethod = paymentMethod;
  }

  rowNewPaymentMethodSelected() {
    this.selectedPaymentMethodType = null;
    this.selectedPaymentMethod = null;
  }

  existOtherPaymentMethod() {
    return this.salePaymentMethods &&
      (this.salePaymentMethods.cards.some(card => !card.current) ||
      (this.isPaymentMethodAvailable(PaymentMethodType.SEPA_DEBIT) && this.salePaymentMethods.sepas.some(sepa => !sepa.current)));
  }

  getStripeValidation( event ) {
    if(event == 'valid') {
      this.stripeValid = 1;
    } else {
      this.stripeValid = 0;
    }
  }

  // changeTeC() {
  //   if(this.isTec == 0) this.isTec = 1;
  //   else this.isTec = 0;
  // }

  // changeGDPR() {
  //   if(this.isGDPR == 0) this.isGDPR = 1;
  //   else this.isGDPR = 0;
  // }

  isPaymentMethodGoingToExpire(paymentMethod) {
    if (!paymentMethod || !paymentMethod.expMonth || !paymentMethod.expYear) {
      return false;
    }
    if (!this.saleData.sale ||
      (this.saleData.sale.paymentType != 'RATEIZZATO' && this.saleData.sale.paymentType != 'RICORRENTE')) {
      return false;
    }
    if (this.saleData.sale.lastExpirationDate) {
      const lastExpirationDate = moment(this.saleData.sale.lastExpirationDate, 'YYYY-MM-DD').toDate();
      return (paymentMethod.expYear < lastExpirationDate.getFullYear() ||
        (paymentMethod.expYear == lastExpirationDate.getFullYear() && 
          paymentMethod.expMonth < lastExpirationDate.getMonth() + 1));
    } else {
      const lastExpirationDate = moment().toDate();
      return (paymentMethod.expYear < lastExpirationDate.getFullYear() ||
        (paymentMethod.expYear == lastExpirationDate.getFullYear() && 
          paymentMethod.expMonth <= lastExpirationDate.getMonth() + 1));
    }
  }

  async saveSelectedPaymentMethod() {
    const responseEditPaymentMethod = await this.salesService.editSalePaymentMethod(this.token,
      this.selectedPaymentMethod).toPromise();
    if (!responseEditPaymentMethod || !responseEditPaymentMethod.outcome || responseEditPaymentMethod.outcome.success == false) {
      const responseErrorDialog = await swal({
        title: this.translate.instant('msg.error'),
        text: this.translate.instant('sale.generic_setup_error'),
        type: "error"
      });
      this.saving = false;
    }
    return responseEditPaymentMethod;
  }

  async saveMethod() {
    this.getStripeElRef();

    /*swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });*/

    this.saving = true;

    if (this.selectedPaymentMethod) {
      if (this.isPaymentMethodGoingToExpire(this.selectedPaymentMethod)) {
        const responseConfirmDialog = await swal({
          title: this.translate.instant('msg.warning'),
          text: this.translate.instant('sale.msg_payment_method_going_to_expire'),
          confirmButtonText: this.translate.instant('sale.msg_payment_method_going_to_expire_confirm'),
          showCancelButton : true,
          cancelButtonText: this.translate.instant('general.cancel'),
          type: "warning"
        });
        if (!responseConfirmDialog.value) {
          this.saving = false;
          return;
        }
      }
      
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      
      const responseEditPaymentMethod = await this.saveSelectedPaymentMethod();
      if (!responseEditPaymentMethod || !responseEditPaymentMethod.outcome || responseEditPaymentMethod.outcome.success == false) {
        return;
      }
    } else {
      swal({
        title: '',
        onOpen: (() => swal.showLoading()),
        allowEscapeKey: false,
        allowOutsideClick: false,
      });

      const { error: submitError } = await this.elements.submit();
      if (submitError) {
        swal({
          title: this.translate.instant('msg.error'),
          text: submitError.message,
          type: "error"
        });
        this.saving = false;
        return;
      }

      const { error: paymentMethodError, paymentMethod } = await this.stripe.createPaymentMethod({
        elements: this.elements,
        params: {
          billing_details: {
            name: this.stripeForm.controls['name'].value,
            email: this.stripeForm.controls['email'].value,
            phone: '',
            address: {
              line1: '',
              line2: '',
              city: '',
              state: '',
              country: 'IT',
              postal_code: ''
            }
          }
        }
      });
      if (paymentMethodError) {
        const responseErrorDialog = await swal({
          title: this.translate.instant('msg.error'),
          text: paymentMethodError.message,
          type: "error"
        });
        this.saving = false;
        return;
      }
      let newPaymentMethod = null;
      let requestCheckPaymentMethod = null;
      switch (paymentMethod.type) {
        case PaymentMethodType.CARD:
          if (paymentMethod.card) {
            if (paymentMethod.card.funding === 'prepaid' && this.saleData.merchant.acceptPrepaid == false) {
              const responseErrorDialog = await swal({
                title: this.translate.instant('msg.error'),
                text: this.translate.instant('sale.card_not_supported'),
                type: "error"
              });
              this.saving = false;
              return;
            } else if (paymentMethod.card.brand === 'amex' && this.saleData.merchant.amex == false) {
              const responseErrorDialog = await swal({
                title: this.translate.instant('msg.error'),
                text: this.translate.instant('sale.card_not_supported'),
                type: "error"
              });
              this.saving = false;
              return;
            }
            newPaymentMethod = {
              idCustomerPM: paymentMethod.id,
              type: paymentMethod.card.brand,
              country: paymentMethod.card.country,
              last4: paymentMethod.card.last4,
              expMonth: parseInt(paymentMethod.card.exp_month),
              expYear: parseInt(paymentMethod.card.exp_year)
            }
            requestCheckPaymentMethod = {
              pmType: PaymentMethodType.CARD,
              type: paymentMethod.card.brand,
              country: paymentMethod.card.country,
              last4: paymentMethod.card.last4,
              expMonth: paymentMethod.card.exp_month,
              expYear: paymentMethod.card.exp_year
            }
          }
          break;
        case PaymentMethodType.SEPA_DEBIT:
          if (paymentMethod.sepa_debit) {
            if ((!this.saleData.merchant || !this.saleData.merchant.sepa) || (!this.saleData.sale || !this.saleData.sale.sepa)) {
              const responseErrorDialog = await swal({
                title: this.translate.instant('msg.error'),
                text: this.translate.instant('sale.sepa_not_supported'),
                type: "error"
              });
              this.saving = false;
              return;
            }
            newPaymentMethod = {
              idCustomerPM: paymentMethod.id,
              bankCode: paymentMethod.sepa_debit.bank_code,
              country: paymentMethod.sepa_debit.country,
              last4: paymentMethod.sepa_debit.last4
            }
            requestCheckPaymentMethod = {
              pmType: PaymentMethodType.SEPA_DEBIT,
              bankCode: paymentMethod.sepa_debit.bank_code,
              country: paymentMethod.sepa_debit.country,
              last4: paymentMethod.sepa_debit.last4
            }
          }
          break;
        case PaymentMethodType.PAYPAL:
          if (paymentMethod.paypal) {
            if (!this.saleData.merchant || !this.saleData.merchant.paypal) {
              const responseErrorDialog = await swal({
                title: this.translate.instant('msg.error'),
                text: this.translate.instant('sale.paypal_not_supported'),
                type: "error"
              });
              this.saving = false;
              return;
            }
            swal({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.paypal_not_yet_supported'),
              type: "error"
            });
            this.saving = false;
            return;
          }
          break;
        case PaymentMethodType.KLARNA:
          if (paymentMethod.klarna) {
            if ((!this.saleData.merchant || !this.saleData.merchant.klarna) || (!this.saleData.sale || !this.saleData.sale.klarna)) {
              const responseErrorDialog = await swal({
                title: this.translate.instant('msg.error'),
                text: this.translate.instant('sale.klarna_not_supported'),
                type: "error"
              });
              this.saving = false;
              return;
            }
            swal({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.klarna_not_yet_supported'),
              type: "error"
            });
            this.saving = false;
            return;
          }
          break;
        default:
          const responseErrorDialog = await swal({
            title: this.translate.instant('msg.error'),
            text: this.translate.instant('sale.payment_method_not_supported'),
            type: "error"
          });
          this.saving = false;
          return;
      }

      if (this.isPaymentMethodGoingToExpire(newPaymentMethod)) {
        const responseConfirmDialog = await swal({
          title: this.translate.instant('msg.warning'),
          text: this.translate.instant('sale.msg_payment_method_going_to_expire'),
          confirmButtonText: this.translate.instant('sale.msg_payment_method_going_to_expire_confirm'),
          showCancelButton : true,
          cancelButtonText: this.translate.instant('general.cancel'),
          type: "warning"
        });
        if (!responseConfirmDialog.value) {
          this.saving = false;
          return;
        }
      
        swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
      }

      const responseCheckPaymentMethod = await this.appService.getAllFromData('/sale/pm/check/exist/' + this.token,
        requestCheckPaymentMethod).toPromise();
      if (responseCheckPaymentMethod) {
        const responseErrorDialog = await swal({
          title: this.translate.instant('msg.error'),
          text: this.translate.instant('sale.msg_payment_method_exist'),
          type: "error"
        });
        this.saving = false;
        return;
      }

      const responseSetupIntent = await this.appService.getAllFromData('/stripe/createSetupIntent',
        { token: this.token }).toPromise();
      if (responseSetupIntent.message) {
        const responseErrorDialog = await swal({
          title: this.translate.instant('msg.error'),
          text: this.translate.instant('sale.generic_setup_error'),
          type: "error"
        });
        this.saving = false;
        return;
      }
      const setupIntent = responseSetupIntent;

      const { error: setupIntentConfirmError } = await this.stripe.confirmSetup({
        clientSecret: setupIntent,
        confirmParams: {
          payment_method: paymentMethod.id
        },
        redirect: 'if_required'
      });
      if (setupIntentConfirmError) {
        const responseErrorDialog = await swal({
          title: this.translate.instant('msg.error'),
          text: setupIntentConfirmError.message,
          type: "error"
        });
        this.saving = false;
        return;
      }
    }

    swal.close()
    this.editPaymentMethodSuccess = true;
    this.saving = false;
  }

  getStripeElRef() {
    this.elements = this._stripe.getStripeElement().elements;
    //this.cardElement = this._stripe.getStripeElementOld().cardElement;
    //this.sepaElement = this._stripe.getStripeElementOld().sepaElement;
    this.paymentElement = this._stripe.getStripeElement().paymentElement;
    this.stripe = this._stripe.getStripeElement().stripe;
    this.stripeForm = this._stripe.getStripeElement().stripeForm;
  }

  closeTab() {
    window.close();
  }

}
