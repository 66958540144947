import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient, 
    private logger: LoggingService, 
    private router: Router, 
    private settings: SettingsService) { }
    
  getProfilesData(){
    var path = environment.restBaseUrl + "/user/me"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  updateProfilesData(profilesData){
    var path = environment.restBaseUrl + "/user/me";
    return this.http
    .put<any[]>(path, profilesData, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("updateProfileDetail", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("updateProfileDetail", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }
  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':  localStorage.getItem("token") || ''
      })
    };
    return httpOptions;
  }
}
