import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../../core/services/base.service';
import { IUserType } from '../models/user';

enum UserAPI {
  profile = "/user/profile"
}

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private appService: AppService) { }

  getLevels(): Observable<IUserType[]> {
    return this.appService.getAll(UserAPI.profile).pipe(
      map(response => {
        return response.results
      })
    )


    /*  .subscribe((response) => {
        this.profiles = response.results;
        this.profiles.forEach(element => {
          element['name'] = element.description;
          element['type'] = 'word';
          element['typeOfSearch'] = 'level';
        });
      },
        (error) => {
          console.log("Error", error);
        }
      );*/
  }

}
