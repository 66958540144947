import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sp-filter-section',
  templateUrl: './filter-section.component.html',
  styleUrls: ['./filter-section.component.scss'],
  
})
export class FilterSectionComponent implements OnInit {

  openedPanel: boolean = false;
  currentState: string = 'initial';
  @Input() showCancelButton: boolean = false;
  @Output() searchClicked = new EventEmitter<boolean>();
  @Output() cancelClicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  togglePannel() {
    this.openedPanel = !this.openedPanel;
    this.changeStateOfPanel();
  }

  changeStateOfPanel() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  search() {
    this.searchClicked.emit(true);
  }

  cancel() {
    this.cancelClicked.emit(true);
  }

}
