import { Injectable, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() onChangedLogoObserver = new EventEmitter<any>();
  @Output() sortTableObserver = new EventEmitter<any>();
  @Output() reloadUserTableObserver = new EventEmitter<any>();
  @Output() selectedUserChanged = new EventEmitter<any>();
  
  public isOpened : boolean;

  constructor(private translate: TranslateService ) {
    
    if( localStorage.getItem('sidebarUSerOpened') == null ) {
      this.isOpened = true;
      localStorage.setItem('sidebarUSerOpened' , 'true' )
    } else {
      this.isOpened = Boolean(localStorage.getItem('sidebarUSerOpened'))
    }

   }

  changeLogo(imgSrc) {
    this.onChangedLogoObserver.emit(imgSrc)
  }
  sortTable(name) {
    this.sortTableObserver.emit(name)
  }
  reloadUserTable(params) {
    console.log('sharedService - reloadUserTable')
    this.reloadUserTableObserver.emit(params)
  }
  formatDate(javascriptDate: Date) {
    var month = javascriptDate.getMonth() < 10 ? "0" + (javascriptDate.getMonth()+1) : javascriptDate.getMonth();
    var day = javascriptDate.getDate() < 10 ? "0" + javascriptDate.getDate() : javascriptDate.getDate();
    var year = javascriptDate.getFullYear().toString().substring(2)
    return day + "/" + month + "/" + year;
  }
  formatDateForServer(date) {
    date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return date;
  }

  getOnlyAFieldFromArray(field, array) {
    let newArray = [];
    array.forEach(element => {
      newArray.push(element[field])
    });
    return newArray;
  }

  toggleSidebarUtenti() {
    if( this.isOpened ) {
      this.isOpened = false
      localStorage.setItem('sidebarUSerOpened' , 'false' )
    } else {
      this.isOpened = true
      localStorage.setItem('sidebarUSerOpened' , 'true' )
    }
    return this.isOpened;
  }

  /**
   * Check if logged user is SUPERADMIN
   */
  isAdmin() {
    if( localStorage.getItem("profile") == 'SUPERADMIN') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Return current user role
   */
  getUserRole() {
    return localStorage.getItem("profile");
  }
  
  selectedUserChangeEmit( user = null ) {
    console.log( 'facio partire emit dal sharedService')
    this.selectedUserChanged.emit( user )
  }

  revertArray( arr:Array<any> ) {
    let _a: any[] = new Array;
    for (let i = arr.length-1; i >= 0; i--) {
      _a.push(arr[i])
    }
    return _a
  }

  sortArray( array: Array<any> , sortingName: string , order: string= 'DESC' ) {

    console.log('sortArray - array' , array )
    console.log('sortArray - sortingName' , sortingName )
    array = array.sort( (a, b) => {
      console.log('a[sortingName] - b[sortingName]' , a.idSale - b.idSale)
      return a.idSale - b.idSale
    });
    console.log('sorted array' , array)
    return array; 
    
  }

}

export const InstallmentNumber = { "id": 1, "name": "instalmentNumber", "showField":"" };
export const Status = { "id": 2, "name": "status", "showField":"" };
export const SaleAmount = { "id": 3, "name": "amount", "showField":"" };
export const paymentType = { "id": 4, "name": "paymentType", "showField":"" };

export const SortOptions = [
  InstallmentNumber,
  Status,
  SaleAmount,
  // paymentType,
]