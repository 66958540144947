import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var $: any;

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;

    constructor(private translate: TranslateService, private router: Router) {
        this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings - footer
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'ServicePay Frontend',
            year: ((new Date()).getFullYear()),
            version: environment.version
        };

        // Layout Settings - basic settings for layout & Co.
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    sessionExpired() {
      this.clearLocalStorage();
    }

    manageErrorMsg(outcome) {
      let { navigationUrl } = history.state;
      navigationUrl = navigationUrl || this.router.url;
      if (navigationUrl.startsWith('/user')) {
        navigationUrl = '/user';
      } else if (navigationUrl.startsWith('/profile-rate')) {
        navigationUrl = '/profile-rate';
      }
      let message = "";
      switch (outcome?.code) {
        case '0000':
          message = this.errorTranslation.error_0000;
          break;
        case '0001':
          message = this.errorTranslation.error_0001;
          break;
        case '0002':
          message = this.errorTranslation.error_0002;
          break;
        case '0003':
          message = this.errorTranslation.bad_credential;
          break;
        case '0004':
          message = this.errorTranslation.wrong_password;
          break;
        case '0005':
          this.sessionExpired();
          this.router.navigate(["/login/0"], { state: { navigationUrl } });
          break;
        case '0006':
          message = this.errorTranslation.account_disabled;
          break;
        case '0007':
          this.sessionExpired();
          this.router.navigate(["/login/0"], { state: { navigationUrl } });
          message = this.errorTranslation.auth_required;
          break;
        case '0008':
          message = this.errorTranslation.error_0008;
          break;
        case '0009':
          message = this.errorTranslation.error_0009;
          break;
        case '0010':
          this.sessionExpired();
          this.router.navigate(["/login/0"], { state: { navigationUrl } });
          message = this.errorTranslation.activation_token_expired;
          break;
        case '0011':
          message = this.errorTranslation.error_0011;
          break;
        case '0012':
          message = this.errorTranslation.error_0012;
          break;
        case '0013':
          message = this.errorTranslation.error_0013;
          break;
        case '0014':
          message = this.errorTranslation.error_0014;
          break;
        case '0015':
          message = this.errorTranslation.error_0015;
          break;
        case '0016':
          message = this.errorTranslation.error_0016;
          break;
        case '0017':
          message = this.errorTranslation.error_0017;
          break;
        case '0018':
          message = this.errorTranslation.error_0018;
          break;
        case '0019':
          message = this.errorTranslation.error_0019;
          break;
        case '0020':
          message = this.errorTranslation.error_0020;
          break;
        case '0021':
          message = this.errorTranslation.error_0021;
          break;
        case '0022':
          message = this.errorTranslation.error_0022;
          break;
        case '0023':
          message = this.errorTranslation.error_0023;
          break;
        case '0024':
          message = this.errorTranslation.error_0024;
          break;
        case '0025':
          message = this.errorTranslation.error_0025;
          break;
        case '0026':
          message = this.errorTranslation.error_0026;
          break;
        case '0027':
          message = this.errorTranslation.error_0027;
          break;
        case '0028':
          message = this.errorTranslation.error_0028;
          break;
        case '0029':
          message = this.errorTranslation.error_0029;
          break;
        case '0030':
          message = this.errorTranslation.error_0030;
          break;
        case '0031':
          message = this.errorTranslation.error_0031;
          break;
        case '0032':
          message = this.errorTranslation.error_0032;
          break;
        case '0033':
          message = this.errorTranslation.error_0033;
          break;
        case '0034':
          message = this.errorTranslation.error_0034;
          break;
        case '0035':
          message = this.errorTranslation.error_0035;
          break;
        case '0036':
          message = this.errorTranslation.error_0036;
          break;
        case '0037':
          message = this.errorTranslation.error_0037;
          break;
        case '0038':
          message = this.errorTranslation.error_0038;
          break;
        case '0039':
          message = this.errorTranslation.error_0039;
          break;
        case '0040':
          message = this.errorTranslation.error_0040;
          break;
        case '0041':
          message = this.errorTranslation.error_0041;
          break;
        case '0042':
          message = this.errorTranslation.error_0042;
          break;
        case '0043':
          message = this.errorTranslation.error_0043;
          break;
        case '0044':
          message = this.errorTranslation.error_0044;
          break;
        case '0045':
          message = this.errorTranslation.error_0045;
          break;
        case '0046':
          message = this.errorTranslation.error_0046;
          break;
        case '0047':
          message = this.errorTranslation.error_0047;
          break;
        case '0048':
          message = this.errorTranslation.error_0048;
          break;
        case '0049':
          message = this.errorTranslation.error_0049;
          break;
        case '0050':
          message = this.errorTranslation.error_0050;
          break;
        case '0051':
          message = this.errorTranslation.error_0051;
          break;
        case '0052':
          message = this.errorTranslation.error_0052;
          break;
        default:
          message = this.errorTranslation.generic_error;
          break;
      }
      return message;
    }

    getLocalStorage( key ) {
      return localStorage.getItem( key )
    }

    setLocalStorage( key , value ) {
      localStorage.setItem( key , value )
    }

    clearLocalStorage() {
      let maxLoggedinDays = localStorage.getItem('maxLoggedinDays');
      let tableRecordNumbers = localStorage.getItem('tableRecordNumbers');
      let saveUsername = localStorage.getItem("saveUsername")
  
      let temp_langPref = localStorage.getItem('langPreference');
      let hideSaleInfo = JSON.parse(localStorage.getItem('hideSaleInfo'))
      let hideInstalmentInfo = JSON.parse(localStorage.getItem('hideInstalmentInfo'))

      localStorage.clear();

      localStorage.setItem( 'langPreference' , temp_langPref );

      if(maxLoggedinDays)
        localStorage.setItem("maxLoggedinDays",maxLoggedinDays)
      
      if(tableRecordNumbers)
        localStorage.setItem("tableRecordNumbers",tableRecordNumbers)
      
      localStorage.setItem("saveUsername",saveUsername)

      localStorage.setItem("hideSaleInfo",JSON.stringify(hideSaleInfo))
      localStorage.setItem("hideInstalmentInfo",JSON.stringify(hideInstalmentInfo))
    }

}
