import { Component, OnInit, OnDestroy, Injector, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { SellerService } from '../../services/seller.service';
import { HomeService } from '../../services/home.service';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service'

declare var $: any;

@Component({
  selector: 'app-sidebar-users',
  templateUrl: './sidebar-users.component.html',
  styleUrls: ['./sidebar-users.component.scss']
})
export class SidebarUsersComponent implements OnInit {
  @Output() selectedUser = new EventEmitter<any>();
  @Input('users') users: any;
  @Input() chipStatus:Observable<boolean>;

  listOfUsers = [];
  userSelected: any;
  userList = [];
  salesValue: any = [];
  salesCount: any = [];
  dateSales: any = [];
  timePeriod: any;
  calendarFirstIndex: number;
  calendarSecondIndex: number;

  constructor( 
    public userService : UserService , 
    public sellerService : SellerService,
    public homeService : HomeService,
    private changeDetector : ChangeDetectorRef , 
    public sharedService : SharedService ) {
      $(function () {
        if ( localStorage.getItem('sidebarUSerOpened') == null ){
          localStorage.setItem('sidebarUSerOpened' , 'true')
        }
        if ( localStorage.getItem('sidebarUSerOpened') == 'true' ){
          $(".wrapper.page").addClass("side-opened");
        } else {
          $(".wrapper.page").removeClass("side-opened");
        }

        $("#menu-toggle").unbind().click(function (e) {
          e.preventDefault();
          $("app-layout .wrapper").toggleClass("toggled");
          $(".wrapper.page").toggleClass("side-opened");
          $("#menu-toggle").toggleClass("toggled");
          if( $(".wrapper.page.side-opened").length ) {
            localStorage.setItem('sidebarUSerOpened' , 'true')
          } else {
            localStorage.setItem('sidebarUSerOpened' , 'false')
          }
        });

        $("#side-menu-toggle").click(function (e) {
          e.preventDefault();
          $(".wrapper").toggleClass("toggled");
          $("#menu-toggle").toggleClass("toggled");
        });

        $(window).resize(function (e) {
          if ($(window).width() <= 768) {
            $(".wrapper").removeClass("toggled");
            $("#menu-toggle").removeClass("toggled");
          } else {
            $(".wrapper").addClass("toggled");
            $("#menu-toggle").addClass("toggled");
          }
        });
      });
    }

  ngOnInit() {
    this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
    this.selectedUser.emit(this.userSelected)
    // this.getUsers(null)
    this.sellerService.getAllSellers().subscribe((res: any) => {
      this.userList = res.results
      this.users = res.results;
    })
    // this.refreshDashboard(null);
  }
  
  ngAfterViewChecked(){
    this.changeDetector.detectChanges();
  }

  // onClick(user) {
  //   if (username==user.username)
  //   this.userSelected = user
  //   this.selectedUser.emit(user)
  // }
  selectUser(user) {
    localStorage.setItem("selectedUser", JSON.stringify(user))
    localStorage.removeItem("removedSelectedEmit")
    this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
    this.sharedService.selectedUserChangeEmit( user )
    this.selectedUser.emit(this.userSelected)
  }
  clearSelectedUser() {
    localStorage.removeItem("selectedUser")
    localStorage.setItem("removedSelectedEmit" , "0" )
    this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
    this.sharedService.selectedUserChangeEmit()
    this.selectedUser.emit(this.userSelected)    
  }
  userSelectedExist(){
    if(JSON.parse(localStorage.getItem("selectedUser"))!= null){
      this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
      return true;
    } else {
      this.userSelected = JSON.parse(localStorage.getItem("selectedUser"))
      return false;
    }
  }

  searchUser(event) {
    var search = event.target.value;
    if (this.listOfUsers.length == 0) {
      this.listOfUsers = this.users;
    }
    var array = [];
    this.listOfUsers.forEach(user => {
      if ((user.username && user.username.toLowerCase().indexOf(search.toLowerCase()) != -1) ||
        (user.companyName && user.companyName.toLowerCase().indexOf(search.toLowerCase()) != -1) ||
        (user.pecAddress && user.pecAddress.toLowerCase().indexOf(search.toLowerCase()) != -1)) {
        array.push(user);
      }
    });
    this.users = array;
  }

  getUsers(userName = null) {
    if (localStorage.getItem("profile") == "RESELLER" || localStorage.getItem("profile") == "SUPERADMIN") {
      this.homeService.getAllUsers(userName).subscribe((res: any) => {
        this.users = res.results;
      })
    }
  }

  refreshDashboard(username) {
    this.homeService.getSalesCount(username).subscribe((res: any) => {
      this.salesCount = res;
    })
    this.homeService.getSalesValue(this.timePeriod[this.calendarFirstIndex], username).subscribe((res: any) => {
      this.salesValue = res;
    })
    this.homeService.getSalesDate(this.timePeriod[this.calendarSecondIndex], username).subscribe((res: any) => {
      this.dateSales = res;
    })
  }

}
