import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'sp-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent implements OnInit {
  @Input() disabled = false;
  @Output() onToggle = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onReset = new EventEmitter();

  mode: 'view' | 'edit' = 'view';

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.mode = this.mode == 'view' ? 'edit' : 'view';
    this.onToggle.emit();
  }

  update() {
    this.onSave.emit();
    this.toggle();
  }

  reset() {
    this.onReset.emit();
    this.toggle();
  }
  
}