import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IMerchantStatus } from '../../../models/merchant';

@Component({
  selector: 'sp-input-merchant-status-select',
  templateUrl: './input-merchant-status-select.component.html',
  styleUrls: ['./input-merchant-status-select.component.scss']
})
export class InputMerchantStatusSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiple: boolean = false;
  @Output() itemSelected = new EventEmitter<IMerchantStatus>();

  statuses: IMerchantStatus[];

  constructor() { }

  ngOnInit() {

    this.getStatuses();

  }

  getStatuses() {
    this.statuses = [
      {
        id: 1,
        code: 'ACTIVE',
        description: 'seller.status.ACTIVE'
      },
      {
        id: 2,
        code: 'INACTIVE',
        description: 'seller.status.INACTIVE'
      },
      {
        id: 3,
        code: 'BLOCKED',
        description: 'seller.status.BLOCKED'
      }
    ]
  }

  changed(ev: IMerchantStatus) {
    this.itemSelected.emit(ev);
  }

}
