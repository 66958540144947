import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { Validators, FormBuilder, ControlContainer, FormControl } from '@angular/forms';
import { DualListComponent } from 'angular-dual-listbox';
import swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { SettingsService } from '../../../core/settings/settings.service';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-reseller-form',
  templateUrl: './reseller-form.component.html',
  styleUrls: ['./reseller-form.component.scss']
})
export class ResellerFormComponent implements OnInit {
  typeOfUser:any;
  keepSorted = true;
  key: string;
  keyId: string;
  display: any;
  filter = false;
  stations: any;
  users: Array<any>;
  public source: Array<any> = new Array<any>();
  public confirmed: Array<any> = new Array<any>();
  public sourceMerchant: Array<any> = new Array<any>();
  public confirmedMerchant: Array<any> = new Array<any>();
  @Input() merchantList;
  @Input() userData: any;
  @Input() resellers: any;
  selectedResellerId;
  errorMessage;
  userAdd = '';
  errorTranslation;
  msgTranslation;
  sourceLeft = true;
  isUpdate;
  idUser: any;
  format: any = DualListComponent.DEFAULT_FORMAT;

  resellerForm = this.formBuilder.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    //companyName: ['', Validators.required],
    idProfile: [2],
    idReseller: [null, Validators.required],
    idUser: [null],
    //description: ['', Validators.required],
    //vatNumber: ['', Validators.required],
    telephone: [''],
    email: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
    //referent: [''],
    //fiscalCode: [''],
    //pecAddress  : [''],
    //phoneNumber: [''],
  });

  currencies = [
    {
      "id": 1, "name": "EUR", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "DOLLAR", "type": "word", "isChecked": false
    }]

  dum = [
    {
      "id": 1, "name": "First", "type": "word", "isChecked": false
    }, {
      "id": 2, "name": "Second", "type": "word", "isChecked": false
    }, {
      "id": 3, "name": "Third", "type": "date", "isChecked": false
  }]

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    public settings: SettingsService,
    private routerExtService: RouterExtService) {
      this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
  }

  ngOnChanges(changes: any) {
    if (changes['merchantList']) {
      if (this.merchantList != null) {
        this.stations = this.merchantList;
        this.doReset();
        this.addConfirmed();
      }
    }
    else if(changes['userData'] && this.idUser) {
      if (this.userData != null && this.merchantList != null) {
        this.stations = this.merchantList;
        this.doReset();
        this.addConfirmed();
      }
    }
  }

  addConfirmed() {
    if (this.userData && this.userData.merchantList != null) {
      var found = false;
      for (var i = 0; i < this.userData.merchantList.length; i++) {
        found = false;
        for (var j = 0; j < this.source.length; j++) {
          if (this.userData.merchantList[i].id == this.source[j].idMerchant) {
            found = true;
            this.confirmed.push(this.source[j])
            break;
          }
        }
        if (!found) {
          this.confirmed.push({ id: this.userData.merchantList[i].id, description: this.userData.merchantList[i].description })
        }
      }
    }
  }

  ngOnInit() {
    this.typeOfUser = this.route.snapshot.params['usertype']
    this.idUser = this.route.snapshot.params['id'];
    if (this.idUser) {
      this.resellerForm.setValue({
        name: this.userData.name,
        surname: this.userData.surname,
        idProfile: 2,
        idReseller: this.userData.idReseller,
        idUser: this.userData.idUser,
        //idMerchant: this.userData.idMerchant,
        //companyName: this.userData.companyName ? this.userData.companyName : "",
        //description: this.userData.description ? this.userData.description : "",
        //vatNumber: this.userData.vatNumber ? this.userData.vatNumber : "",
        telephone: this.userData.telephone ? this.userData.telephone : "",
        email: this.userData.email ? this.userData.email : "",
        //referent: this.userData.referent ? this.userData.referent : "",
        //fiscalCode: this.userData.fiscalCode ? this.userData.fiscalCode : "",
        //pecAddress: this.userData.pecAddress ? this.userData.pecAddress : "",
        //phoneNumber: this.userData.phoneNumber ? this.userData.phoneNumber : "",
      })
      this.addConfirmed();
    }
  }

  useMerchant() {
    this.key = 'id';
    this.display = this.merchantLabel;
    this.keepSorted = true;
    this.source = this.merchantList;
    this.confirmed = [];
  }

  private merchantLabel(item: any) {
    return item.description
  }

  doReset() {
    this.merchantList = this.stations;
    this.users = new Array<any>();
    this.useMerchant();
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }
  onSubmit() {
    for (var i in this.resellerForm.controls) {
      this.resellerForm.controls[i].markAsTouched();
    }
    if (this.resellerForm.status == 'VALID') {
      this.save(this.resellerForm.value)
    }
  }

  onChangeReseller(reseller) {
    console.log(reseller);
    this.selectedResellerId = reseller
  }

  getOnlyIdsOfArray(array) {
    let arrayId = [];
    array.forEach(element => {
      arrayId.push(element.id)
    });
    return arrayId;
  }

  save(reseller) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (this.idUser) {
      this.userService.updateUserDatas(reseller).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('reseller.swal_user_update_ok'), "" , "success").then((result) => {
            this.navigationBack();
          });
        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        swal("Error", "", "error");
      })
    }
    else {
      this.userService.insertUser(reseller).subscribe((response: any) => {
        if (response.code == "0008") {
          swal(this.translate.instant('general.email_match'), "", "error").then((result) => {
          });
        }
        if (response.outcome.success === true) {
          swal(this.translate.instant('reseller.swal_user_created_ok'), "" , "success").then((result) => {
            this.navigationBack();
          });

        }
        else {
          swal("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal("Error", this.errorMessage, "error");
      })
    }
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/user');
    } else {
      this.location.back();
    }
  }
}
