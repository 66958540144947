import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'sp-input-datepicker-range',
  templateUrl: './input-datepicker-range.component.html',
  styleUrls: ['./input-datepicker-range.component.scss']
})
export class InputDatepickerRangeComponent implements OnInit {

  @Input() controlFrom: FormControl;
  @Input() controlTo: FormControl;
  @Input() placeholderFrom: string = '';
  @Input() placeholderTo: string = '';
  @Input() label: string = '';
  @Input() locale: any = {applyLabel: 'ok', clearLabel: '', format: 'DD/MM/YYYY'};
  @Input() showClearButton: boolean = false;
  @Input() autoApply: boolean = true;
  @Input() showDropdowns: boolean = true;
  @Input() opensFrom: string = 'right';
  @Input() opensTo: string = 'left';
  @Input() drops: string = 'down';

  constructor() { }

  ngOnInit() {
  }

}
